import { getCheckoutClient } from '../config/CheckoutClient';
import { getEmail, getDefaultAddress } from '../selectors/member';
import { addServerErrors, goToConfirmation } from './ui';
import { sendSlackMessage } from 'listing/actions/general';
import { wrappedFetch } from '../helpers/fetch';
import { getConfirmationData } from './confirmation';

export function setTos(accepted) {
  return { type: 'SET_TOS', accepted };
}

export function addPayment(payment) {
  return { type: 'ADD_PAYMENT', payment };
}

export function setTerminal(terminalId) {
  return { type: 'SET_TERMINAL_ID', terminalId };
}

export function setPaymentError(field, message) {
  return {
    type: 'SET_PAYMENT_ERROR',
    payload: {
      field,
      message,
    },
  };
}

export function setTerminals(terminals) {
  return { type: 'SET_TERMINALS', terminals };
}

export function setStripeTerminal(stripeTerminal) {
  return { type: 'SET_STRIPE_TERMINAL', stripeTerminal };
}

export function setConnectedTerminal(connectedTerminal) {
  return { type: 'SET_CONNECTED_TERMINAL', connectedTerminal };
}

export function setStripePaymentIntentSecret(secret) {
  return { type: 'SET_STRIPE_PAYMENT_INTENT_SECRET', secret };
}

export function setStripePaymentIntentId(id) {
  return { type: 'SET_STRIPE_PAYMENT_INTENT_ID', id };
}

export const setStripeSubmittedPayment = (val) => {
  return { type: 'SET_STRIPE_SUBMITTED_PAYMENT', val };
};

/**
 * Reset only values that can be changed during checkout process
 * such as "tosAccepted", "errors". Can be extended later.
 */
export const resetChangeablePaymentState = () => {
  return { type: 'RESET_PAYMENT_STATE' };
};

export const clearStripeFields = () => {
  return (dispatch, getState) => {
    dispatch(setStripePaymentIntentSecret(null));
    dispatch(setStripePaymentIntentId(null));
    dispatch(setStripeSubmittedPayment(false));
  };
};

export function setIsReaderConnected(isReaderConnected) {
  return (dispatch, getState) => {
    if (isReaderConnected === false) {
      dispatch(sendSlackMessage('@here EMV terminal not connected', 'error'));
    } else {
      dispatch(sendSlackMessage('EMV terminal connected successfully', 'ok'));
    }

    dispatch({ type: 'SET_IS_READER_CONNECTED', isReaderConnected });
  };
}

export function clearPaymentError(field) {
  return (dispatch) => {
    dispatch(setPaymentError(field, ''));
  };
}

export function zeroDollarPayment() {
  return (dispatch, getState) => {
    let state = getState();
    const address = getDefaultAddress(state);
    const payload = {
      amount: 0,
      email: getEmail(state),
      ...address,
      tos_accepted: state.payment.tosAccepted,
      emailOptOut: state.member.emailOptOut,
    };

    if (state.member.createdNewAccount) {
      payload.sendAccountEmail = 1;
    }

    return getCheckoutClient(state.ui.apiUrl)
      .reservationCreateRequest(state.reservation.token, 'payment', payload, state.reservation.secret)
      .then((result) => {
        dispatch(addPayment(result.body.data));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(addServerErrors(error.response.body.errors));
        return Promise.reject();
      });
  };
}

/**
 * Create a payment intent
 */
export function createStripePaymentIntent(amount, piArgs = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const reservationToken = state.reservation.token;
    const reservationSecret = state.reservation.secret;
    let body = { token: reservationToken, payment: amount };

    if (typeof piArgs === 'object' && piArgs !== null && !!Object.keys(piArgs).length) {
      body = {
        ...body,
        ...piArgs,
      };
    }

    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    return wrappedFetch(state.ui.apiUrl + '/stripepayments/create_payment_intent/' + reservationToken, req).then((res) => {
      if (res.status === 200) {
        return res.json();
      }

      // For now, we get a 400 from that endpoint means that payment was already processed
      if (res.status === 400) {
        dispatch(getConfirmationData(reservationToken, reservationSecret)).then(
          () => {
            dispatch(goToConfirmation());
          },
          () => {
            let errMsg = 'Could not fetch confirmation data';
            Rollbar.critical(
              'Error redirecting to confirmation page: ' + errMsg,
              JSON.stringify({
                params: {
                  err: errMsg,
                  reservationToken: reservationToken,
                },
              }),
            );
            throw new Error(errMsg);
          },
        );

        Rollbar.critical(
          'Stripe request failed.',
          JSON.stringify({
            params: {
              response: res,
              reservationToken: reservationToken,
            },
          }),
        );
      }

      throw new Error(res);
    });
  };
}

export function updateStripePaymentIntent(amount, piArgs = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const reservationToken = state.reservation.token;
    let body = { token: reservationToken, amount };

    if (typeof piArgs === 'object' && piArgs !== null && !!Object.keys(piArgs).length) {
      body = {
        ...body,
        ...piArgs,
      };
    }

    let req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    return wrappedFetch(state.ui.apiUrl + '/stripepayments/update_payment_intent/' + reservationToken, req).then((res) => {
      if (res.status === 200) {
        return res.json();
      }

      throw new Error(res);
    });
  };
}

/**
 * Creates payment intent and attempts to process payment if no further action required
 */
export function createStripePaymentIntentAndCapture(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const reservationToken = state.reservation.token;
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'reservation-secret': state.reservation.secret,
      },
      body: JSON.stringify({ token: reservationToken, ...payload }),
    };
    return wrappedFetch(state.ui.apiUrl + '/stripepayments/create_payment_intent_and_capture/' + reservationToken, req).then((res) => {
      return res.json();
    });
  };
}

/**
 * Confirms payment intent and attempts to process payment
 */
export function confirmStripePaymentIntentAndCapture(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const reservationToken = state.reservation.token;
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'reservation-secret': state.reservation.secret,
      },
      body: JSON.stringify({ token: reservationToken, ...payload }),
    };
    return wrappedFetch(state.ui.apiUrl + '/stripepayments/confirm_payment_intent_and_capture/' + reservationToken, req).then((res) => {
      return res.json();
    });
  };
}

/**
 * Capture payment intent that has been confirmed
 */
export function finalizeReservationFromPaymentIntent(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const reservationToken = state.reservation.token;
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'reservation-secret': state.reservation.secret,
      },
      body: JSON.stringify({ token: reservationToken, ...payload }),
    };
    return wrappedFetch(state.ui.apiUrl + '/stripepayments/finalize_from_payment_intent/' + reservationToken, req).then((res) => {
      return res.json();
    });
  };
}

/**
 * Cancel payment intent
 */
export function cancelStripePaymentIntent(paymentIntentId, secret) {
  return (dispatch, getState) => {
    const state = getState();
    const reservationToken = state.reservation.token;
    const req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: state.reservation.token,
        payment_intent_id: paymentIntentId,
        client_secret: secret,
      }),
    };
    return wrappedFetch(state.ui.apiUrl + '/stripepayments/cancel_payment_intent/' + reservationToken, req).then((res) => {
      return res.json();
    });
  };
}
