import {OpenFeature} from '@openfeature/web-sdk';
import {ConfigCatWebProvider} from '@openfeature/config-cat-web-provider';
import {createConsoleLogger, LogLevel} from "configcat-js-ssr";
import MarketingHelper from "@h/marketing/marketingHelper";

export async function initConfigClient(sdkKey, measurementId, context) {
    if (window.configClient) {
        return;
    }

    const options = {
        logger: createConsoleLogger(LogLevel.Off),
        setupHooks: (hooks) => hooks.on('flagEvaluated', evaluationDetails => {
            if (!measurementId) {
                return;
            }
            const variant = "configcat-" + evaluationDetails.key + "-" + evaluationDetails.value;
            const helper = new MarketingHelper();
            helper.sendEvent(helper, 'experience_impression', {
                'exp_variant_string': variant,
                'variation_id': evaluationDetails.variationId
            }, measurementId);
        }),
    }

   try {
        await OpenFeature.setProviderAndWait(ConfigCatWebProvider.create(sdkKey, options));
        if (context) {
            await OpenFeature.setContext(context);
        }
        window.configClient = OpenFeature.getClient();
    } catch (error) {
        console.error('Failed to initialize config client:', error);
    }
}

export function getConfigClient() {
    return window.configClient;
}
