import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';
import Props from './FooterComponent';
import Translation from "../../shared/Translation";
import { getMainEvent } from '../../checkout/selectors/event';
import {getConfigClient} from "@b/helpers/configClient";

export class Footer extends Component<Props> {
    // TODO: Remove after testing ConfigCat integration
    configClient = getConfigClient();

    getCopyrightText = () => {
        let copyrightText = (this.props.partner?.copyright_year ?? new Date().getFullYear()) + ' ' + this.props.partner.copyright;
            if(this.props.partner.copyright_behalf) {
              copyrightText = <Translation interpolation={{copyright:copyrightText, partner:this.props.partner.copyright_behalf}}>{"checkout__copyright"}</Translation>
            }

        return copyrightText;
    }

    getLink = type => {
       const event = this.props.mainEvent;
       return event ? event?.[type] : this.props.partner?.[type];
    }

    // TODO: Remove after testing ConfigCat integration
    showCopyright = () => {
      // Flag will give us true for employees and false for customers so we negate it to show for customers only
      if(this.configClient) {
        return !this.configClient.getBooleanValue('checkoutpoc', false);
      }
      return true;
    }

    render() {
        if (!this.props.partner) {
            return null;
        }

        const tos_url     = this.getLink('tos_url');
        const privacy_url = this.getLink('privacy_url');
        const contact_url = this.getLink('contact_url');
        const do_not_sell_url = this.getLink('do_not_sell_url');
        const hide_nav_bar_logo = this.getLink('hide_nav_bar_logo');

        const shouldUseThemeDark = this.props.uiConfig.styles["CONTENT__reverse-text"] && this.props.uiConfig.styles["FULLSCREEN"];
        const pageFooterClassName = clsx('page__footer', { 'theme--dark': shouldUseThemeDark });

        return(
            <footer className={pageFooterClassName}>
                <nav className="page__footer__top">
                    <div className="page__footer__left">
                        <ul className="footer__links">
                            {!!contact_url && <li className="footer__link-item"><a href={contact_url} target="_blank"><Translation>{"checkout__support"}</Translation></a></li>}
                            {!!tos_url && <li className="footer__link-item"><a href={tos_url} target="_blank"><Translation>{"checkout__terms_of_service"}</Translation></a></li>}
                            {!!privacy_url && <li className="footer__link-item"><a href={privacy_url} target="_blank"><Translation>{"checkout__privacy_policy"}</Translation></a></li>}
                            {!!do_not_sell_url && <li className="footer__link-item"><a href={do_not_sell_url} target="_blank"><Translation>{"checkout__do_not_sell_or_share"}</Translation></a></li>}
                        </ul>
                    </div>
                    {
                      !hide_nav_bar_logo &&
                      <div className="page__footer__right">
                        <div className="footer__branding">
                            <a href={contact_url} className="footer__branding__image">
                                <span className="sr-only">{this.props.partner.name}</span>
                            </a>
                        </div>
                    </div>
                  }
                </nav>
              {this.showCopyright() && (
                <div className="page__footer__bottom">
                  <p className="copyright-text">&copy; {this.getCopyrightText()}</p>
                </div>
              )}
            </footer>
        )
    }
}

Footer.propTypes = {
    partner: PropTypes.object,
}

const mapStateToProps = (state, props) => {
    return {
        partner: state.partner,
        mainEvent: getMainEvent(state)?.event ?? null,
        uiConfig: state.ui.uiConfig,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
