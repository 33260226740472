import React from 'react';
import PropTypes from 'prop-types';
import { getModifiers, getStyleModifiers } from '../../../helpers/series';
import IntlDayPicker from '@showclix-shared/IntlDayPicker';
import Skeleton from '../../../../shared/Skeleton';

const noop = () => {
  // do nothing
};

/**
 * Shared logic for applying modifiers and events to IntlDayPicker
 */
const DayPicker = (props) => {
  if (props.asSkeleton) {
    return (
      <IntlDayPicker
        locale={props.locale}
        pagedNavigation={false}
        numberOfMonths={props.numberOfMonths}
        canChangeMonth={false}
        selectedDays={[]}
        onDayClick={noop}
        onMonthChange={noop}
        weekdayElement={() => null}
        renderDay={(day) => <Skeleton height="25px" width="25px" borderRadius="12px" />}
        captionElement={() => (
          <center>
            <Skeleton height="25px" width="50%" />
          </center>
        )}
      />
    );
  }

  const modifiers = getModifiers(
    props.eventDates,
    props.calendarColors,
    props.calendarDescriptions,
    props.inventoryFilterResults,
  );
  const calendarStyle = getStyleModifiers(props.accentColor, modifiers);
  const allModifiers = {
    ...modifiers,
    soldOutDays: props.soldOutDays,
  };

  if (props.selectedDay) {
    allModifiers.selectedDay = new Date(props.selectedDay);
  }

  let providedProps = { ...props };
  if (props.loading) {
    providedProps.onDayClick = noop;
  }

  if (props.currentMonth) {
    providedProps.month = props.currentMonth;
  }

  return (
    <IntlDayPicker
      locale={props.locale}
      modifiers={allModifiers}
      pagedNavigation={false}
      calendarStyle={calendarStyle}
      numberOfMonths={props.numberOfMonths}
      canChangeMonth={!props.loading}
      className={props.fullLoading ? 'loading loading--full' : props.loading ? 'loading' : 'loaded'}
      {...providedProps}
    />
  );
};

DayPicker.propTypes = {
  locale: PropTypes.string.isRequired,
  selectedDay: PropTypes.string,
  startingMonth: PropTypes.instanceOf(Date),
  endingMonth: PropTypes.instanceOf(Date),
  selectedDays: PropTypes.array,
  disabledDays: PropTypes.array,
  onDayClick: PropTypes.func,
  onMonthChange: PropTypes.func,
  numberOfMonths: PropTypes.number,
  fromMonth: PropTypes.instanceOf(Date),
  initialMonth: PropTypes.instanceOf(Date),
  toMonth: PropTypes.instanceOf(Date),
  currentMonth: PropTypes.instanceOf(Date),
  soldOutDays: PropTypes.array,
  eventDates: PropTypes.array,
  calendarColors: PropTypes.object,
  calendarDescriptions: PropTypes.object,
  accentColor: PropTypes.string,
  loading: PropTypes.bool,
  fullLoading: PropTypes.bool,
  inventoryFilterResults: PropTypes.object,
  asSkeleton: PropTypes.bool,
};

export default DayPicker;
