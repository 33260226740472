import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hourMinute } from '@showclix-shared-intl/dateTimeFormatters';
import { getNextAvailableEventTodayTime, todayHasPresaleEvents } from '../selectors/eventSeries';
import { getMainEvent } from '../../../selectors/event';
import Translation from '@s/Translation';

class SoldOutDayDisplay extends Component {

    render() {

        if (this.props.isRecurring && !this.props.listingSoldOut && this.props.fetchedEventsToday && !this.props.nextAvailableEventTime) {
            const message = this.props.hasPresaleEvents ? 
                <Translation>{'checkout__tickets_for_today_unavailable'}</Translation> : 
                <Translation>{'checkout__today_is_sold_out'}</Translation>;
            return (
                <div className="kiosk__listing__sold-out-message">
                    <h3 className="sold-out-message__title">{message}</h3>
                </div>

            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.intl.locale,
        nextAvailableEventTime: getNextAvailableEventTodayTime(state),
        isRecurring: !!getMainEvent(state)?.event?.isRecurring,
        listingSoldOut: state.ui.listingSoldOut,
        fetchedEventsToday: state.eventSeries.fetchedEventsToday,
        hasPresaleEvents: todayHasPresaleEvents(state),
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};

SoldOutDayDisplay.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SoldOutDayDisplay)
