import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import clsx from 'clsx';
import ListingBranding from './ListingBranding';
import ListingBannerProps from './ListingBannerComponent';
import { DateTimeFormatters } from '@showclix-shared-intl';
import { ISOStringToDate, isValidDateObject } from 'helpers/time';
import TicketsGoOnSale from './TicketsGoOnSale';
import { getMainEvent } from '../../checkout/selectors/event'
import SelectLanguage from "./SelectLanguage";
import { getEventDate, getSeparatedEventDate } from '@h/helpers';
import Translation from '@s/Translation';
import SalesPaused from "./SalesPaused";
import { getCmsMode, getSeriesConfig, selectIsHeroLayout } from '@b/checkout/selectors/ui';
import { StickyEvent } from './StickyEvent';
import { getSeriesOption } from '@v/series/selectors/eventSeries';


class ListingBanner extends Component<ListingBannerProps> {
    constructor() {
      super();

      this.state = {
        isStickyEventVisible: false,
        ticketsContanerRightOffset: 0,
        isStickyEventAllowed: false,
      }

      this.ticketsContainerRef = createRef();
      this.bannerRef = createRef();
    }

    componentDidMount() {
      this.intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach(() => {
          if (!this.ticketsContainerRef?.current) {
            this.setState({
              isStickyEventVisible: window.scrollY > this.bannerRef?.current?.offsetHeight
            }, () => this.onWindowResize());
            return;
          }

          const ticketsContainerRect = this.ticketsContainerRef.current.getBoundingClientRect();

          const isBelow = ticketsContainerRect.bottom < 0;

          this.setState({
            isStickyEventVisible: isBelow
          }, () => this.onWindowResize());
        });
      })

      if (this.ticketsContainerRef?.current) {
        this.intersectionObserver.observe(this.ticketsContainerRef.current);
      }

      if (this.props.isHeroLayout) {
        window.top.addEventListener('load', this.createOnWindowLoad());
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.isHeroLayout && this.props.isHeroLayout) {
        this.createOnWindowLoad(true)();
        this.allowStickyEvent();
      }
    }

    componentWillUnmount() {
      this.intersectionObserver.disconnect();
      window.top.removeEventListener('resize', this.onWindowResize);
      window.top.removeEventListener('load', this.createOnWindowLoad());
      window.top.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
      const target = document.documentElement || document.body;

      if (target.scrollTop === 0) {
        this.setState({
          isStickyEventVisible: false,
        })
      }
    }

    allowStickyEvent() {
      this.setState({ isStickyEventAllowed: true });
      this.onWindowResize();
    }

    addBannerImageOnLoadListener = () => {
      const banner = document.getElementById('banner');
      const style = window.getComputedStyle(banner, ':after');

      if (style.backgroundImage === 'none') {
        this.allowStickyEvent();
        return;
      }

      const url = style.backgroundImage.match(/\((.*?)\)/)[1].replace(/('|")/g,'');

      const img = new Image();
      img.onload = () => {
        this.allowStickyEvent();
      }
      img.src = url;
      if (img.complete) img.onload();
    }

    createOnWindowLoad = (synthetic = false) => () => {
      window.top.addEventListener('resize', this.onWindowResize);
      window.top.addEventListener('scroll', this.onScroll);
      if (this.props.cmsMode) {
        !synthetic && this.addBannerImageOnLoadListener();
      } else {
        this.allowStickyEvent();
      }
      this.onWindowResize();
    }

    onWindowResize = () => {
      if (!this.ticketsContainerRef?.current) return;

      setTimeout(() => {
        const rect = this.ticketsContainerRef.current.getBoundingClientRect();
        this.setState({ ticketsContanerRightOffset: window.innerWidth - rect.right });
      });
    }
  
    getTitle = () => {
        let title = this.props.name;
        if (this.props.uiConfig.listings && this.props.uiConfig.listings.useEventTitle !== 'undefined' &&
            this.props.uiConfig.listings.useEventTitle === false) {
            title = this.props.uiConfig.listings.title;
        }

        return title;
    };

    getAltText = () => {
      const altText = this.props.uiConfig.images.headerAltText;
      return altText || this.getTitle();
    };

    getIsHideEventDateAndTime = () => this.props.uiConfig.hide_event_date_and_time;

    getIsHideEventTime = () => this.props.uiConfig.hide_event_time;

    isCustomTimeUsed = () =>
      this.props.uiConfig.listings.useEventTime !== 'undefined' &&
      this.props.uiConfig.listings.useEventTime === false;

    getTime = () => {
        if (
            this.props.uiConfig.listings && this.isCustomTimeUsed()
        ) {
            return this.props.uiConfig.listings.time;
        }

        if (this.props.isRecurring) {
          if (this.props.uiConfig.hide_event_date_and_time){
            return null;
          }
          let startDate = ISOStringToDate(this.props.start);
          if (!isValidDateObject(startDate)) {
            return null;
          }

          let endDate = null;
          if(this.props.end) {
              endDate = ISOStringToDate(this.props.end);
          }

          const {monthDayShort, monthDayYearShort} = DateTimeFormatters;

          if (!isValidDateObject(endDate)) {
              return monthDayShort(this.props.locale, startDate);
          }

          let startingYear = startDate.getFullYear();
          let endingYear = endDate.getFullYear();
          if(startingYear == endingYear){
              return `${monthDayShort(this.props.locale, startDate)} - ${monthDayYearShort(this.props.locale, endDate)}`;
          } else {
              return `${monthDayYearShort(this.props.locale, startDate)} - ${monthDayYearShort(this.props.locale, endDate)}`;
          }
        }
        return getEventDate(this.props.start, this.props.end, this.props.locale, this.getIsHideEventTime(), this.getIsHideEventDateAndTime());
    };

    getSeparatedDate() {
      const empty = {
        date: '',
        time: ''
      };

      if (this.props.uiConfig.listings && this.isCustomTimeUsed()) {
        return {
          date: this.props.uiConfig.listings.time
        };
      }

      if (this.props.isRecurring) {
        if (this.props.uiConfig.hide_event_date_and_time){
          return empty;
        }
        let startDate = ISOStringToDate(this.props.start);
        if (!isValidDateObject(startDate)) {
          return empty;
        }

        let endDate = null;
        if(this.props.end) {
            endDate = ISOStringToDate(this.props.end);
        }

        const {monthDayShort, monthDayYearShort} = DateTimeFormatters;

        if (!isValidDateObject(endDate)) {
            return {
              date: monthDayShort(this.props.locale, startDate),
            }
        }

        let startingYear = startDate.getFullYear();
        let endingYear = endDate.getFullYear();
        if(startingYear == endingYear){
            return {
              date: `${monthDayShort(this.props.locale, startDate)} - ${monthDayYearShort(this.props.locale, endDate)}`
            };
        } else {
            return {
              date: `${monthDayYearShort(this.props.locale, startDate)} - ${monthDayYearShort(this.props.locale, endDate)}`
            };
        }
      }

      return getSeparatedEventDate({
        eventStart: this.props.start,
        eventEnd: this.props.end,
        locale: this.props.locale,
        hide_time: this.getIsHideEventTime(),
        hide_date_time: this.getIsHideEventDateAndTime()
      });
    }

    getLocation = () => {
        if (this.props.uiConfig.hide_venue){
          return null;
        }

        let location = this.props.venueName + ", " + this.props.venueAddress;
        if(this.props.uiConfig.listings && this.props.uiConfig.listings.useEventLocation !== 'undefined' &&
            this.props.uiConfig.listings.useEventLocation === false) {
            location = this.props.uiConfig.listings.location;
        }

        return location;
    };

    getSeparatedLocation = () => {
      const { venueName, venueAddress, uiConfig } = this.props;
      
      if (uiConfig.hide_venue){
        return {
          venueName: '',
          venueAddress: ''
        }
      }

      if (uiConfig?.useEventLocation !== 'undefined' && !uiConfig.listings.useEventLocation) {
        return {
          venueName: uiConfig.listings.location,
        }
      }

      return {
        venueName,
        venueAddress
      }
    }

    getTimezone = () => {
      if(this.isCustomTimeUsed() || this.getIsHideEventTime() || this.getIsHideEventDateAndTime()) {
        return null;
      }

      return this.props.timezone;
    }

    render() {
        const headerContainerStyle = this.props.headerImageMaxWidth && +this.props.headerImageMaxWidth > 0
            ? {maxWidth: this.props.headerImageMaxWidth + 'px'}
            : {maxWidth: 'none'};

        const shouldUseThemeDark = this.props.uiConfig.styles["BANNER__reverse-text"];
        const pageBannerClassName = clsx('page__banner banner__main', { 'theme--dark': shouldUseThemeDark });

        const separatedDate = this.getSeparatedDate();
        const hasSeparatedDate = separatedDate.date || separatedDate.time;

        const separateLocation = this.getSeparatedLocation();

        return(
            <>
              <SelectLanguage className='select-language--listing' />
              <div className={pageBannerClassName} style={{ '--TICKETS-CONTAINER-RIGHT': `${this.state.ticketsContanerRightOffset}px` }}>
                <ListingBranding slug={this.props.slug} brand={this.props.brand}/>
                <div ref={this.bannerRef} id="banner" className="banner">
                  <div className="banner__logo-img-container" style={headerContainerStyle}>
                    {this.props.headerImage !== "" && <div className="banner__logo">
                      <img id='banner__logo-img' className="banner__logo-img" alt={this.getAltText()} src={this.props.headerImage} />
                    </div>}
                  </div>
                </div>
                <div className="page__content__main">
                  <div className="page-content__inner">
                    <div className="banner__event-details">
                      <h1 className="listing__title">{this.getTitle()}</h1>

                      {(!this.props.hideAgeLimit && this.props.ages.trim()) && (
                        <div className="event-details__ages">
                          <i className="icon-user"></i>
                          <Translation>{this.props.ages}</Translation>
                        </div>
                      )}

                      <div className="event-details">
                        <div className="event-details__date">
                          <div className="event-details__date__icon">
                            <i className="icon-calendar_today"></i>
                          </div>
                          {!this.props.isHeroLayout && <span className="event-details__date-1">{this.getTime()} {this.getTimezone()}</span>}

                          {this.props.isHeroLayout && hasSeparatedDate && (
                            <div className="event-details__date-group">
                              {separatedDate.date && (<span className="event-details__date-1">{separatedDate.date} {!separatedDate.time && this.getTimezone()}</span>)}
                              {separatedDate.time && (<span className="event-details__date-2">{separatedDate.time} {this.getTimezone()}</span>)}
                            </div>
                          )}
                        </div>

                        <div className="event-details__location">
                          <div className="event-details__location__icon">
                            <i className="icon-room"></i>
                          </div>
                          <address>
                            {!this.props.isHeroLayout && (<><span className="event-details__location-name">{this.getLocation()}</span><br /></>)}
                            {this.props.isHeroLayout && (
                              <>
                                {separateLocation.venueName && <span className="event-details__location-name-1">{separateLocation.venueName}</span>}
                                {separateLocation.venueAddress && <span className="event-details__location-name-2">{separateLocation.venueAddress}</span>}
                              </>
                            )}
                          </address>
                        </div>
                      </div>
                    </div>
                    <div ref={this.ticketsContainerRef} className="tickets-container">
                      <div className="event-details__sale-status-container">
                        <TicketsGoOnSale />
                      </div>
                      <div className="event-details__sale-status-container">
                        <SalesPaused />
                      </div>
                      {this.renderTicketsButton()}
                    </div>
                    {this.props.isHeroLayout && this.state.isStickyEventAllowed && (
                      <StickyEvent
                        show={this.state.isStickyEventAllowed && this.state.isStickyEventVisible}
                        calendarOnListing={this.props.calendarOnListing}
                        name={this.props.name}
                        date={this.getTime()}
                        ages={!this.props.hideAgeLimit && this.props.ages}
                        location={this.getLocation()}
                        image={this.props.headerImage}
                        imageAlt={this.getAltText()}
                        TicketsButton={this.props.children}
                      />
                    )}
                  </div>
                </div>
                <div className="banner__background-image">
                </div>
              </div>
            </>
        )
    }

    renderTicketsButton = () => {
        return this.props.children;
    }
}

const mapStateToProps = (state, props) => {

    const venue = state.venue.venues ? Object.values(state.venue.venues)[0] : null;
    const mainEvent = getMainEvent(state);
    return {
        locale: state.intl.locale,
        end: mainEvent?.event?.end ?? '',
        name: mainEvent?.event?.name ?? '',
        start: mainEvent?.event?.start ?? '',
        venueName: venue ? venue.name : "",
        venueAddress: venue ? venue.address.city + ', ' + venue.address.region : "",
        ages: mainEvent?.event?.age_minimum,
        hideAgeLimit: mainEvent?.event?.hide_age_limit,
        slug: state.partner.slug,
        brand: state.partner.brand,
        uiConfig: state.ui.uiConfig,
        headerImage: (state.ui.uiConfig && state.ui.uiConfig.images) ? state.ui.uiConfig.images.header : null,
        headerImageMaxWidth: (state.ui.uiConfig && state.ui.uiConfig.images && state.ui.uiConfig.images.headerImageMaxWidth) ? state.ui.uiConfig.images.headerImageMaxWidth : null,
        isRecurring: !!mainEvent?.event?.isRecurring,
        timezone: mainEvent.event.timezone,
        isHeroLayout: selectIsHeroLayout(state),
        calendarType: getSeriesConfig(state).calendarType,
        cmsMode: getCmsMode(state),
        calendarOnListing: !!getSeriesOption("calendarOnListing", false)(state),
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}

ListingBanner.defaultProps = {
    headerImage: "",
    isOnsale: false
}

ListingBanner.propTypes = {
    locale: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string,
    branding: PropTypes.string,
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
    venueName: PropTypes.string.isRequired,
    venueAddress: PropTypes.string.isRequired,
    headerImage: PropTypes.string,
    isRecurring: PropTypes.bool,
    timezone: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(ListingBanner)
