/**
 * Helper class to support external marketing events
 */
import { formatAllReservationItems, initAnalyticsScript } from './googleAnalytics4';

const SERVICE_GOOGLE = 1;
const SERVICE_FACEBOOK = 2;

class MarketingHelper {
    fbq;

    constructor() {
        // I have no idea what this js does but it creates a function 'fbq'
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod? // eslint-disable-line
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','//connect.facebook.net/en_US/fbevents.js');

        this.fbq = fbq;
    }

    handleMessageGoogleAnalytics(message, measurementID) {
        const {
          page,
          currency,
          value,
          sellableItems,
          action, 
          paymentItems, 
          coupons,
          listingSlug
        } = message;

        let eventType = this.getGaEventFromAction(action);

        let args = {
            currency: currency,
            value: { amount: value, currency: currency },
            items: (sellableItems === undefined) ? [] : formatAllReservationItems(sellableItems, currency),
            page_path: `/listing/${listingSlug}/analytics`,
        };

        switch (eventType) {
            case 'page_view':
                if (page === 'ticketPicker') {
                    eventType = 'view_cart';
                } else if (page === 'confirmation') {
                    break;
                } else {
                    return;
                }
                break;

            case 'reservation_created':
            case 'add_to_cart':
            case 'remove_from_cart':
            case 'begin_checkout':
            case 'view_cart':
                // args built before the switch
                break;

            case 'add_payment_info':
                args.payment_type = paymentItems?.payment_type;
                break;

            case 'purchase':
                args.transaction_id = paymentItems?.orderId;
                break;

            case 'coupon_submitted':
                args.coupons = coupons;
                break;

            default:
                // Unsupported event type on GA
                return;
        }

        this.sendEvent(SERVICE_GOOGLE, eventType, args, measurementID);
    }

    handleMessageMetaPixel(message, measurementID) {
        const { currency, value, action, paymentItems, coupons } = message;
        const eventType = this.getMetaPixelEventFromAction(action);

        let args = {};
        switch (eventType) {
            case 'ReservationCreated':
            case 'PageView':
                break;

            case 'InitiateCheckout':
            case 'Purchase':
                args = {
                    value: value,
                    currency: currency,
                    order_id: paymentItems?.orderId,
                    content_type: 'product',
                    content_ids: this.getContentIds(message),
                };
                break;

            case 'CouponSubmitted':
                args.coupons = coupons;
                break;

            default:
                // Unsupported event type on Meta/FB
                return;
        }

        this.sendEvent(SERVICE_FACEBOOK, eventType, args, measurementID);
    }

    sendEvent(service, type, args, measurementID) {
        if (service == SERVICE_GOOGLE){
            if(!window.gtag) {
                initAnalyticsScript(measurementID).then(function(){
                    window.gtag('event', type, {...args, send_to: measurementID});
                });
            } else {
                window.gtag('event', type, {...args, send_to: measurementID});
            }
        }

        if (service == SERVICE_FACEBOOK && this.fbq) {
            // capitalize type so facebook will match with std events
            type = type.charAt(0).toUpperCase() + type.slice(1);
            this.fbq('init', measurementID);
            this.fbq('track', type, args);
        }
    }

    getContentIds(message) {
        let ids = [];
        if (message.sellableItems === undefined) {
            return ids;
        }

        message.sellableItems.forEach(item => {
            let itemType = item.sellableType == 'ticket' ? 'level' : item.sellableType;
            ids.push(`${message.eventId}-${itemType}-${message.sellableID}`);
        });

        return JSON.stringify(ids);
    }

    getGaEventFromAction(action) {
        const actionMap = {
            pageView: 'page_view',
            addToCart: 'add_to_cart',
            viewCart: 'view_cart',
            removeFromCart: 'remove_from_cart',
            beginCheckout: 'begin_checkout',
            addPaymentInfo: 'add_payment_info',
            purchase: 'purchase',
            reservationCreated: 'reservation_created',
            couponSubmitted: 'coupon_submitted',
        };

        return actionMap[action] ?? null;
    }

    getMetaPixelEventFromAction(action) {
        const actionMap = {
            pageView: 'PageView',
            beginCheckout: 'InitiateCheckout',
            purchase: 'Purchase',
            couponSubmitted: 'CouponSubmitted',
            reservationCreated: 'ReservationCreated',
        };

        return actionMap[action] ?? null;
    }
}

export default MarketingHelper;
