import { getCheckoutClient } from "../../../config/CheckoutClient";
import { addServerErrors } from "../../../actions/ui";
import { createReservationIfNotExists, fetchReservation, reservationCreated, setReservationData, deleteReservation } from "../../../actions/reservation";
import { setPriceLevels, setEventPriceLevels, setProducts, updatePriceLevels, setBundles } from '@/actions/event';
import { isReservationContentFree, reservationActive } from 'checkout/selectors/reservation';
import { getSelectedEvent } from 'checkout/selectors/event';
import { triggerTransition } from '@uirouter/redux';
import { setListingItem, getListingItem } from "listing/helpers/storage";
import { setInsurancePolicy } from "checkout/views/insurance/actions/insurance";
import { isKioskMode } from '../../../selectors/ui';
import { serializeToUrl } from '../../../helpers/helpers';
import { submitReserveCoupon } from '../../../actions/reservedSeating';
import { getReserveCouponUsage } from '../../../selectors/reserved-seating';
import { wrappedGet } from "../../../helpers/fetch";
import { showPresaleCouponForm } from '../selectors/coupon';

export function setCouponCode(code) {
    return { type: "SET_COUPON_CODE", code };
}

export function addCouponCode(code) {
  return { type: "ADD_COUPON_CODE", code };
}

export function clearCouponCodes() {
  return {type: "CLEAR_COUPON_CODES"};
}

export function setCouponInput(code) {
    return { type: "SET_COUPON_INPUT", code };
}

export function setAutoCouponAmount(autoCouponAmount) {
    return { type: "SET_AUTO_COUPON_AMOUNT", autoCouponAmount };
}

export function setPresaleCode(presaleCode) {
    return { type: "SET_PRESALE_CODE", presaleCode };
}

export function setCouponData(couponData) {
    return { type: "SET_COUPON_DATA", couponData }
}

export function setShowError(showError) {
    return { type: "SET_SHOW_ERROR", showError };
}

export function setShowSuccess(showSuccess) {
    return { type: "SET_SHOW_SUCCESS", showSuccess };
}

export function setShowWarning(showWarning) {
    return { type: "SET_SHOW_WARNING", showWarning };
}

export function setIsPresaleValid(isPresaleValid) {
    return { type: "SET_IS_PRESALE_VALID", isPresaleValid }
}

export function setIsPresaleApplied(isPresaleCodeApplied) {
    return { type: "SET_IS_PRESALE_APPLIED", isPresaleCodeApplied }
}

export function setAccessCode(accessCode) {
    return { type: "SET_ACCESS_CODE", accessCode };
}

export function setIsAccessCodeValid(isAccessCodeValid) {
    return { type: "SET_IS_ACCESS_CODE_VALID", isAccessCodeValid }
}

export function setIsAccessCodeApplied(isAccessCodeApplied) {
    return { type: "SET_IS_ACCESS_CODE_APPLIED", isAccessCodeApplied }
}

export function setShowAccessCodeError(showAccessCodeError) {
    return { type: "SET_SHOW_ACCESS_CODE_ERROR", showAccessCodeError }
}

export function setShowAccessCodeSuccess(showAccessCodeSuccess) {
    return { type: "SET_SHOW_ACCESS_CODE_SUCCESS", showAccessCodeSuccess }
}

export function couponSubmitted(code) {
    return { type: "COUPON_SUBMITTED", code }
}

export function setUnlockedPresaleEvent(id) {
    return { type: "SET_UNLOCKED_PRESALE_EVENT", id };
}

export function setAccessInventory(val) {
    return { type: "SET_ACCESS_INVENTORY", val };
}

export const setReserveCouponUsages = val => {
    return { type: "SET_RESERVE_COUPON_USAGES", val };
}

export const setInviteCouponUsages = val => {
    return { type: "SET_INVITE_COUPON_USAGES", val };
}

export const setTaggableAccessCodeApplied = val => {
    return { type: "SET_TAGGABLE_ACCESS_CODE_APPLIED", val };
}

export const setTaggableReserveCodeApplied = val => {
    return { type: "SET_TAGGABLE_RESERVE_CODE_APPLIED", val };
}

export const setTaggableInvitationCodeApplied = val => {
    return { type: "SET_TAGGABLE_INVITATION_CODE_APPLIED", val };
}

export const setTaggablePresaleCodeApplied = val => {
    return { type: "SET_TAGGABLE_PRESALE_CODE_APPLIED", val };
}

export const setTaggableDiscountCodeApplied = val => {
    return { type: "SET_TAGGABLE_DISCOUNT_CODE_APPLIED", val };
}

export const setTaggableCouponLoading = val => {
    return { type: "SET_TAGGABLE_COUPON_LOADING", val };
}

export const setTaggableCodeError = val => {
    return { type: "SET_TAGGABLE_CODE_ERROR", val };
}

const getEventId = state => {
    return getSelectedEvent(state)?.event_id;
}

export const setCouponLoading = val => {
    return { type: "SET_COUPON_LOADING", val };
}

export function validateCoupon({
  code,
  force = false,
  forTaggableCoupon = false,
  submitAccessCoupon = true
}) {
    return (dispatch, getState) => {
        let state = getState();

        dispatch(couponSubmitted(code));

        let payload = serializeToUrl({
            code: code,
            eventId: getEventId(state),
            token: (state.reservation.token !== '') ? state.reservation.token : getListingItem(state.ui.listingSlug, "token")/*localStorage.getItem('token')*/,
            listingId: state.ui.listingId,
        });

        if (forTaggableCoupon) {
            dispatch(setTaggableCouponLoading(true));
        }else{
            dispatch(setCouponLoading(true));
        }

        return wrappedGet(state.ui.apiUrl + '/coupon-validation?' + payload).then((response) => {
            return response.json();
        }).then((data) => {
            dispatch(setCouponLoading(false));

            if (data.isPlCode === true) { //access coupon
                if (isKioskMode(state)) {//do not allow access coupons for kiosk listings
                    dispatch(setTaggableCouponLoading(false));
                    dispatch(setShowError(true));
                } else if (data.valid === true) {
                    if (state.coupon.isAccessCodeApplied === true) {
                        dispatch(setShowAccessCodeError(true));
                        if (forTaggableCoupon) {
                            dispatch(setTaggableCodeError(true));
                            dispatch(setTaggableCouponLoading(false));
                        }
                    } else if (submitAccessCoupon) {
                        dispatch(setAccessCode(code));
                        dispatch(setIsAccessCodeValid(data.valid));
                        dispatch(submitPriceLevelCoupon(code, forTaggableCoupon));
                    }
                } else {
                    dispatch(setShowError(!data.valid));
                    dispatch(setTaggableCouponLoading(false));
                }
            } else {
                if (data.valid === true) {
                    dispatch(addCouponCode(code));
                    dispatch(setCouponCode(code));
                    dispatch(setShowSuccess(data.valid));
                    dispatch(setShowError(!data.valid));
                    dispatch(setCouponInput(""));
                } else {
                    dispatch(setShowError(true));
                    dispatch(setShowSuccess(false));
                }
                dispatch(setTaggableCouponLoading(false));
            }
            return data;
        }).catch(function (err) {
            dispatch(setCouponLoading(false));

            if (forTaggableCoupon) {
                dispatch(setTaggableCodeError(true));
                dispatch(setTaggableCouponLoading(false));
            }
            dispatch(setShowError(true));
            dispatch(setShowSuccess(false));

            return data;
        });
    }
}

export function validatePresaleCoupon(reload = null, force = false, forTaggableCoupon = false) {
    return (dispatch, getState) => {
        let state = getState();

        let payload = serializeToUrl({
            code: state.coupon.presaleCode,
            eventId: getEventId(state),
            listingId: state.ui.listingId,
        });

        if (forTaggableCoupon) {
            dispatch(setTaggableCouponLoading(true));
        }else{
            dispatch(setCouponLoading(true));
        }

        return wrappedGet(state.ui.apiUrl + '/presale-coupon-validation?' + payload).then((response) => {
            return response.json();
        }).then((data) => {
            dispatch(setCouponLoading(false));

            if (data.valid === true) {
                dispatch(setPriceLevels(data.price_levels));
                dispatch(setEventPriceLevels(getEventId(state), data.price_levels));
                if (data.products) dispatch(setProducts(data.products));
                if (data.bundles) dispatch(setBundles(data.bundles));
                dispatch(setPresaleCode(state.coupon.presaleCode));
                dispatch(setIsPresaleValid(data.valid));
                dispatch(setCouponInput(""));
                if (forTaggableCoupon) {
                    dispatch(setTaggablePresaleCodeApplied(true));
                    dispatch(setTaggableCouponLoading(false));
                }
                if (reload !== true) dispatch(submitPresaleCoupon());
            } else {
                dispatch(setShowError(!data.valid));
                if (forTaggableCoupon) {
                    dispatch(setTaggableCodeError(true));
                    dispatch(setTaggableCouponLoading(false));
                }
            }

        }).catch(function (err) {
            dispatch(setCouponLoading(false));

            dispatch(setShowError(true));
            dispatch(setShowSuccess(false));
            if (forTaggableCoupon) {
                dispatch(setTaggableCodeError(true));
                dispatch(setTaggableCouponLoading(false));
            }
        });
    }
}

export function applyInviteCoupon(code, forTaggableCoupon = false) {
    return async (dispatch, getState) => {
        const state = getState();

        if (forTaggableCoupon) {
            dispatch(setTaggableCouponLoading(true));
        }

        let creatingReservation = false;
        let errorApplyingInvite = false;
        try {
            // validate the coupon code, get the event data
            const validationResponse = await dispatch(requestInviteCodeValidation(code));
            const validationData = await validationResponse.json();
            if (validationData.valid !== true) {
                throw new Error('Invalid code');
            }

            // create reservation if necessary and attach coupon
            creatingReservation = await dispatch(createReservationIfNotExists());
            if (!reservationActive(getState())) {
                throw new Error('Error creating reservation');
            }

            let alreadyApplied = false;
            getState().coupon.inviteCouponUsages.map(usage => {
                if (usage?.attributes?.code == code) {
                    alreadyApplied = true;
                }
            })
            if (!alreadyApplied) {
                await dispatch(submitInviteCoupon(code));
            }

            // if all goes according to plan, put the event data in state
            if (validationData.price_levels) dispatch(setPriceLevels(validationData.price_levels));
            if (validationData.products) dispatch(setProducts(validationData.products));
            if (validationData.bundles) dispatch(setBundles(validationData.bundles));
        } catch (err) {
            errorApplyingInvite = true;
            if (creatingReservation) {
                dispatch(deleteReservation(false));
            }
        }
        if (forTaggableCoupon) {
            dispatch(setTaggableCouponLoading(false));
            if (errorApplyingInvite) {
                dispatch(setTaggableCodeError(true));
            } else {
                dispatch(setTaggableInvitationCodeApplied(true));
            }
        }
    }
}

const requestInviteCodeValidation = code => {
    return (dispatch, getState) => {
        const state = getState();
        const validateParams = serializeToUrl({
            code,
            eventId: getEventId(state),
            listingId: state.ui.listingId,
        });
        return wrappedGet(`${state.ui.apiUrl}/invite-coupon-validation?${validateParams}`);
    }
}

const submitInviteCoupon = code => {
    return (dispatch, getState) => {
        const state = getState();
        const payload = {
            code,
            event_id: getEventId(state),
        };
        return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(
            state.reservation.token,
            'invite-coupon-usage',
            payload,
            state.reservation.secret
        );
    }
}

export function sendCouponUsageRequest() {
    return (dispatch, getState) => {
        let state = getState();
        let eventId = getEventId(state);

        let uniqueCodes = [...new Set(state.coupon.codes)];
        let couponsEntered = [];
        state.coupon.couponData.forEach(couponUsage => {
            couponsEntered.push(couponUsage.code);
        });
        let coupons = [];
        uniqueCodes.forEach(uniqueCode => {
           let codeEntry = {
               event_id: +eventId,
               reservation_id: state.reservation.id,
               code: uniqueCode,
               amount: null,
               coupon_type: null,
           }
           // if coupon has been used don't push it
            if(!couponsEntered.includes(uniqueCode)) {
                coupons.push(codeEntry);
            }
        });
        let payload = {
            coupons: coupons
        }

        return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(state.reservation.token, 'coupon-usage', payload, state.reservation.secret);
    }
}

export function submitCoupon() {
    return (dispatch, getState) => {
        let state = getState();
        return dispatch(sendCouponUsageRequest()).then((result) => {
            dispatch(setShowSuccess(true));
            return dispatch(fetchReservation(state.reservation.token, state.reservation.secret)).then(() => {
                state = getState();
                if (state.insurance.selected !== "" && isReservationContentFree(state)) {
                    dispatch(setInsurancePolicy(""));
                }
                dispatch(setCouponInput(""));
                return true;
            });
        }).catch((error) => {
            if (error.response.statusCode === 400) {
                dispatch(addServerErrors(error.response.body.errors));
                dispatch(setShowError(true));
                dispatch(setShowSuccess(false));
                return Promise.reject("");
            }
        });
    }
}

function requestPresaleCoupon(state, token, payload, secret, dispatch, getState, eventId, forTaggableCoupon) {
  return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(token, 'presale-coupon-usage', payload, secret).then((result) => {
    dispatch(setIsPresaleApplied(true));
  }).then(() => {
    fetchReservation(token, secret)(dispatch, getState);
    dispatch(setShowError(false));
    dispatch(setShowWarning(false));
    dispatch(setUnlockedPresaleEvent(eventId));
    if (forTaggableCoupon) {
      dispatch(setTaggableAccessCodeApplied(true));
      dispatch(setTaggableCouponLoading(false));
    }
    dispatch(triggerTransition("ticketPicker"));
    dispatch(setCouponInput(""));
  }).catch((error) => {
    if (forTaggableCoupon) {
      dispatch(setTaggableAccessCodeApplied(false));
      dispatch(setTaggableCouponLoading(false));
    }
    if (error.response.statusCode === 400) {
      dispatch(setPresaleCode(''));
      dispatch(setIsPresaleValid(false));
      dispatch(setIsPresaleApplied(false));
      dispatch(addServerErrors(error.response.body.errors));
      return Promise.reject("");
    }
  });
}

export function submitPresaleCoupon(forTaggableCoupon = false) {
    return (dispatch, getState) => {
        let state = getState();
        let token = state.reservation?.token;
        let secret = state.reservation?.secret;

        let eventId = getEventId(state);
        var payload = {
            event_id: +eventId,
            code: state.coupon.presaleCode,
            amount: null,
            coupon_type: null,
            reservation_id: state.reservation.id,
        };

        const resPayload = {
            captureMethod: state.payment.method,
            listingId: state.ui.listingId,
            seller_id: state.event.seller_id,
        }

      if(token && secret) {
        return requestPresaleCoupon(state, token, payload, secret, dispatch, getState, eventId, forTaggableCoupon);
      } else {
        return getCheckoutClient(state.ui.apiUrl).createResource('reservations', resPayload, '').then((result) => {
          let secret = result.header['reservation-secret'];
          let token = result.body.data.id;
          setListingItem(state.ui.listingSlug, "secret", secret);
          setListingItem(state.ui.listingSlug, "token", token);
          dispatch(reservationCreated());
          dispatch(setReservationData(secret, result.body.data));
          return requestPresaleCoupon(state, token, payload, secret, dispatch, getState, eventId, forTaggableCoupon);
        }).catch(e => {
        });
      }
    }
}

export function submitPriceLevelCoupon(code, forTaggableCoupon = false) {
    return (dispatch, getState) => {
        let state = getState();
        let token = state.reservation.token;
        let payload = {
            event_id: getEventId(state),
            code: code,
            coupon_type: 11,
        };

        if (token !== '') {
            return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(state.reservation.token, 'pl-coupon-usage', payload, state.reservation.secret).then((result) => {
                dispatch(setIsAccessCodeApplied(true));
                dispatch(getHiddenPriceLevels());
                dispatch(setShowError(false));
                dispatch(setShowWarning(false));
                dispatch(setShowAccessCodeSuccess(true));
                if (forTaggableCoupon) {
                    dispatch(setTaggableAccessCodeApplied(true));
                    dispatch(setTaggableCouponLoading(false));
                }
                dispatch(triggerTransition("ticketPicker"));
                dispatch(setCouponInput(""));
                return fetchReservation(state.reservation.token, state.reservation.secret)(dispatch, getState);
            }).catch((error) => {
                if (forTaggableCoupon) {
                    dispatch(setTaggableCodeError(true));
                    dispatch(setTaggableCouponLoading(false));
                }
                if (error.response.statusCode === 400) {
                    dispatch(addServerErrors(error.response.body.errors));
                    dispatch(setShowError(true));
                    return Promise.reject("");
                }
            });
        } else {
            const resPayload = {
                captureMethod: state.payment.method,
                listingId: state.ui.listingId,
                seller_id: state.event.seller_id,
            }
            return getCheckoutClient(state.ui.apiUrl).createResource('reservations', resPayload, '').then((result) => {
                let secret = result.header['reservation-secret'];
                let token = result.body.data.id;
                setListingItem(state.ui.listingSlug, "secret", secret);
                setListingItem(state.ui.listingSlug, "token", token);
                dispatch(reservationCreated());
                dispatch(setReservationData(secret, result.body.data));
                dispatch(setCouponInput(""));

                return priceLevelCouponUsage(state, token, payload, secret, dispatch, getState, forTaggableCoupon);
            }).catch(e => {
            });
        }
    }
}

function priceLevelCouponUsage(state, token, payload, secret, dispatch, getState, forTaggableCoupon = false) {
    return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(token, 'pl-coupon-usage', payload, secret).then((result) => {
        dispatch(setIsAccessCodeApplied(true));
        dispatch(getHiddenPriceLevels());
        return Promise.resolve(true);
    }).then(() => {
        fetchReservation(token, secret)(dispatch, getState);
        dispatch(setShowError(false));
        dispatch(setShowWarning(false));
        dispatch(setShowAccessCodeSuccess(true));
        dispatch(triggerTransition("ticketPicker"));
        if (forTaggableCoupon) {
            dispatch(setTaggableAccessCodeApplied(true));
            dispatch(setTaggableCouponLoading(false));
        }
    }).catch((error) => {
        if (forTaggableCoupon) {
            dispatch(setTaggableCodeError(true));
            dispatch(setTaggableCouponLoading(false));
        }
        if (error.response.statusCode === 400) {
            dispatch(clearAccessCodeState());
            dispatch(addServerErrors(error.response.body.errors));
            return Promise.reject("");
        }
    });
}

export const clearAccessCodeState = () => {
    return dispatch => {
        dispatch(setAccessCode(''));
        dispatch(setIsAccessCodeValid(false));
        dispatch(setIsAccessCodeApplied(false));
    }
}

/**
 * Updates price levels and custom inventory configuration after submitting an access code
 */
export function getHiddenPriceLevels() {
    return (dispatch, getState) => {
        let state = getState();
        let payload = serializeToUrl({
            code: state.coupon.accessCode,
            eventId: getEventId(state),
            token: (state.reservation.token !== '') ? state.reservation.token : getListingItem(state.ui.listingSlug, "token")/*localStorage.getItem("token")*/
        });

        return wrappedGet(state.ui.apiUrl + '/price-levels-coupon?' + payload).then((response) => {
            return response.json();
        }).then((data) => {
            dispatch(updatePriceLevels(data.price_levels));
            if (data.inventory) {
                dispatch(setAccessInventory(data.inventory));
            }
        }).catch(function (err) {
        });
    }
}

// only sets the discount coupon data for now
export function fetchCouponUsage(token, secret) {
    return (dispatch, getState) => {
        let state = getState();
        return getCheckoutClient(state.ui.apiUrl).reservationGetRequest(token, 'coupon-usage', secret).then((result) => {
            if (state.coupon.code !== "" && state.coupon.showSuccess === true) {
                dispatch(setCouponData(getDiscountCoupons(state, result.body.data)));
            }
            if (getState().reservation.hasReserveCoupon) {
                dispatch(setReserveCouponUsages(getReserveCouponUsages(result.body.data)));
            }
            if (getState().reservation.hasInviteCoupon) {
                dispatch(setInviteCouponUsages(getInviteCouponUsages(result.body.data)));
            }
            return Promise.resolve();
        });
    }
}

const getReserveCouponUsages = couponData => {
    return getCouponUsagesOfType(couponData, 4);
}

const getInviteCouponUsages = couponData => {
    return getCouponUsagesOfType(couponData, 10);
}

const getCouponUsagesOfType = (couponData, type) => {
    return !Array.isArray(couponData) ? [] : couponData.filter(c => +c?.attributes?.coupon_type === type);
}

const getDiscountCoupons = (state, usedCoupons) => {
    let usedDiscountCoupons = [];

    usedCoupons.forEach(usedCoupon => {
        if (usedCoupon['attributes']['coupon_type'] === 2 || usedCoupon['attributes']['coupon_type'] === 7) {
            usedDiscountCoupons.push(usedCoupon.attributes);
        }
    });

    let stateCoupons = [...new Set(state.coupon.codes)];
    // for case-insensitive comparison
    stateCoupons = stateCoupons.map((code) => typeof code === 'string' ? code.toUpperCase() : code);

    let verifiedDiscountCoupons = [];

    usedDiscountCoupons.forEach(usedDiscountCoupon => {
        const compareCode = typeof usedDiscountCoupon.code === 'string' ? usedDiscountCoupon.code.toUpperCase() : usedDiscountCoupon.code;
        // make sure only coupons in the state show up
        if(stateCoupons.includes(compareCode)) {
            let codeEntry = {
                amount: usedDiscountCoupon.amount,
                code: usedDiscountCoupon.code,
                coupon_name: usedDiscountCoupon.coupon_name,
                coupon_offer_id: usedDiscountCoupon.coupon_offer_id,
                coupon_type: usedDiscountCoupon.coupon_type,
                event_coupon_id: usedDiscountCoupon.event_coupon_id,
                event_id: usedDiscountCoupon.event_id,
                reservation_id: usedDiscountCoupon.reservation_id
            }
            verifiedDiscountCoupons.push(codeEntry);
        }
    });


    if (verifiedDiscountCoupons.length) {
        return verifiedDiscountCoupons;
    } else {
        return [];
    }
}

let appliedTaggableCoupon = false;

/**
 * If there is a tagged coupon in the URL, apply it on page load
 * Info on how to handle the tagged code is injected into uiConfig
 * In the listing action.
 */
export const applyTaggableCoupon = (skipPresale = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        const code = state.ui?.uiConfig?.taggableCode;

        if (!code || appliedTaggableCoupon || !!state.ui.isEmbed || isKioskMode(state)) {
            return true;
        }

        appliedTaggableCoupon = true;

        if (state.ui?.uiConfig?.hasTaggablePl) {
            if (!state.coupon.accessCode) {
               dispatch(validateCoupon({ code, forTaggableCoupon: true }));
            }
        } else if (state.ui?.uiConfig?.hasTaggableReserve) {
            if (!getReserveCouponUsage(state)) {
                dispatch(setTaggableCouponLoading(true));
                await dispatch(submitReserveCoupon(code));
                dispatch(setTaggableCouponLoading(false));
                if (getState().reservation.hasReserveCoupon) {
                    dispatch(setTaggableReserveCodeApplied(true));
                } else {
                    dispatch(setTaggableCodeError(true));
                }
            }
        } else if (state.ui?.uiConfig?.hasTaggablePresale) {
            if (!skipPresale && showPresaleCouponForm(state)) {
                dispatch(setPresaleCode(code));
                dispatch(validatePresaleCoupon(null, false, true));
            }
        } else if (state.ui?.uiConfig?.hasTaggableInvite) {
            dispatch(applyInviteCoupon(code, true));
        } else if (state.ui?.uiConfig?.hasTaggableDiscount) {
            // code has already been validated, but will be applied after
            // reservation is created. just show the success message
            dispatch(setTaggableDiscountCodeApplied(true));
        }
    }
}
