import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Props from './ListingDetailsComponent';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import clsx from 'clsx';
import { getXssFilter } from 'checkout/helpers/helpers';
import * as fitvids from 'fitvids';
import Translation from "../../shared/Translation";
import { getMainEvent } from '../../checkout/selectors/event';

/**
 * props
 * description
 *
 */
class ListingDetails extends Component<Props> {
    componentDidMount() {
        fitvids();
    }
    getDescription = () => {
        let description = this.props.description;
        if (this.props.uiConfig.listings && this.props.uiConfig.listings.useEventContent !== 'undefined' &&
            this.props.uiConfig.listings.useEventContent === false) {
            description = this.props.uiConfig.listings.content;
        }

        return {__html: getXssFilter().process(description)};
    };

    getHeading = () => {
      let heading = <Translation>{'checkout__event_details'}</Translation>;
        if (this.props.uiConfig.listings && this.props.uiConfig.listings.heading) {
            heading = this.props.uiConfig.listings.heading;
        }

        return heading;
    };

    getWrapperClass = () => {
        let classString = "page-content__outer";

        if(this.props.theme) {
            classString += " "+this.props.theme;
        }

        return classString;
    }

    render() {
        const shouldUseThemeDark = this.props.uiConfig.styles["CONTENT__reverse-text"] && this.props.uiConfig.styles["FULLSCREEN"];
        const pageContentClassName = clsx('page__content', { 'theme--dark': shouldUseThemeDark });

        return(
            <div className={pageContentClassName}>
                <div className={this.getWrapperClass()}>
                    <div className="page-content__inner">
                        <div className="rich-content ">
                            <div className="rich-content__heading">
                                <h2 className="rich-content__heading-name">{this.getHeading()}</h2>
                            </div>
                            <div className="rich-content__body" ref="body" dangerouslySetInnerHTML={this.getDescription()}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ListingDetails.propTypes = {
    description: PropTypes.string.isRequired,
    theme: PropTypes.string
}

const mapStateToProps = (state, props) => {
    return {
        description: getMainEvent(state)?.event?.description ?? '',
        uiConfig: state.ui.uiConfig,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);


