import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonLoading from 'shared/ButtonLoading';
import Translation from "./Translation";

class Button extends Component {
    render() {
        // remove props that are not part of html attributes
        const {
          loading,
          disabled,
          children: _children,
          loadingText: _loadingText,
          ...props
        } = this.props;

        return(
            <button {...props} disabled={disabled || loading} onClick={this.onClick}>
                {this.getContent()}
            </button>
        )
    }
    getContent = () => {
        if(this.props.loading) {
            return <ButtonLoading>{this.props.loadingText}</ButtonLoading>
        } else {
          return this.props.children;
        }
    }

    onClick = () => {
        if (!this.props.disabled && !this.props.loading) {
            this.props.onClick();
        }
    }
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    loadingText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
}

Button.defaultProps = {
  loadingText: <Translation>{"checkout__loading"}</Translation>
}

export default Button;
