import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import { asyncComponent } from 'react-async-component';
import {fetchEvent, setFetchedEventId, setEventData, setListingInfoData} from '@/actions/event';
import { setConfirmation, setApiUrl, setListingSlug, resetUi, setCheckoutConfig, setMaintenanceMode, setAlwaysShowHiddenLevels } from '@/actions/ui';
import { setJsLocale, setDefaultJsLocale, setServerLocale, setDefaultServerLocale, setLanguages, changedLanguage } from "@/actions/intl";
import {StyleContext} from '@c/StyleContext';
import {CurrencyContext} from '@c/CurrencyContext';
import Translations from '@h/translation';
import {TranslationContext} from '@c/Translations';
import Listing from './containers/Listing';
import { toUrlParams } from '@h/helpers';
import { setCheckoutOverlay, setListingConfig, setDefaultShowCalendarInModal, setShowCalendarInModal } from '@/actions/ui';
import { validatePresaleCoupon, getHiddenPriceLevels, applyTaggableCoupon, clearAccessCodeState } from 'checkout/views/coupon/actions/coupon';
import { getEventSeriesChildListing, setWidth } from 'checkout/views/series/actions/event-series';
import { getBlockingErrors, isKioskMode, getCmsMode } from 'checkout/selectors/ui';
import { getMainEvent, isSeries } from 'checkout/selectors/event';
import Notification from './components/Notification';
import { getListingItem } from './helpers/storage';
import { setEmailOptOut, setDefaultEmailOptOut } from 'checkout/views/member/actions/member';
import InteractionBlock from 'checkout/containers/InteractionBlock';
import KioskLoginContainer from './views/kiosk-management/containers/KioskLoginContainer';
import { setKioskToken, KIOSK_AUTH_TOKEN } from './views/kiosk-management/actions/kiosk-management';
import { CustomerCareHeader } from './components/CustomerCareHeader.tsx';
import { setShouldFetchUpsells } from 'checkout/views/upsells/actions/upsells';
import MaintenanceBanner from './components/MaintenanceBanner';
import { setListingId, setEmbedType, setHideBestAvailable, setBestAvailableButtonText, setDisableBestAvailable } from "../checkout/actions/ui";
import TaggableCouponBannerContainer from '../checkout/views/coupon/containers/TaggableCouponBannerContainer';
import { EMBED_TYPE_CALENDAR, EMBED_TYPE_MULTI } from '../checkout/reducers/ui';
import LocalizedAddress from '../checkout/views/member/components/LocalizedAddress';
import { PaymentContextProvider } from '@showclix-shared/Payment/contexts/PaymentContext';
import EmailOptOutContainer from 'checkout/views/checkout/containers/EmailOptOutContainer';
import QuestionOptInContainer from 'checkout/views/checkout/containers/QuestionOptInContainer';
import TermsOfServiceContainer from "checkout/views/checkout/containers/TermsOfServiceContainer";
import StepActionContainer from 'checkout/views/checkout/containers/StepActionContainer';
import InsuranceContainer from 'checkout/views/insurance/containers/InsuranceContainer';

const AsyncCheckout = asyncComponent({
    resolve: () => import('../checkout/containers/CheckoutContainer'),
});

const isInitialUrl = () => {
    const hash = window.location.hash;

    if (!hash) return true;

    // '#' || '#/'
    return hash.length <= 2;
}

class App extends Component {
    stripeTerminal = null;
    t = null;

    constructor(props) {
        super(props);

        this.t = new Translations(this.props.apiUrl);
        this.t.init(
          this.props.language?.locale ?? 'en_US',
          this.props.language?.translations ?? {}
        );
    }

    // ignoring the passed error and info, rollbar will pick them up
    componentDidCatch(error, info) {
        console.log({error, info});
        if(this.props.errorHandler) {
            this.props.errorHandler();
        }
    }

    componentDidMount() {
        // For embeds when there are search params,
        // Update search params that are not present in current url
        // and update url.
        if (window.history.pushState && this.props.searchParams) {
          const url = new URL(window.location.href);
          Object.entries(this.props.searchParams).forEach(([key, value]) => {
            if (!url.searchParams.get(key)) {
              url.searchParams.append(key, value);
            }
          })
          window.history.pushState(null, '', url.toString());
        }

        this.props.resetUi();
        this.props.setApiUrl(this.props.apiUrl);
        this.props.setListingSlug(this.props.listingSlug);
        this.props.setListingId(this.props.listingId);

        this.props.setListingInfoData(this.props.listingInfo)

        let shouldFetchUpsells = !!this.props.listingConfig?.shouldFetchUpsells;

        if (Object.keys(this.props.listingInfo?.event || [])?.length !== 0) {
            const newEvent = {...this.props.listingInfo.event};
            this.props.setEventData(newEvent);


            const reservedSeating = newEvent?.attributes?.events?.[this.props.eventId]?.reserved_seating;

            this.props.setHideBestAvailable(!!reservedSeating?.hideBestAvailable);
            this.props.setDisableBestAvailable(!!reservedSeating?.disableBestAvailable);
            this.props.setBestAvailableButtonText(reservedSeating?.bestAvailableButtonText);

            if (newEvent.attributes?.shouldFetchUpsells) {
                shouldFetchUpsells = true;
            }
        }

        this.props.setShouldFetchUpsells(shouldFetchUpsells);

        this.props.setMaintenanceMode(this.props.maintenanceMode);
        this.props.setFetchedEventId(this.props.embedType === EMBED_TYPE_MULTI ? this.props.eventId : undefined);

        this.props.setJsLocale(this.props.language?.jsLocale ?? 'en-US');
        this.props.setDefaultJsLocale(this.props.language?.jsLocale ?? 'en-US');
        this.props.setLanguages(this.props.language?.languages ?? []);
        this.props.setServerLocale(this.props.language?.locale ?? 'en_US', this.props.language?.translations ?? {});
        this.props.setDefaultServerLocale(this.props.language?.locale ?? 'en_US');

        if (window && window.innerWidth) {
            this.props.setWidth(window.innerWidth);
        }

        if(this.props.listingConfig.checkout) {
            this.props.setCheckoutConfig(this.props.listingConfig.checkout);
            if (
                this.props.embedType == EMBED_TYPE_CALENDAR ||
                (this.props.listingConfig.checkout.seriesOptions && this.props.listingConfig.checkout.seriesOptions.calendarOnListing)
            ) {
                this.props.setDefaultShowCalendarInModal(false);
                this.props.setShowCalendarInModal(false);
            }
        }

        if(this.props.listingConfig && Object.keys(this.props.listingConfig).length > 0) {
            this.props.setListingConfig(this.props.listingConfig);
            this.props.setDefaultEmailOptOut(this.props.listingConfig.opt_out_by_default);
            this.props.setEmailOptOut(this.props.listingConfig.opt_out_by_default);
        }

        this.props.setConfirmation(false);

        if (getListingItem(this.props.listingSlug, 'selectedEventId')) {
            this.props.getEventSeriesChildListing(getListingItem(this.props.listingSlug, 'selectedEventId'));
        }

        let kioskAuthToken = getListingItem(this.props.listingSlug, KIOSK_AUTH_TOKEN);
        if (kioskAuthToken) {
            this.props.setKioskToken(kioskAuthToken);
        }

        /**
         * if user previously applied an access coupon, fetch the hidden levels
         * if we end up in a state where there is an access code in local storage, but
         * no reservation, just clear the access code data from state so that user can
         * submit another one
         */
        if (this.props.coupon.accessCode !== "" && this.props.coupon.isAccessCodeValid === true && this.props.coupon.isAccessCodeApplied === true) {
            if (!!this.props.reservationToken || !!getListingItem(this.props.listingSlug, "token")) {
                this.props.getHiddenPriceLevels();
            } else {
                this.props.clearAccessCodeState();
            }
        }

        const hasSelectedEventId = () => !!getListingItem(this.props.listingSlug, "selectedEventId");

        const token = getListingItem(this.props.listingSlug, "token");

        if (isInitialUrl() && this.props.isSeries && this.props.isOpen && !hasSelectedEventId() && !token) {
            this.props.setCheckoutOverlay(false);
        }

        let reloadedPresale = false;

        if (this.props.coupon.presaleCode !== "" && this.props.coupon.isPresaleValid === true && this.props.coupon.isPresaleCodeApplied === true) {
            reloadedPresale = true;
            this.props.validatePresaleCoupon(true);
        }

        // if not fetching the reservation, handle applying taggable coupons here
        if (
            !this.props.mainEvent?.event?.isRecurring &&
            (!getListingItem(this.props.listingSlug, "token") || !getListingItem(this.props.listingSlug, "secret"))
        ) {
            this.props.applyTaggableCoupon(reloadedPresale);
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.serverLocale !== this.props.serverLocale) {
            const newTranslations = this.props.loadedTranslationsByServerLocale[this.props.serverLocale];
            const newT = new Translations(this.props.apiUrl);
            newT.init(this.props.serverLocale);
            newT.resetTranslations(typeof newTranslations === 'object' && newTranslations !== null ? newTranslations : {});
            this.t = newT;
            this.props.changedLanguage();
        }

        if(prevProps.listingConfig !== this.props.listingConfig) {
            this.props.setListingConfig(this.props.listingConfig);
            this.props.setCheckoutConfig(this.props.listingConfig.checkout);
        }
    }

    render() {
        let params = toUrlParams(this.props.uiConfig);

        let cssUrl = "";
        if(this.props.uiConfig.siteCss) {
            cssUrl = this.props.uiConfig.siteCss[this.props.uiConfig.layout];
        }

        if(this.props.checkoutConfig && Object.keys(this.props.checkoutConfig).length > 0) {
            return(
                <TranslationContext.Provider value={this.t}>
                    <PaymentContextProvider value={{
                        TranslationContext,
                        LocalizedAddress,
                        Insurance: <InsuranceContainer />,
                        OptInsOuts: (
                          <>
                            <TermsOfServiceContainer />
                            <EmailOptOutContainer />
                            <QuestionOptInContainer />
                          </>
                        ),
                        StepAction: StepActionContainer,
                    }}>
                        <StyleContext.Provider value={this.props.styleVars}>
                            <CurrencyContext.Provider value={this.props.currencyConfig}>
                                {(this.props.uiConfig.layout && !this.props.uiConfig.skipListingCss) && <link rel="stylesheet" href={cssUrl} />}
                                <div id="mangement-access-div"></div>
                                <div id="kiosk__management"></div>
                                <div id="help"></div>
                                <MaintenanceBanner maintenanceMode={this.props.maintenanceMode} />
                                {this.props.blockingErrors.length > 0 && <Notification messages={this.props.blockingErrors}/>}
                                <div id="timeout-overlay"></div>
                                <ConfiguredListing config={this.props.checkoutConfig.listing}/>
                                {this.props.maintenanceMode === false && <AsyncCheckout router={this.props.router} store={this.props.store} apiUrl={this.props.apiUrl} listingConfig={this.props.listingConfig}/>}
                            </CurrencyContext.Provider>
                        </StyleContext.Provider>
                    </PaymentContextProvider>
                </TranslationContext.Provider>
            )
        }

        return(
            <TranslationContext.Provider value={this.t}>
                <PaymentContextProvider value={{
                  TranslationContext,
                  LocalizedAddress,
                  StepAction: StepActionContainer,
                  Insurance: <InsuranceContainer />,
                  OptInsOuts: (
                    <>
                      <TermsOfServiceContainer />
                      <EmailOptOutContainer />
                      <QuestionOptInContainer />
                    </>
                  ),
                }}>
                    <StyleContext.Provider value={this.props.styleVars}>
                        <CurrencyContext.Provider value={this.props.currencyConfig}>
                            {(this.props.uiConfig.layout && !this.props.uiConfig.skipListingCss) && <link rel="stylesheet" href={cssUrl} />}
                            {this.renderListing()}
                        </CurrencyContext.Provider>
                    </StyleContext.Provider>
                </PaymentContextProvider>
            </TranslationContext.Provider>
        )
    }

    renderListing = () => {
        // maybe these named divs should be portaled
        return(
            <Fragment>
                <div id="mangement-access-div"></div>
                <div id="kiosk__management"></div>
                <MaintenanceBanner maintenanceMode={this.props.maintenanceMode} />
                <InteractionBlock />
                {this.props.blockingErrors.length > 0 && <Notification messages={this.props.blockingErrors}/>}
                <div id="kiosk__time__container"></div>
                <div className="bottom-message-banner">
                    <TaggableCouponBannerContainer />
                    <CustomerCareHeader {...(this.props.uiConfig.customerCare || {})} />
                </div>
                {(this.props.isKiosk && !this.props.kioskToken && !this.props.cmsMode) ? (
                    <TranslationContext.Provider value={this.t}>
                        <KioskLoginContainer />
                    </TranslationContext.Provider>
                ) : (
                    <Listing />
                )}
                {this.props.maintenanceMode === false && <AsyncCheckout router={this.props.router} store={this.props.store} apiUrl={this.props.apiUrl} listingConfig={this.props.listingConfig}/>}
            </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        styleVars: state.ui.styleVars,
        uiConfig: state.ui.uiConfig,
        isOpen: state.ui.overlay,
        serverLocale: state.intl.serverLocale,
        locale: state.intl.locale,
        maintenanceMode: state.ui.maintenanceMode,
        currencyConfig: state.ui.currencyConfig,
        coupon: state.coupon,
        blockingErrors: state.kioskManagement.token ? getBlockingErrors(state) : [],
        sellerId: state.event.seller_id,
        isKiosk: isKioskMode(state),
        kioskToken: state.kioskManagement.token,
        cmsMode: getCmsMode(state),
        reservationToken: state.reservation.token,
        mainEvent: getMainEvent(state),
        loadedTranslationsByServerLocale: state.intl.loadedTranslationsByServerLocale,
        changedLanguageCount: state.intl.changedLanguageCount, // just to force a ui refresh
        embedType: state.ui.embedType,
        isSeries: isSeries(state),
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchEvent,
        setEventData,
        setListingInfoData,
        setHideBestAvailable,
        setDisableBestAvailable,
        setBestAvailableButtonText,
        setConfirmation,
        setApiUrl,
        setListingSlug,
        setListingConfig,
        resetUi,
        validatePresaleCoupon,
        getEventSeriesChildListing,
        getHiddenPriceLevels,
        setCheckoutConfig,
        setEmailOptOut,
        setDefaultEmailOptOut,
        setDefaultShowCalendarInModal,
        setShowCalendarInModal,
        setCheckoutOverlay,
        setKioskToken,
        setWidth,
        setMaintenanceMode,
        setServerLocale,
        setLanguages,
        setJsLocale,
        setDefaultJsLocale,
        setDefaultServerLocale,
        setShouldFetchUpsells,
        setListingId,
        setEmbedType,
        setFetchedEventId,
        clearAccessCodeState,
        applyTaggableCoupon,
        changedLanguage,
        setAlwaysShowHiddenLevels,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
