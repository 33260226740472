import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import Translation from 'shared/Translation';

class Help extends React.Component {

    render() {
        return (
            <Button className="kiosk-footer__button--secondary" onClick={this.props.onClick}>
                <span className="button-secondary--icon">?</span>
                <Translation>{"checkout__help"}</Translation>
            </Button>
        );
    }
}

Help.propTypes = {
    onClick: PropTypes.func.isRequired,
};

Help.defaultProps = {
};

export default Help;

