import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InventoryFilterItem from '../components/InventoryFilterItem';
import { getPriceLevels } from '../../../selectors/event';
import { incrementPriceLevelFilterQuantity, decrementPriceLevelFilterQuantity, setPriceLevelFilterQuantity } from '../actions/inventory-filter';
import { clearSelectedPriceLevels } from '../../ticket-picker/actions/ticket-picker';
import { clearSelectedBundles } from '../../bundle-picker/actions/bundle-picker';
import { clearSelectedProducts } from '../../product-picker/actions/product-picker';
import { setSelectedChildEvent } from '../actions/event-series';

class PriceLevelFilterContainer extends Component {
    resetTimeSelection = () => {
      this.props.clearSelectedPriceLevels();
      this.props.clearSelectedBundles();
      this.props.clearSelectedProducts();
      this.props.setSelectedChildEvent(null);
    }
  
    onQuantityInput = (e) => {
        this.props.setPriceLevelFilterQuantity(this.props.levelId, +e.target.value);
        this.resetTimeSelection();
    };

    onIncrement = () => {
        this.props.incrementPriceLevelFilterQuantity(this.props.levelId);
        this.resetTimeSelection();
    };

    onDecrement = () => {
        this.props.decrementPriceLevelFilterQuantity(this.props.levelId);
        this.resetTimeSelection();
    };

    render() {
        if (!this.props.level) return null;
        return(
            <InventoryFilterItem
                name={this.props.level.name}
                price={this.props.level.price.price}
                minPrice={this.props.level.min_price.price}
                maxPrice={this.props.level.max_price.price}
                onIncrement={this.onIncrement}
                onDecrement={this.onDecrement}
                quantity={this.props.quantity}
                quantities={this.props.quantities}
                onQuantityInput={this.onQuantityInput}
                description={this.props.level?.description}
            />
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        level: getPriceLevels(state)[props.levelId],
        quantity: state.inventoryFilter.priceLevelQuantities[props.levelId],
        quantities: state.inventoryFilter.priceLevelQuantityArrays[props.levelId],
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        incrementPriceLevelFilterQuantity,
        decrementPriceLevelFilterQuantity,
        setPriceLevelFilterQuantity,
        clearSelectedPriceLevels,
        clearSelectedBundles,
        clearSelectedProducts,
        setSelectedChildEvent
    }, dispatch);
}

PriceLevelFilterContainer.propTypes = {
    levelId: PropTypes.any.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceLevelFilterContainer)
