// @flow
import type {ClearAction, CreateAction, SetDeliveryMethod} from '../../../reducers/reservation';
import reconcilePersistedState from '../../../../helpers/reconcilePersistedState';

type Address = {
    name: string,
    address: string,
    address2: string,
    city: string,
    country: string,
    state: string,
    zip:  string,
    phone: string
}

export type SetOptionsAction = {type: "DELIVERY_OPTIONS_SET", options: string};
export type SetPhoneAction = {type: "DELIVERY_PHONE_SET", phone: string};
export type SetEmailAction = {type: "DELIVERY_PHONE_SET", email: string};
export type SetAddressAction = {type: "DELIVERY_ADDRESS_SET", address: Address};

export type Action = SetOptionsAction | SetPhoneAction | SetEmailAction | ClearAction | CreateAction | SetDeliveryMethod | SetAddressAction;

//delivery type constants
export const DELIVERY_TYPE_EMAIL           = 1;
export const DELIVERY_TYPE_POST            = 2;
export const DELIVERY_TYPE_MOBILE          = 3;
export const DELIVERY_TYPE_WILL_CALL       = 4;
export const DELIVERY_TYPE_NO_DELIVERY     = 5;

type State = { id: number, phone: string | boolean, options: Array<Object>, email: string, address: Address, price: Object };

let initialState = { id: null, countryCode: "1", phone: false, options: [], email: "", address: false, price: {} };

/** Reducer for delivery actions */
export function delivery(state: State = initialState, action:Action): State {
    if(action.type === "DELIVERY_OPTIONS_SET") {
        let newState = {...state};

        if (state.id) return state;

        newState.options = [];
        for(let i = 0; i < action.options.length; i++) {
            if(Object.keys(newState.price).length > 0) {
                if(+action.options[i].id === newState.id) {
                    newState.price = action.options[i].attributes.price;
                }
            }
            newState.options.push({id: action.options[i].id, ...action.options[i].attributes});
        }
        return newState;
    } else if(action.type === "DELIVERY_PHONE_SET") {
        let newState = {...state};
        newState.phone = action.number;
        return newState;
    } else if(action.type === "DELIVERY_COUNTRY_CODE_SET") {
        let newState = {...state};
        newState.countryCode = action.countryCode;
        return newState;
    } else if(action.type === "DELIVERY_EMAIL_SET") {
        let newState = {...state};
        newState.email = action.email;
        return newState;
    } else if(action.type === "DELIVERY_ADDRESS_SET") {
        let newState = {...state};
        newState.address = action.address;
        return newState;
    } else if(action.type === "RESERVATION_CLEARED") {
        return initialState;
    } else if(action.type === "RESERVATION_CREATED") {
        return initialState;
    } else if(action.type === "DELIVERY_METHOD_SET") {
        let newState = {...state};
        newState.id = action.selectedDelivery.id;
        newState.price = action.selectedDelivery.price;
        return newState;
    } else if (action.type === 'RECONCILE_PERSISTED_STATE') {
        return reconcilePersistedState(state, initialState);
    } else {
        return state;
    }
}
