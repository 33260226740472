import React, {Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { triggerTransition } from '@uirouter/redux';
import {
  getNumberOfMonths,
  seriesHasSchedule,
  getCurrentMonth,
  hasActiveInventoryFilter,
  getSeriesOption,
  getFormattedEventDates
} from '../selectors/eventSeries';
import SeriesDayPicker from '../components/SeriesDayPicker';
import { setEventTimes, setSelectedDate, handleMonthChange, lazyLoadSeriesDates } from '../actions/event-series';
import { removeListingItem } from '../../../../listing/helpers/storage';
import { setCheckoutOverlay, setShowCalendarInModal } from '../../../actions/ui';
import { deleteReservation } from '../../../actions/reservation';
import { isValidDateObject } from '../../../../helpers/time';
import { reservationActive } from '../../../selectors/reservation';
import { createLoadingSelector } from 'checkout/selectors/helpers';
import { getMainEvent } from 'checkout/selectors/event';
import InventoryFilterContainer from './InventoryFilterContainer';
import { EMBED_TYPE_CALENDAR } from '../../../reducers/ui';
import Translation from '@s/Translation';
import { getTimestampAndRemoveFromUrl } from "../../../helpers/url";
import {isSameDay} from "../../../../../../shared/util/dates";
import { CalendarLegends } from '../components/CalendarLegends';

const DEFAULT_SOLD_OUT_TEXT = 'SOLD OUT';
const ListingDatePicker = ({
    soldOutMessage,
    outerClassName,
    inventoryFilter,
    isEmbed,
    selectDate,
    ...dayPickerProps
}) => {
  useEffect(() => {
    const timestamp = getTimestampAndRemoveFromUrl('date');

    if (timestamp) {
      selectDate(new Date(timestamp));
    }
  }, [])

  return soldOutMessage ? (<span>{soldOutMessage}</span>) : (
    <dialog
      className={outerClassName}
    >
      {inventoryFilter && (
        <div id={isEmbed ? "checkout-overlay" : ""} className={isEmbed ? "embed__inventory-filter" : ""}>
          <div className="card">
            <h2 className="tickets__inventory-title">
              <Translation>{'checkout__find_tickets'}</Translation>
            </h2>
            <InventoryFilterContainer/>
          </div>
        </div>
      )}
      <div className={`DayPicker--listing tickets__datepicker ${isEmbed ? "embed__listing-date-picker" : ""}`}>
        <CalendarLegends />
        <SeriesDayPicker {...dayPickerProps} />
      </div>
    </dialog>
  )
}

const eventTimesLoading = createLoadingSelector("EVENT_TIMES");
const mapStateToProps = (state) => (state.ui.listingSoldOut === true) ?
    {
        soldOutMessage: (state.ui.uiConfig.listings.useCustomSoldOutText
            && typeof state.ui.uiConfig.listings.soldOutText !== "undefined"
            && state.ui.uiConfig.listings.soldOutText !== "") ? state.ui.uiConfig.listings.soldOutText : DEFAULT_SOLD_OUT_TEXT
    } : {
        listingSlug: state.ui.listingSlug,
        numberOfMonths: seriesHasSchedule(state) ? getNumberOfMonths(state) : 1,
        eventTimesLoading: eventTimesLoading(state),
        selectedDate: state.eventSeries.selectedDate,
        reservationActive: reservationActive(state),
        isOnsale: getMainEvent(state)?.event?.isOnsale ?? false,
        listingSoldOut: state.ui.listingSoldOut,
        uiConfig: state.ui.uiConfig,
        currentMonth: getCurrentMonth(state),
        loadedDates: state.eventSeries.datesLazyLoaded,
        hasActiveInventoryFilter: hasActiveInventoryFilter(state),
        selectedDateObject: state.eventSeries.selectedDateObject,
        eventDates: getFormattedEventDates(state),
        inventoryFilter: state.ui.embedType == EMBED_TYPE_CALENDAR &&
                         getSeriesOption('inventoryFilter')(state) &&
                         getSeriesOption("calendarType")(state) !== 'list',
        isEmbed: state.ui.embedType == EMBED_TYPE_CALENDAR,
    };

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        setCheckoutOverlay,
        setEventTimes,
        setSelectedDate,
        triggerTransition,
        lazyLoadSeriesDates,
        setShowCalendarInModal,
        deleteReservation,
        handleMonthChange,
    }, dispatch);

const mergeProps = (stateProps, dispatchProps) => {

    if (stateProps.soldOutMessage) {
        return { soldOutMessage: stateProps.soldOutMessage };
    }

    const getSelectedEventTimes = day => {
        dispatchProps.setSelectedDate(day).then(() => {
            dispatchProps.triggerTransition("seriesDatePicker");
        });
    };

    return {
        outerClassName:
            stateProps.numberOfMonths === 2
                ? "tickets-wrap__calendar-double"
                : "tickets-wrap__calendar-single",
        selectedDay: stateProps.selectedDate,
        onMonthChange: dispatchProps.handleMonthChange,
        inventoryFilter: stateProps.inventoryFilter,
        isEmbed: stateProps.isEmbed,
        selectDate: async (date) => {
          const isDateIsAvailable = stateProps.eventDates.some((availableDate) => isSameDay(availableDate, date))

          if(!isDateIsAvailable) {
              return false;
          }

          dispatchProps.setEventTimes([]);
          dispatchProps.setShowCalendarInModal(false);

          getSelectedEventTimes(date);
        },
        onDayClick: async (day, modifiers = {}) => {
            if(stateProps.eventTimesLoading || modifiers.disabled) {
                return false;
            }
            dispatchProps.setEventTimes([]);
            removeListingItem(stateProps.listingSlug, 'selectedEventId');
            dispatchProps.setShowCalendarInModal(false);

            //if user already selected a different date, and there is an active reservation, delete it here
            const pickingSameDay = isValidDateObject(day)
                && isValidDateObject(stateProps.selectedDateObject)
                && day.getTime() === stateProps.selectedDateObject.getTime();
            if (stateProps.reservationActive && (!isValidDateObject(stateProps.selectedDateObject) || !pickingSameDay)) {
                await dispatchProps.deleteReservation(false);
            }
            getSelectedEventTimes(day);
        }
    };
}

ListingDatePicker.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ListingDatePicker)
