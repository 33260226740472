import { createSelector } from 'reselect';
import { getNoDeliveryOptions } from '../selectors/delivery.js';

const getMemberState = state => state.member;
const getDeliveryState = state => state.delivery;
const getReservationState = state => state.reservation;
const getInsurance = state => state.insurance;
const getDonationState = state => state.donations;
const isKioskMode = state => state.payment.method === 13;
const getTicketsFromState = state => state.tickets.tickets;
const getBundleTicketsFromState = state => state.tickets.bundleTickets;
const getBundleSalesFromState = state => state.bundleSales.bundles;
const getTicketNames = state => state.tickets.namesOnTickets;
const getBundleSaleNames = state => state.bundleSales.namesOnBundles;
const getLeapOptIn = state => state.reservation.leapOptIn;
const getWeather = state => state.weather;
const getBrowserCountry = state => state.ui?.uiConfig?.browserCountry;

export const getMemberPayload = createSelector([getMemberState], (member) => {
    let splitName = member.name.trim().split(" ");
    let firstName = splitName.slice(0, -1).join(' ');
    let lastName = splitName.slice(-1)[0];
    let payload = {
        first_name: firstName,
        last_name: lastName,
        email: member.email,
        phone: member.phone,
        send_account_email: false,
    };

    if (member.address) {
        payload.address = member.address.address;
        payload.address2 = member.address.address2;
        payload.city = member.address.city;
        payload.state = member.address.state;
        payload.zip = member.address.zip;
        payload.country = member.address.country;
    }

    return payload;
});

export const getDeliveryPayload = createSelector(
    [getDeliveryState, getReservationState, getMemberState, isKioskMode, getLeapOptIn, getBrowserCountry],
    (delivery, reservation, member, isKiosk, leapOptIn, browserCountry
    ) => {
    let payload = {
        id: reservation.selected_delivery_option || delivery.id,
        email: delivery.email ? delivery.email : member.email,
        numbers: [{number: delivery.phone, countryCode: delivery.countryCode}],
        address: delivery.address,
        leapOptIn,
        browserCountry
    };

    if (isKiosk && !payload.id) {
        let noDeliveryOptions = getNoDeliveryOptions({delivery: delivery});
        if (noDeliveryOptions.length) {
            payload.id = noDeliveryOptions[0].id;
        }
    }

    return payload;
});

export const getInsurancePayload = createSelector([getInsurance], (insurance) => {
    let payload = {
        provider: insurance.selected,
        status: insurance.selected === "ticketguardian" ? true : false,
        quote: insurance.price,
        token: insurance.quoteToken,
        hash: insurance.quoteHash
    };

    return payload;
});

export const getDonationsPayload = createSelector([getDonationState, getTicketsFromState, getBundleTicketsFromState], (donation, tickets, bundleTickets) => {

    let payload = {};
    /**
     * Get unique event IDs from tickets so that we can
     * make sure that we are only submitting donation for
     * event that is associated with this reservation
     */
    const ticketEventIds = tickets.map(ticket => +ticket.event_id);
    const bundleTicketEventIds = Array.isArray(bundleTickets) ? bundleTickets.map(ticket => +ticket.event_id) : [];
    const eventIds = [...new Set(ticketEventIds.concat(bundleTicketEventIds))];

    Object.keys(donation.userDonations).forEach(eventId => {
        if (eventIds.includes(+eventId)) {
            payload[eventId] = donation.userDonations[eventId];
        }
    });

    return payload;
});

/**
 * check if we need names in the request
 */
export const orderRequiresNames = createSelector([getTicketsFromState, getBundleSalesFromState], (tickets, bundleSales) => {
    let result = false;
    tickets.forEach(ticket => {
        if (ticket.requires_name) {
            result = true;
        }
    });
    bundleSales.forEach(bundleSale => {
        if (bundleSale.requires_name) {
            result = true;
        }
    });
    return result;
});

const bundleTicketRequiresName = (bundleTicket, bundleSales) => {
    let result = false;
    const bundleSaleId = bundleTicket?.bundleSaleId;
    if (bundleSaleId && Array.isArray(bundleSales)) {
        bundleSales.forEach(bundleSale => {
            if (!!bundleSale.multiple_ticket_holders && !!bundleSale.requires_name && +bundleSaleId === +bundleSale?.id) {
                result = true;
            }
        });
    }
    return result;
}

/**
 * Make sure that we have names for all tickets and bundle sales that require names
 */
export const nameRequirementMet = createSelector(
    [
        getTicketsFromState,
        getBundleTicketsFromState,
        getBundleSalesFromState,
        getTicketNames,
        getBundleSaleNames,
    ], (
        tickets,
        bundleTickets,
        bundleSales,
        ticketNames,
        bundleNames
    ) => {
    let result = true;

    const nameIsEmpty = name => typeof name !== 'string' || !name.trim();

    // check regular tickets
    tickets.forEach(ticket => {
        if (!!ticket.requires_name && nameIsEmpty(ticketNames[ticket?.id])) {
            result = false;
        }
    });

    // check bundles
    bundleSales.forEach(bundleSale => {
        if (
            !bundleSale.multiple_ticket_holders &&
            !!bundleSale.requires_name &&
            nameIsEmpty(bundleNames[bundleSale?.id])
        ) {
            result = false;
        }
    });

    // check bundle tickets
    bundleTickets.forEach(ticket => {
        if (
            bundleTicketRequiresName(ticket, bundleSales) &&
            nameIsEmpty(ticketNames[ticket?.id])
        ) {
            result = false;
        }
    });

    return result;
});

/**
 * Get the payload for saving names on tickets and bundles that require names
 */
export const getNamesPayload = createSelector(
    [
        getTicketsFromState,
        getBundleTicketsFromState,
        getBundleSalesFromState,
        getTicketNames,
        getBundleSaleNames,
    ], (
        tickets,
        bundleTickets,
        bundleSales,
        ticketNames,
        bundleNames
    ) => {
    const nameOnTickets = [];
    tickets.forEach(ticket => {
        if (ticket.requires_name) {
            nameOnTickets.push({ticket_id: ticket.id, purchase_for: ticketNames[ticket.id]});
        }
    });
    bundleTickets.forEach(ticket => {
        if (bundleTicketRequiresName(ticket, bundleSales)) {
            nameOnTickets.push({ticket_id: ticket.id, purchase_for: ticketNames[ticket.id]});
        }
    });
    bundleSales.forEach(bundleSale => {
        if (!bundleSale.multiple_ticket_holders && !!bundleSale.requires_name) {
            nameOnTickets.push({bundle_sale_id: bundleSale.id, purchase_for: bundleNames[bundleSale.id]});
        }
    });
    return {nameOnTicket:nameOnTickets};
});

export const getWeatherPayload = createSelector([getWeather], (weather) => {
  return {
    id: weather?.quote?.id ?? 0,
    selected: weather?.quote?.selected ?? false,
    event_id: weather?.quote?.eventId,
    price: weather?.quote?.totalPrice
  };
});
