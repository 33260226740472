import { getCheckoutClient } from '../config/CheckoutClient';
import { setTickets, fetchTickets } from './tickets';
import { setProductSales, fetchProducts, updateProductQuantities } from './product-sales';
import { setBundleSales, fetchBundles } from './bundle-sales';
import { setLoading } from './loading';
import { triggerTransition } from '@uirouter/redux';
import { getConfirmationData } from './confirmation';
import { fetchDeliveryOptions, submitDelivery } from '../views/delivery/actions/delivery';
import { fetchQuestions, resetQuestions } from '../views/questions/actions/questions';
import {
  fetchCouponUsage,
  setReserveCouponUsages,
  applyTaggableCoupon,
  setCouponCode,
  addCouponCode,
  clearCouponCodes,
  sendCouponUsageRequest,
  setShowSuccess,
  setInviteCouponUsages,
} from '../views/coupon/actions/coupon';
import { submitCoupon, setAutoCouponAmount, validateCoupon } from 'checkout/views/coupon/actions/coupon';
import { setPriceLevels, setProducts, getEventPriceLevels, setBundles } from 'checkout/actions/event';
import {
  getNumTicketsByLevel,
  levelIsPWYW,
  getPWYWPriceForPriceLevel,
} from 'checkout/views/ticket-picker/selectors/tickets';
import { updateTicketQuantities, deleteTicketsById } from 'checkout/actions/tickets';
import { getConfigurationQuantitiesFromProductSales } from 'checkout/views/product-picker/selectors/products';
import { addBundles } from 'checkout/actions/bundle-sales';
import {
  getNoDeliveryOptions,
  getMobileDeliveryOptions,
  getWillCallDeliveryOptions,
  getEmailDeliveryOptions,
} from 'checkout/selectors/delivery';
import { submitMemberInfo } from 'checkout/views/member/actions/member';
import { isKioskMode, getKioskOptions, allStepsComplete, isCurrentRouterState } from 'checkout/selectors/ui';
import { getMainEvent } from 'checkout/selectors/event';
import { removeListingItem, getListingItem, setListingItem } from 'listing/helpers/storage';
import { getErrorText } from 'checkout/helpers/helpers';
import { fetchDonations, updateDonations } from '../views/donations/actions/donations';
import { fetchUpsells, setShowUpsellPopup } from '../views/upsells/actions/upsells';
import {
  reservationActive,
  isReservationFree,
  getReservationEventId,
  numberOfItemsInCart,
} from '../selectors/reservation';
import { zeroDollarPayment, clearStripeFields, resetChangeablePaymentState } from './payment';
import { getAllAvailableDeliveryOptions } from '../selectors/delivery';
import { feesSlice } from '../slices/fees';
import { stateToString } from '../helpers/helpers';
import { getSubState } from '../reducers/subReducerHelpers';
import { hasPopupUpsells } from '../views/upsells/selectors/upsells';
import {
  showBestAvailablePicker,
  useServerSideBestAvailable,
  numberSelectedBestAvailableSeats,
  isPyos,
  getReserveCouponUsage,
} from '../selectors/reserved-seating';
import { setBestAvailableQuantities, setSectionLevelQuantities } from './reservedSeating';
import { createLoadingSelector } from '../selectors/helpers';
import { getTopWindow } from '@h/window';
import {
  setCheckoutOverlay,
  addAlert,
  addServerErrors,
  refreshCheckoutSteps,
  goToConfirmation,
  clearAlerts,
  setShowBestAvailablePicker,
  setShowPyosPicker,
} from './ui';
import { wrappedFetch } from '../helpers/fetch';
import { hasTicketsCartedForOtherSeriesChild, getTicketIdsExceptForSelectedSeriesChild } from 'checkout/views/series/selectors/eventSeries';

export function setReservationData(secret, reservation, forward = true) {
  return { type: 'RESERVATION_DATA_SET', secret, reservation, forward };
}

export function reservationCreated() {
  return { type: 'RESERVATION_CREATED' };
}

export function reservationCompleted() {
  return { type: 'RESERVATION_COMPLETED' };
}

export function reservationExpiresIn(timeRemaining) {
  return { type: 'RESERVATION_EXPIRES_IN', timeRemaining };
}

export function setLeapOptIn(opt_in) {
  return { type: 'SET_LEAP_OPT_IN', opt_in };
}

export function clearReservationData({
  reset = true,
  clearChildEvent = true,
  fireCartChangeHooks = true,
  forward = true,
  reason,
} = {}) {
  return (dispatch, getState) => {
    let state = getState();

    clearReservationLocalStorage(state.ui.listingSlug);

    // global TicketGuardian class needed to clear session
    if (typeof tg === 'function') {
      try {
        // eslint-disable-next-line no-undef
        tg('clearSession');
      } catch (err) {
        //this can throw an error if the Ticket Guardian widget is not done loading
      }
    }

    // fire cart update hooks
    if (fireCartChangeHooks) {
      dispatch(setTickets([]));
      dispatch(setProductSales([]));
      dispatch(setBundleSales([]));
      dispatch(clearCouponCodes());
    }

    dispatch(resetQuestions());

    dispatch(resetChangeablePaymentState());
    dispatch(getClearReservationDataAction({ reset, clearChildEvent, forward, reason }));
  };
}

export function clearReservationLocalStorage(slug) {
  removeListingItem(slug, 'secret');
  removeListingItem(slug, 'token');
}

export function getClearReservationDataAction({
  reset = true,
  clearChildEvent = true,
  forward = true,
  reason = 'unknown',
} = {}) {
  return { type: 'RESERVATION_CLEARED', reset, clearChildEvent, forward, reason };
}

export function addListingToReservation(listing) {
  return { type: 'ADD_LISTING', listing };
}

export const createReservationIfNotExists = () => {
  return async (dispatch, getState) => {
    const creatingReservation = !reservationActive(getState());
    if (creatingReservation) {
      const doBulkRequest = false;
      await dispatch(createReservation(doBulkRequest));
    }
    return creatingReservation;
  };
};

export function createReservation(doBulkRequest = true, to = 'checkout') {
  return (dispatch, getState) => {
    dispatch(setLoading('RESERVATION_CREATE_REQUEST'));
    dispatch(clearAlerts());
    let state = getState();

    let createArgs = {
      seller_id: state.event.seller_id,
      captureMethod: state.payment.method,
      listingId: state.ui.listingId,
    };

    if (state?.intl?.serverLocale) {
      createArgs.locale = state.intl.serverLocale;
    }
    
    try {
      if (localStorage && localStorage.getItem('custCareId')) {
        createArgs.custCareId = localStorage.getItem('custCareId');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (state.reservation.purchaseUser) {
      createArgs.purchaseUser = state.reservation.purchaseUser;
    }

    return getCheckoutClient(state.ui.apiUrl)
      .createResource('reservations', createArgs, '')
      .then((result) => {
        let secret = result.header['reservation-secret'];
        let token = result.body.data.id;
        setListingItem(state.ui.listingSlug, 'secret', secret);
        setListingItem(state.ui.listingSlug, 'token', token);
        dispatch(reservationCreated());
        dispatch(setReservationData(secret, result.body.data));
        const { taggableCode, hasTaggableDiscount } = state?.ui?.uiConfig ?? {
          taggableCode: '',
          hasTaggableDiscount: '',
        };
        if (taggableCode && isPyos(state) && hasTaggableDiscount) {
          const { valid, isPlCode } = dispatch(
            validateCoupon({ code: taggableCode, submitAccessCoupon: false, forTaggableCoupon: true }),
          );
          if (isPlCode) {
            dispatch(addAlert('checkout__can_not_apply_access_coupon'));
          }
          if (valid) {
            dispatch(setCouponCode(taggableCode));
            dispatch(addCouponCode(taggableCode));
            dispatch(applyTaggableCoupon());
            const isSubmitted = dispatch(submitCoupon(taggableCode));
            if (!isSubmitted) {
              dispatch(addAlert('checkout__error_submitting_coupon'));
            }
          }
        }

        if (!doBulkRequest) {
          dispatch(setLoading('RESERVATION_CREATE_SUCCESS'));
          return Promise.resolve();
        }

        const reservationEventId = getReservationEventId(getState());

        let bulkRequests = [];
        // this calculation could probably be moved into a selector
        let ticketPayload = { tickets: [] };
        for (const id in state.ticketPicker.quantities) {
          try {
            const thisTicketPayload = {
              event_id: state.priceLevel.levels[id].eventId,
              pricing_level_id: id,
              quantity: state.ticketPicker.quantities[id],
            };
            if (levelIsPWYW(state.priceLevel, id)) {
              const ticketPickerState = getState().ticketPicker;
              thisTicketPayload.pwyw_price = getPWYWPriceForPriceLevel(
                state.priceLevel,
                ticketPickerState,
                state.tickets,
                id,
              );
            }
            ticketPayload.tickets.push(thisTicketPayload);
          } catch (e) {
            // eslint-disable-next-line no-undef
            Rollbar.critical(
              'Error carting tickets during reservation creation',
              JSON.stringify({
                params: {
                  attempted_level: id,
                  state_levels: Object.keys(state.priceLevel.levels),
                  state: stateToString(state),
                },
              }),
            );
            throw new Error('Error carting tickets, please refresh the page, clear your reservation, and try again');
          }
        }

        const reserveCoupon = getReserveCouponUsage(state);
        if (
          showBestAvailablePicker(state) &&
          (useServerSideBestAvailable(state) || !!reserveCoupon?.attributes?.coupon_link_id)
        ) {
          Object.keys(state.reservedSeating.bestAvailableQuantities).forEach((bestAvailableKey) => {
            const bestAvailableRequest = {
              event_id: reservationEventId,
              quantity: numberSelectedBestAvailableSeats(state),
              // eslint-disable-next-line no-extra-boolean-cast
              section_level: !!Object.keys(state.reservedSeating.sectionLevelQuantities).length
                ? {
                    ...state.reservedSeating.sectionLevelQuantities,
                    0: +state.reservedSeating.bestAvailableQuantities[bestAvailableKey],
                  }
                : null,
            };
            // eslint-disable-next-line no-extra-boolean-cast
            if (!!reserveCoupon?.attributes?.coupon_link_id) {
              bestAvailableRequest.reserve_coupon_id = reserveCoupon.attributes.coupon_link_id;
            } else {
              bestAvailableRequest.pricing_level_id = bestAvailableKey == 'best_available' ? null : bestAvailableKey;
              bestAvailableRequest.best_available = true;
            }
            ticketPayload.tickets.push(bestAvailableRequest);
          });
        }

        if (ticketPayload.tickets.length > 0) {
          bulkRequests.push({ type: 'tickets', payload: ticketPayload });
        }

        let bundlePayload = { bundles: [] };
        for (const id in state.bundlePicker.bundleQuantities) {
          bundlePayload.bundles.push({
            eventId: state.bundles.bundles[id].eventId,
            bundleId: id,
            quantity: state.bundlePicker.bundleQuantities[id],
            productConfigs: state.bundlePicker.selectedConfigurations[id]
              ? getBundleProductConfig(state.bundlePicker.selectedConfigurations[id])
              : [],
          });
        }

        if (bundlePayload.bundles.length > 0) {
          bulkRequests.push({ type: 'bundlesale', payload: bundlePayload });
        }

        let productPayload = { products: [] };
        for (const configId in state.productPicker.selectedConfigurations) {
          productPayload.products.push({
            event_id: reservationEventId,
            product_configuration_id: configId,
            quantity: state.productPicker.selectedConfigurations[configId],
          });
        }

        if (productPayload.products.length) {
          bulkRequests.push({ type: 'products', payload: productPayload });
        }

        return sendBulkRequest(state.ui.apiUrl, token, secret, bulkRequests)
          .then((res) => {
            const { tickets, bundlesale, products } = res || {};

            if (tickets) {
              if (tickets.errors) {
                throw tickets.errors;
              }
              processTicketResponse(dispatch, state, tickets);
              // clear out selected quantities from best available picker
              if (useServerSideBestAvailable(state)) {
                dispatch(setSectionLevelQuantities({}));
                dispatch(setBestAvailableQuantities({}));
              }
            }

            if (bundlesale) {
              if (bundlesale.errors) {
                throw bundlesale.errors;
              }
              processBundleResponse(dispatch, state, bundlesale);
            }

            if (products) {
              if (products.errors) {
                throw products.errors;
              }
              processProductResponse(dispatch, state, products);
            }

            // remove any stripe stuff that may be leftover from a previous reservation
            dispatch(clearStripeFields());

            startTick()(dispatch, getState);

            if (state.coupon.code !== ''
              && state.coupon.couponData.length === 0
              && state.coupon.showSuccess === true
              && ((state.tickets.tickets.length !== 0 && showBestAvailablePicker(state)) || (!showBestAvailablePicker(state)))) {
              return submitCoupon(state.coupon.code)(dispatch, getState).then(() => {
                afterBulk(dispatch, getState, token, secret, to);
              }); // submitting a coupon fetches the reservation itself
            } else {
              // need to fetch a second time because now it has tickets
              return fetchReservation(token, secret)(dispatch, getState).then(() => {
                afterBulk(dispatch, getState, token, secret, to);
              });
            }
          })
          .catch((errors) => {
            if (!errors) {
              return dispatch(setLoading('RESERVATION_CREATE_FAILURE'));
            }

            if (!Array.isArray(errors)) {
              const formattedError = getErrorText(errors);
              dispatch(addServerErrors(formattedError));
              return dispatch(setLoading('RESERVATION_CREATE_FAILURE'));
            }

            errors.forEach(function (error) {
              const creationErrors = getCreationErrors(getErrorText(error));
              if (creationErrors.length > 1 || creationErrors[0].detail != '') {
                dispatch(addServerErrors(creationErrors));
              }

              if (error.status == 500) {
                // Remove reservation only if Internal error happens,
                // but on on validation error to give the customer ability
                // to fix the reservation (i.e. ticket quantity)
                return dispatch(deleteReservation(false)).then(() => {
                  dispatch(setLoading('RESERVATION_CREATE_FAILURE'));
                });
              }

              return dispatch(setLoading('RESERVATION_CREATE_FAILURE'));
            });
          });
      })
      .catch((e) => {
        let errors = [];
        if (e.response && e.response.body) {
          errors = getCreationErrors(e.response.body.errors);
        } else {
          errors = getCreationErrors(e);
        }
        dispatch(addServerErrors(errors));
        return dispatch(deleteReservation(false)).then(() => {
          dispatch(setLoading('RESERVATION_CREATE_FAILURE'));
        });
      });
  };
}

/**
 * after creating the reservation:
 * if all steps are complete and free reservation, submit free payment and go to confirm
 * else, transition to checkout for next steps
 */
export const transitionAfterCreateReservation = (token, to = 'checkout') => {
  return (dispatch, getState) => {
    const state = getState();
    if (allStepsComplete(state) && isReservationFree(state)) {
      zeroDollarPayment()(dispatch, getState)
        .then((result) => {
          dispatch(goToConfirmation());
        })
        .catch((error) => {
          /*handled in zeroDollarPayment*/
        });
    } else {
      dispatch(triggerTransition(to, { token }));
    }
  };
};

const shouldTransitionToPyos = (state) => {
  return (
    !getReserveCouponUsage(state)?.attributes?.coupon_link_id &&
    showBestAvailablePicker(state) &&
    !useServerSideBestAvailable(state) &&
    isPyos(state)
  );
};

const transitionToPyos = () => {
  return (dispatch) => {
    dispatch(setShowBestAvailablePicker(false));
    dispatch(setShowPyosPicker(true));
  };
};

/**
 * Determines where to send the user and what actions to take
 * after a reservation is initially created
 */
async function afterBulk(dispatch, getState, token, secret, to = 'checkout') {
  dispatch(setLoading('RESERVATION_CREATE_SUCCESS'));
  let state = getState();
  const isKiosk = isKioskMode(state);

  /**
   * Perform the after reservation create transition
   * unless there is a popup upsell to display.  In that case, send the user to
   * the popup where the continue button will perform the after create reservation
   * transition.
   */
  const doTransition = () => {
    const state = getState();

    if (hasPopupUpsells(state) && !state.upsells.hasPoppedUp) {
      dispatch(setShowUpsellPopup(true));
    }
    // following best available flow, it will open PYOS before going to checkout
    if (shouldTransitionToPyos(state)) {
      dispatch(transitionToPyos());
    } else {
      dispatch(transitionAfterCreateReservation(token, to));
    }
  };

  if (isKiosk) {
    const kioskOptions = getKioskOptions(state);
    const availableDeliveryOptions = getAllAvailableDeliveryOptions(state);
    const collectingCustomerInfo = kioskOptions.collectCustomerName || kioskOptions.collectCustomerEmail;
    const hasMobileDelivery = kioskOptions.allowMobileDelivery && getMobileDeliveryOptions(state).length;
    const hasWillCallDelivery = kioskOptions.allowWillCallDelivery && getWillCallDeliveryOptions(state).length;
    const hasEmailDelivery = kioskOptions.allowEmailDelivery && getEmailDeliveryOptions(state).length;
    let noDeliveryOptions = getNoDeliveryOptions(state);
    let noDelivery = noDeliveryOptions.length ? noDeliveryOptions[0] : null;
    if (availableDeliveryOptions.length) {
      if (noDelivery || !kioskOptions.printReceiptsAndTickets) {
        //if this kiosk is not configured to allow delivery methods, submit No Delivery method.
        //If it has delivery methods, submit later when the user chooses a method.
        if (!hasMobileDelivery && !hasWillCallDelivery && !hasEmailDelivery) {
          dispatch(setDeliveryMethod(noDelivery));
          return dispatch(submitDelivery(noDelivery.id)).then(() => {
            //if we are collecting any customer info in kiosk mode, we won't need to submit a member request
            //here because member info will be submitted in the registration checkout step
            if (!collectingCustomerInfo) {
              return dispatch(submitMemberInfo()).then(() => {
                doTransition();
              });
            } else {
              doTransition();
            }
          });
        } else {
          if (!collectingCustomerInfo) {
            return dispatch(submitMemberInfo()).then(() => {
              doTransition();
            });
          } else {
            doTransition();
          }
        }
      } else {
        dispatch(addAlert('checkout__event_requires_no_delivery_method'));
        // @todo should probably throw an error cause this is misconfigured
      }
    } else {
      dispatch(addAlert('checkout__event_has_no_delivery_methods_assigned'));
    }
  } else {
    /**
     * Apply tagged discount coupon after creating reservation
     * Remove series check when support for series parent listing tagged coupons added
     */
    if (
      !getMainEvent(getState())?.event?.isRecurring &&
      !state.coupon.code &&
      state.ui?.uiConfig?.taggableCode &&
      state.ui?.uiConfig?.hasTaggableDiscount
    ) {
      dispatch(setCouponCode(state.ui.uiConfig.taggableCode));
      dispatch(addCouponCode(state.ui.uiConfig.taggableCode));
      let refetchAfterCoupon = true;
      try {
        await dispatch(sendCouponUsageRequest());
        dispatch(setShowSuccess(true));
      } catch (err) {
        dispatch(setCouponCode(''));
        dispatch(clearCouponCodes());
        refetchAfterCoupon = false;
      }
      if (refetchAfterCoupon) {
        fetchReservation(token, secret)(dispatch, getState).then(() => {
          doTransition();
        });
      } else {
        doTransition();
      }
    } else {
      doTransition();
    }
  }
}

function getCreationErrors(e) {
  let errors = [];
  if (typeof e === 'string') {
    errors = [{ detail: e }];
  } else if (Array.isArray(e)) {
    errors = e;
  } else if (e instanceof Error) {
    errors = [{ detail: e.message }];
  } else {
    errors = e.response.body.errors;
  }

  for (let i = 0; i < errors.length; i++) {
    if (errors[i].detail === 'exceeded_ticket_limit_select_max_to_continue') {
      errors[i].detail = 'Selection exceeds the maximum ticket limit.';
    }
  }

  return errors;
}

export function sendBulkRequest(apiUrl, token, secret, requests) {
  let req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({ secret, requests }),
  };

  return wrappedFetch(apiUrl + '/reservations/' + token + '/bulk', req).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    if (res.status === 500) {
      throw new Error('checkout__reservation_error');
    }
  });
}

function getBundleProductConfig(selectedConfigurations) {
  let configurations = {};
  Object.keys(selectedConfigurations).forEach((productId) => {
    let configurationIds = [];
    Object.keys(selectedConfigurations[productId]).forEach((keyName) => {
      Object.keys(selectedConfigurations[productId][keyName]).forEach((configId) => {
        configurationIds.push(configId);
      });
    });

    configurations[productId] = configurationIds;
  });

  return configurations;
}

function processBundleResponse(dispatch, state, bundles) {
  let listing = {
    event: { ...Object.values(state.event.events)[0] },
    selectedListing: { ...state.eventSeries.selectedListing },
    venue: { ...state.venue },
    priceLevels: { ...state.priceLevels },
  };
  dispatch(addListingToReservation(listing));
  dispatch(setBundleSales(bundles));
  return Promise.resolve();
}

function processTicketResponse(dispatch, state, tickets) {
  let listing = {
    event: { ...Object.values(state.event.events)[0] },
    selectedListing: { ...state.eventSeries.selectedListing },
    venue: { ...state.venue },
    priceLevels: { ...state.priceLevels },
  };

  dispatch(addListingToReservation(listing));
  dispatch(setTickets(tickets));
}

function processProductResponse(dispatch, state, products) {
  let listing = {
    event: { ...Object.values(state.event.events)[0] },
    selectedListing: { ...state.eventSeries.selectedListing },
    venue: { ...state.venue },
    priceLevels: { ...state.priceLevels },
  };
  dispatch(addListingToReservation(listing));
  dispatch(setProductSales(products));
}

/**
 * Keep track of which reservations we have already fetched
 * so that we can perform actions on the first fetch of a reservation
 */
const reservationsFetchedByToken = [];
const reservationHasBeenFetched = (token) => {
  return reservationsFetchedByToken.includes(token);
};
const markReservationFetched = (token) => {
  if (!reservationHasBeenFetched(token)) {
    reservationsFetchedByToken.push(token);
  }
};

export function fetchReservation(token, secret, tick = true, fireCartChangeHooks = true) {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(setLoading('RESERVATION_FETCH_REQUEST'));
    return getCheckoutClient(state.ui.apiUrl)
      .getResource('reservations', token, '', secret)
      .then((result) => {
        let reservationData = result.body.data;
        if (reservationData.attributes.autoCouponAmount !== null) {
          dispatch(setAutoCouponAmount(reservationData.attributes.autoCouponAmount));
        }
        if (!reservationData.attributes?.hasReserveCoupon) {
          dispatch(setReserveCouponUsages([]));
        }
        if (!reservationData.attributes?.hasInviteCoupon) {
          dispatch(setInviteCouponUsages([]));
        }

        let requests = [
          fetchDeliveryOptions(token, secret)(dispatch, getState),
          fetchQuestions(token, secret)(dispatch, getState),
          fetchDonations(token, secret)(dispatch, getState),
        ];

        if (state.upsells.shouldFetchUpsells) {
          requests.push(fetchUpsells(token, secret)(dispatch, getState));
        }

        if (
          (state.coupon.code !== '' && state.coupon.showSuccess === true) ||
          !!reservationData.attributes?.hasReserveCoupon ||
          !!reservationData.attributes?.hasInviteCoupon
        ) {
          requests.push(fetchCouponUsage(token, secret)(dispatch, getState));
        }

        if (reservationData.attributes.ticket_count > 0 || reservationData.attributes.bundle_count > 0) {
          requests.push(fetchTickets(token, secret, fireCartChangeHooks)(dispatch, getState));
        }

        if (reservationData.attributes.product_count > 0) {
          requests.push(fetchProducts(token, secret, fireCartChangeHooks)(dispatch, getState));
        }

        if (reservationData.attributes.bundle_count > 0) {
          requests.push(fetchBundles(token, secret, fireCartChangeHooks)(dispatch, getState));
        }

        dispatch(setReservationData(secret, reservationData));
        return Promise.all(requests).then(() => {
          if (
            tick &&
            !reservationData.attributes.isCompleted &&
            !reservationData.attributes.isPendingPaymentCompletion
          ) {
            dispatch(startTick());
          }
          // fetch current state after everything's refreshed
          const state = getState();
          // if the reservation is pending and we are not in pending state, go to pending state
          if (reservationData.attributes.isPendingPaymentCompletion || reservationData.attributes.isCompleted) {
            dispatch(getConfirmationData(token, secret))
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                if (reservationData.attributes.isPendingPaymentCompletion && !isCurrentRouterState('pending')(state)) {
                  dispatch(goToConfirmation(true, token));
                }
                if (reservationData.attributes.isCompleted && !isCurrentRouterState('confirmation')(state)) {
                  dispatch(goToConfirmation(false, token));
                }
              });
          } else {
            const deliveryOptions = getAllAvailableDeliveryOptions(state);
            if (deliveryOptions.length === 1 && !reservationData.attributes.selected_delivery_option) {
              dispatch(setDeliveryMethod(deliveryOptions[0]));
            }
            if (isKioskMode(state)) {
              dispatch(refreshCheckoutSteps(state));
            }
          }
          dispatch(setLoading('RESERVATION_FETCH_SUCCESS'));
          dispatch(clearAlerts());

          if (!reservationHasBeenFetched(token) && !getMainEvent(getState())?.event?.isRecurring) {
            dispatch(applyTaggableCoupon());
          }
          markReservationFetched(token);
        });
      })
      .catch((error) => {
        dispatch(clearReservationData());
        dispatch(setLoading('RESERVATION_FETCH_FAILURE'));
        if (!state.ui.isEmbed) {
          location.href = state.ui.listingSlug;
        }
      });
  };
}

export function deleteReservation(
  reset = true,
  clearChildEvent = true,
  forward = true,
  fireHooks = true,
  sendApiRequest = true,
  reason,
) {
  return (dispatch, getState) => {
    dispatch(setLoading('RESERVATION_DELETE_REQUEST'));
    let state = getState();
    let token = state.reservation.token;
    let secret = state.reservation.secret;

    const wipeDataPayload = {
      dispatch,
      getState,
      reset,
      clearChildEvent,
      forward,
      fireHooks,
      reason,
    };

    // reservation is complete, clear our local data but no need to send a delete request
    if (state.reservation.isCompleted || !reservationActive(state) || !sendApiRequest) {
      wipeLocalReservationData(wipeDataPayload);
      return Promise.resolve();
    }

    if (!sendApiRequest) {
      return;
    }

    return getCheckoutClient(state.ui.apiUrl)
      .deleteResource('reservations', token, secret)
      .then((result) => {
        wipeLocalReservationData(wipeDataPayload);
      })
      .catch((error) => {
        wipeLocalReservationData(wipeDataPayload);
      });
  };
}

// Temp function to not update all usages in current PR
export function deleteReservationWrapper({
  reset = true,
  clearChildEvent = true,
  forward = true,
  fireHooks = true,
  sendApiRequest = true,
  reason,
}) {
  return deleteReservation(reset, clearChildEvent, forward, fireHooks, sendApiRequest, reason);
}

function wipeLocalReservationData({
  dispatch,
  getState,
  reset,
  clearChildEvent = true,
  forward = true,
  fireHooks = true,
  reason,
}) {
  let state = getState();
  let event = Object.values(state.event.events)[0].event;

  //clearReservation will set this back to false
  const isAccessCodeApplied = state.coupon.isAccessCodeApplied;

  dispatch(clearReservationData({ reset, clearChildEvent, fireHooks, forward, reason }));
  if (!event.isOnsale && (event.hasPresale || (state.event.childEvent && state.event.childEvent.event.hasPresale))) {
    dispatch(setPriceLevels({}));
    dispatch(setProducts({}));
    dispatch(setBundles({}));
  }

  if (isAccessCodeApplied === true) {
    dispatch(getEventPriceLevels());
  }

  dispatch(setLoading('RESERVATION_DELETE_SUCCESS'));
  if (!event.isRecurring) {
    dispatch(triggerTransition('ticketPicker', { listing: event.listing }));
  }
  if (reset) {
    dispatch(setCheckoutOverlay(false));
  }
  removeListingItem(state.ui.listingSlug, 'selectedEventId');
}

export function fetchReservationIfNeeded(tick = true) {
  return (dispatch, getState) => {
    let state = getState();

    // if already fetching, no need to fetch again
    if (createLoadingSelector(['RESERVATION_FETCH'])(state)) {
      return Promise.resolve();
    }

    let token = state.reservation.token;
    let secret = state.reservation.secret;

    // reservation token and secret already in state means nothing to fetch
    if (token !== '' && secret !== '') {
      return Promise.resolve();
    }

    // if not already in state then try to pull from localStorage and rehydrate
    token = getListingItem(state.ui.listingSlug, 'token');
    secret = getListingItem(state.ui.listingSlug, 'secret');

    // if we're missing either the token or secret (or both) then we can't rehydrate
    if (!token || !secret) {
      return Promise.reject('Unable to rehydrate reservation');
    }

    // if we are rehydrating the reservation, we don't need to fire
    // hooks for adding items to the cart
    const fireCartChangeHooks = false;

    return fetchReservation(token, secret, tick, fireCartChangeHooks)(dispatch, getState);
  };
}

export function startTick() {
  return (dispatch, getState) => {
    let state = getState();
    if (!state.reservation.tickInterval) {
      /**
       * Set the interval on the top window because in embedded checkout,
       * it is possible to create the reservation from the process running
       * in the embedded component iframe which would result in the interval
       * getting set in the embedded component iframe.  If this happens, actions from
       * the checkout iframe cannot clear the interval resulting in crazy behavior.
       */
      let interval = getTopWindow().setInterval(() => {
        let state = getState();
        if (state.reservation.hasPayments) {
          clearInterval(interval);
          return;
        }

        // report reservation expires in every minute
        if (state.reservation.time_remaining % 60 === 0) {
          dispatch(reservationExpiresIn(state.reservation.time_remaining));
        }

        /** Remove milliseconds from JS unixtimestamp, since server's doesn't have such */
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);

        if (state.reservation.time_remaining <= 0 || currentTimestamp >= state.reservation.time_remaining_timestamp) {
          deleteReservationWrapper({
            reason: 'expired',
          })(dispatch, getState);
          dispatch(triggerTransition('ticketPicker', { listing: getState().event.listing }));
          dispatch(setCheckoutOverlay(false));
        }
        dispatch(tick());
      }, 1000);
      dispatch(tickSet(interval));
    }
  };
}

/**
 * Send requests to the server to add tickets, bundles, and products
 * from the ticket picker, bundle picker, and product picker reducers to the cart.
 *
 * Optionally accepts a reducerKey parameter. When provided, the tickets, products, and bundles
 * added to the cart will come from the sub reducers in the ticket picker, product picker, and bundle picker
 * reducers that match the provided reducer key.
 *
 * Additionally, you can pass an opts object to provide certain data instead of pulling it from redux.
 * Available options:
 * ticketQuantities  - pass to override quantities in ticket picker state
 * productQuantities - pass to override quantities in product picker state
 * bundleQuantities  - pass to override quantities in bundle picker state
 */
export function updateQuantities(reducerKey = null, upsellId = null, opts = null, loadingKey = null, to = 'checkout') {
  return (dispatch, getState) => {
    dispatch(setLoading('RESERVATION_CREATE_REQUEST'));
    if (loadingKey) {
      dispatch(setLoading(`${loadingKey}_REQUEST`));
    }
    let state = getState();
    const reservationEventId = getReservationEventId(state);
    let token = state.reservation.token;
    let secret = state.reservation.secret;
    if (opts === null || typeof opts !== 'object') {
      opts = {};
    }

    const priceLevelState = getSubState(state.priceLevel, reducerKey);
    const bundlesState = getSubState(state.bundles, reducerKey);
    const ticketPickerState = getSubState(state.ticketPicker, reducerKey);
    const productPickerState = getSubState(state.productPicker, reducerKey);
    const bundlePickerState = getSubState(state.bundlePicker, reducerKey);

    let requests = [];

    // if this is a request to add tickets to a reservation that already contains
    // tickets for a different series child, clear the other series tickets
    if (!reducerKey && !upsellId && hasTicketsCartedForOtherSeriesChild(state)) {
      const deleteTicketIds = getTicketIdsExceptForSelectedSeriesChild(state);
      if (deleteTicketIds.length) {
        requests.push(dispatch(deleteTicketsById(deleteTicketIds)));
      }
    }

    // add tickets requests
    let quantities = { tickets: [] };
    let ticketPickerQuantities = opts.ticketQuantities ? opts.ticketQuantities : ticketPickerState.quantities;
    for (const levelId in ticketPickerQuantities) {
      let totalQuantity = ticketPickerQuantities[levelId];
      try {
        if (priceLevelState.levels?.[levelId]) {
          const ticketQuantity = {
            pricing_level_id: levelId,
            quantity: totalQuantity,
            event_id: priceLevelState.levels[levelId].eventId,
          };
          if (upsellId) {
            ticketQuantity.listing_upsell_id = upsellId;
          }
          if (levelIsPWYW(priceLevelState, levelId)) {
            ticketQuantity.pwyw_price = getPWYWPriceForPriceLevel(
              priceLevelState,
              ticketPickerState,
              state.tickets,
              levelId,
            );
          }
          quantities.tickets.push(ticketQuantity);
        }
      } catch (e) {
        // eslint-disable-next-line no-undef
        Rollbar.critical(
          "'Error carting tickets while updating quantities",
          JSON.stringify({
            params: {
              attempted_level: levelId,
              state_levels: Object.keys(priceLevelState.levels),
              state: stateToString(state),
            },
          }),
        );
        throw new Error('Error carting tickets, please refresh the page, clear your reservation, and try again');
      }
    }
    if (quantities.tickets.length) {
      requests.push(dispatch(updateTicketQuantities(quantities, token, secret, false, null, upsellId)));
    }

    // if called from best available picker, add more tickets
    // there is no adjusting the quantity of best available
    const reserveCoupon = getReserveCouponUsage(state);
    if (
      showBestAvailablePicker(state) &&
      (useServerSideBestAvailable(state) || !!reserveCoupon?.attributes?.coupon_link_id)
    ) {
      const bestAvailablePayload = { tickets: [] };
      const bestAvailable = state?.reservedSeating?.bestAvailableQuantities;
      const bestAvailableSelected = {
        [Object.keys(bestAvailable)[0]]: Object.values(bestAvailable)[0],
      };
      const bestAvailableRequest = {
        event_id: reservationEventId,
        quantity: numberSelectedBestAvailableSeats(state),
        // eslint-disable-next-line no-extra-boolean-cast
        section_level: !!Object.keys(state.reservedSeating.sectionLevelQuantities).length
          ? bestAvailableSelected
          : null,
      };
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!reserveCoupon?.attributes?.coupon_link_id) {
        bestAvailableRequest.reserve_coupon_id = reserveCoupon.attributes.coupon_link_id;
      } else {
        bestAvailableRequest.pricing_level_id =
          Object.keys(bestAvailable)[0] == 'best_available' ? null : Object.keys(bestAvailable)[0];
        bestAvailableRequest.best_available = true;
      }
      bestAvailablePayload.tickets.push(bestAvailableRequest);
      if (bestAvailablePayload.tickets.length) {
        requests.push(
          getCheckoutClient(state.ui.apiUrl)
            .reservationCreateRequest(token, 'tickets', bestAvailablePayload, secret)
            .then(() => {
              dispatch(setSectionLevelQuantities({}));
              dispatch(setBestAvailableQuantities({}));
            })
            .catch((err) => {
              if (err.response && err.response.body) {
                dispatch(addServerErrors(err.response.body.errors));
              } else {
                dispatch(addAlert(err));
              }
            }),
        );
      }
    }

    let configQuantities = { products: [] };
    let productPickerQuantities = opts.productQuantities
      ? opts.productQuantities
      : productPickerState.selectedConfigurations;
    let selectedConfigurationQuantities = getConfigurationQuantitiesFromProductSales(
      state.productSales.products,
      upsellId,
    );
    if (Object.keys(productPickerQuantities).length > 0) {
      for (const configId in productPickerQuantities) {
        let configTotalQty = productPickerQuantities[configId];
        if (selectedConfigurationQuantities[configId]) {
          configTotalQty += selectedConfigurationQuantities[configId];
        }
        configQuantities.products.push({
          product_configuration_id: configId,
          quantity: configTotalQty,
          event_id: reservationEventId,
        });
      }

      if (configQuantities.products.length) {
        requests.push(dispatch(updateProductQuantities(configQuantities, token, secret, true, upsellId)));
      }
    }

    // add bundles requests
    let bundlePayload = { bundles: [] };
    let bundlePickerQuantities = opts.bundleQuantities ? opts.bundleQuantities : bundlePickerState.bundleQuantities;
    let selectedBundleConfigurations = bundlePickerState.selectedConfigurations;
    let bundles = bundlesState.bundles;
    for (const bundleId in bundlePickerQuantities) {
      bundlePayload.bundles.push({
        eventId: bundles[bundleId].eventId,
        bundleId: bundleId,
        quantity: bundlePickerQuantities[bundleId],
        productConfigs: selectedBundleConfigurations[bundleId]
          ? getBundleProductConfig(selectedBundleConfigurations[bundleId])
          : [],
      });
    }

    if (bundlePayload.bundles.length > 0) {
      requests.push(dispatch(addBundles(bundlePayload, token, secret)));
    }

    return Promise.all(requests)
      .then(() => {
        let updateRequests = [];
        if (
          state.coupon.code !== '' &&
          Object.keys(state.coupon.couponData).length === 0 &&
          state.coupon.showSuccess === true
        ) {
          updateRequests.push(dispatch(submitCoupon(state.coupon.code)));
        }

        if (state.donations.hasDonations) {
          updateRequests.push(dispatch(updateDonations()));
        }

        Promise.all(updateRequests)
          .then(() => {
            return Promise.all([
              dispatch(fetchTickets(token, secret)),
              dispatch(fetchProducts(token, secret)),
              dispatch(fetchBundles(token, secret)),
            ]).then(() => {
              dispatch(fetchReservation(token, secret)).then(() => {
                const state = getState();

                dispatch(setLoading('RESERVATION_CREATE_SUCCESS'));
                if (loadingKey) {
                  dispatch(setLoading(`${loadingKey}_SUCCESS`));
                }
                if (hasPopupUpsells(state) && !state.upsells.hasPoppedUp) {
                  dispatch(setShowUpsellPopup(true));
                }
                if (shouldTransitionToPyos(state)) {
                  dispatch(transitionToPyos());
                } else {
                  const upToDateState = getState();
                  if (numberOfItemsInCart(upToDateState) > 0) {
                    dispatch(triggerTransition(to, { token }));
                  }
                }
              });
            });
          })
          .catch((e) => {
            dispatch(setLoading('RESERVATION_CREATE_FAILURE'));
            if (loadingKey) {
              dispatch(setLoading(`${loadingKey}_FAILURE`));
            }
          });
      })
      .catch((e) => {
        dispatch(setLoading('RESERVATION_CREATE_FAILURE'));
        if (loadingKey) {
          dispatch(setLoading(`${loadingKey}_FAILURE`));
        }
      });
  };
}

export function clearActiveReservationDetails() {
  return (dispatch, getState) => {
    dispatch(clearReservationData());
    dispatch(setPriceLevels({}));
    dispatch(setProducts({}));
    dispatch(setBundles({}));
  };
}

export function tick() {
  return { type: 'TICK' };
}

export function tickSet(interval) {
  return { type: 'TICK_SET', interval };
}

export function stopTick() {
  return { type: 'TICK_CLEAR' };
}

export function setDeliveryMethod(selectedDelivery) {
  return (dispatch, getState) => {
    dispatch(_setDeliveryMethod(selectedDelivery));
    dispatch(feesSlice.actions.setFee({ type: 'delivery', id: selectedDelivery.id, price: selectedDelivery.price }));
  };
}

export function _setDeliveryMethod(selectedDelivery) {
  return { type: 'DELIVERY_METHOD_SET', selectedDelivery };
}
export function setPurchaseUser(id) {
  return { type: 'SET_PURCHASE_USER', id };
}

export function saveOptInQuestionAnser(opt_in_question_id, payload) {
  return (dispatch, getState) => {
    let state = getState();
    let token = state.reservation.token;
    let secret = state.reservation.secret;
    return getCheckoutClient(state.ui.apiUrl).saveOptInQuestionAnser(token, secret, opt_in_question_id, payload);
  };
}

export function saveInsuranceOption(payload) {
  return (dispatch, getState) => {
    let state = getState();
    let token = state.reservation.token;
    let secret = state.reservation.secret;
    return getCheckoutClient(state.ui.apiUrl).saveInsuranceOption(token, secret, payload);
  };
}

export function _extendTime() {
  return { type: 'EXTEND_TIME'};
}

export function extendTime() {
  return (dispatch, getState) => {
    let state = getState();
    let token = state.reservation.token;
    let secret = state.reservation.secret;
    return getCheckoutClient(state.ui.apiUrl).extendReservationTime(token, secret).then(result => {
        dispatch(_extendTime());
        return Promise.resolve();
      });
  };

}