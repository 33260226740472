import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AddTickets from './AddTickets';
import BookTimeslotBuyout from './BookTimeslotBuyout';
import PriceLevelProps from './PriceLevelComponent';
import FormattedCurrency from '@s/FormattedCurrency';
import DetailsToggle from '@s/DetailsToggle'
import { TICKET_FEE_SEPARATE, TICKET_PRICE_ONLY } from '../../../reducers/ui';
import { toMinorUnit } from "@h/currency";
import Translation from '@s/Translation';
import { getJSFormattedCurrency } from '@h/currency';
import { CurrencyContext } from '../../../config/CurrencyContext';
import { getXssFilter } from 'checkout/helpers/helpers';
import Badge from '@showclix-shared/Badge';

class PriceLevel extends Component<PriceLevelProps> {
    constructor(props) {
        super(props);

        this.state = {showDescription: false, pwywString: ''}
    }

    componentDidMount() {
        const price = this.props.pwywPrice ?? 0;
        this.setState({pwywString: price.toFixed(2)})
    }

    toggleDescription = () => {
        this.setState({showDescription: !this.state.showDescription});
    };

    basePrice = () => {
        // if this item has a discounted price, display the discounted price
        if (this.props.level?.discounted_price?.price !== undefined) {
            return this.props.level.discounted_price.price;
        }
        if (!!this.props.isPWYW) {
            return toMinorUnit(this.getPWYWPrice(), this.props.level?.min_price?.currency ?? 'USD')
        }
        return this.props.level.price.price;
    }
    
    original_price = () =>{
        return this.props.level.price.price;
    }
    
    show_markdown = () =>{
        return this.props.level.show_markdown;
    }
    
    minPrice = () => {
        return +this.props.level?.min_price?.price;
    }

    serviceFee = () => {
        // if this item has a discounted service fee, display the discounted service fee
        if (this.props.level?.discounted_service_fee?.price !== undefined) {
            return this.props.level.discounted_service_fee.price;
        }
        return this.props.level.service_fee.price;
    }

    /**
     * Should the pay what you want controls be shown
     */
    showPWYWControls = () => {
        return !!this.props.isPWYW && !!this.props.canEnterPWYWPrice;
    }

    /**
     * Is there a quantity greater than 0 selected for this level
     */
    hasQuantity = () => {
        return this.props.quantity && this.props.quantity > 0;
    }

    setPWYWPrice = e => {
        this.setState({pwywString: e.target.value});
        if (typeof this.props.onSetPWYWPrice === 'function') {
            this.props.onSetPWYWPrice(e.target.value);
        }
    }

    getPWYWPrice = () => {
        return +this.props.pwywPrice;
    }

    /**
     * Get the UI to show underneath the name of the price level
     */
    getTicketInformation = (basePrice, serviceFee, totalPrice, showFeeSeparately, originalTotalPrice, show_markdown) => {
        if (this.props.customTicketInformationBelowName !== undefined) {
            return (
                <div className="ticket__fee-disclosure">
                    {this.props.customTicketInformationBelowName}
                </div>
            )
        }

        if (!this.showPWYWControls()) {
            return (
                <Fragment>
                    {showFeeSeparately &&
                        <>
                            {!this.props.showPriceLevelInInfo && (
                              <div className="hide-on-medium-screen">
                                <div className="ticket__price--total">
                                  {show_markdown && 
                                    <span class="ticket__price--markdown">
                                      <strike>
                                        <FormattedCurrency>{ originalTotalPrice}</FormattedCurrency>
                                      </strike>
                                    </span>
                                  }
                                  <span className={show_markdown ? 'ticket__price--discounted' : ''}>
                                    <FormattedCurrency>{ totalPrice }</FormattedCurrency>
                                  </span>
                                </div>
                              </div>
                            )}
                            <span className="ticket__price">
                                <FormattedCurrency>{ basePrice }</FormattedCurrency>
                            </span>
                            <CurrencyContext.Consumer>
                                {currencyConfig => {
                                    return (
                                        <span className="ticket__fee">
                                            &nbsp;+ <Translation interpolation = {{amount:getJSFormattedCurrency(serviceFee, currencyConfig)}}>
                                                {this.props.feeVerbiage ? 'checkout__fees_with_amount' : 'checkout__service_fee_with_amount'}
                                           </Translation>
                                        </span>
                                    )
                                }}
                            </CurrencyContext.Consumer>

                        </>
                    }
                    {!showFeeSeparately &&
                        <div className="ticket__pricing-group hide-on-medium-screen">
                            <div className="ticket__price"><FormattedCurrency>{ basePrice }</FormattedCurrency></div>
                        </div>
                    }
                </Fragment>
            );
        } else {
            if (!this.hasQuantity()) {
                return (
                    <div className='hide-on-medium-screen'>
                        <div className="ticket__price"><Translation>{'checkout__pay_what_you_want'}</Translation></div>
                    </div>
                );
            }
            const pwywPrice = this.getPWYWPrice();
            const pwywMinorUnit = toMinorUnit(pwywPrice, this.props.level?.min_price?.currency ?? 'USD')
            return (
                <Fragment>
                    <div className="hide-on-large-screen">
                        <span style={{display: 'block', marginTop: '5px'}}>
                            {this.getPWYWInput()}
                        </span>
                    </div>
                    {showFeeSeparately &&
                        <div>
                            <div className="ticket__price">
                            <CurrencyContext.Consumer>
                                {currencyConfig => {
                                    return (
                                        <Translation interpolation={{minorUnit:getJSFormattedCurrency(pwywMinorUnit, currencyConfig), amount:getJSFormattedCurrency(serviceFee, currencyConfig)}}>
                                            {'checkout__ticket_fee'}
                                        </Translation>
                                    )
                                }}
                            </CurrencyContext.Consumer>
                            </div>
                        </div>
                    }
                    {!showFeeSeparately &&
                        <div className="ticket__pricing-group">
                            <div className="ticket__price"><FormattedCurrency>{ pwywMinorUnit }</FormattedCurrency></div>
                        </div>
                    }
                </Fragment>
            );
        }
    }

    pwywLessThanMin = () => {
        const pwyw = toMinorUnit(this.getPWYWPrice(), this.props.level?.min_price?.currency ?? 'USD');
        const min = this.minPrice();
        return pwyw < min;
    }

    getPWYWInput = () => {
        return (
            <div className="input__pay-what-you-want">
                <div className={`input margin-bottom--none ${this.pwywLessThanMin() ? 'input--error ' : ''}`}>
                    <input
                        className={`input__control input--size-2`}
                        type="number"
                        min="0.00"
                        step="0.01"
                        value={this.state.pwywString}
                        onChange={this.setPWYWPrice}
                    />
                </div>
                {this.minPrice() > 0 && <div className={`ticket__price ${this.pwywLessThanMin() ? 'input--error__text input-error-text--small' : ''}`}>
                    <CurrencyContext.Consumer>
                        {currencyConfig => {
                            return (
                                <Translation interpolation={{amount:getJSFormattedCurrency(this.minPrice(), currencyConfig)}}>
                                    {'checkout__minimum'}
                                </Translation>
                            )
                        }}
                    </CurrencyContext.Consumer>
                </div>}
            </div>

        );
    }

    getPriceLevel = (show_markdown, totalPrice, originalTotalPrice) => {
      return (
        <div className="ticket__price--total">
          {show_markdown && 
            <span class="ticket__price--markdown">
              <strike>
                <FormattedCurrency>{ originalTotalPrice}</FormattedCurrency>
              </strike>
            </span>
          }
          <span className={show_markdown ? 'ticket__price--discounted' : ''}>
            <FormattedCurrency>{ totalPrice }</FormattedCurrency>
          </span>
        </div>
      )
    }

    /**
     * Get the UI to show to the right of the ticket name
     */
    getDisplayToRightOfTicketName = (basePrice, totalPrice, showFeeSeparately, originalTotalPrice, show_markdown) => {
        if (this.props.customTicketInformationToRightOfName !== undefined) {
            return (
                <div className="ticket__pricing-group hide-on-small-screen">
                    <div className="ticket__price--total">
                        {this.props.customTicketInformationToRightOfName}
                    </div>
                </div>
            )
        }

        if (this.showPWYWControls()) {
                return (
                    <>
                        {!this.hasQuantity() ?
                            <div className="ticket__pricing-group hide-on-small-screen">
                                <div className="ticket__price"><Translation>{'checkout__pay_what_you_want'}</Translation></div>
                            </div>
                        :
                            <div className="ticket__pricing-group hide-on-small-screen margin-top--none">
                                {this.hasQuantity() && this.getPWYWInput()}
                            </div>
                        }
                    </>
                );


        } else {
            if (this.props.showPriceLevelInInfo) {
              return <div className="ticket__price--placeholder" />; // return div to not break css-grid
            }

            return (
                <div className="ticket__pricing-group hide-on-small-screen">
                    {showFeeSeparately && this.getPriceLevel(show_markdown, totalPrice, originalTotalPrice)}
                    {!showFeeSeparately &&
                        <div className="ticket__price--total"><FormattedCurrency>{ basePrice }</FormattedCurrency></div>
                    }
                </div>
            );
        }
    }

    render() {
        // don't go trying to render a price level if you don't even have a price level
        if (typeof this.props.level !== 'object' || this.props.level === null) {
            return null;
        }

        const basePrice = this.basePrice();
        const serviceFee = this.serviceFee();
        const totalPrice = basePrice + serviceFee;
        const showFeeSeparately = this.props.feeDisplayOption === TICKET_FEE_SEPARATE && totalPrice > 0;
        const originalTotalPrice = this.original_price() + serviceFee;
        const show_markdown = this.show_markdown();
        const description = getXssFilter().process(this.props.level.description);

        return(
            <Fragment>
                <div className={`ticket ${this.showPWYWControls() ? 'ticket--pwyw' : ''}`}>
                    <div className="ticket__information">
                        {this.props.level.wasHidden === true &&
                            <div className="ticket-availability"><Translation>{'checkout__access_code_applied'}</Translation></div>
                        }
                        { this.props.level.timeslot_buyout_settings &&
                            <Badge
                              className="timeslot-buyout"
                              dismissable={false}
                              size={Badge.size.small}
                              variant={Badge.variant.magenta}
                            >
                                { this.props.level.timeslot_buyout_settings.label }
                            </Badge>
                        }
                        <div className="ticket__name" role='heading' aria-level='3'>{ this.props.level.name }</div>
                        {this.props.showPriceLevelInInfo && this.getPriceLevel(show_markdown, totalPrice, originalTotalPrice)}
                        <div>{this.getTicketInformation(basePrice, serviceFee, totalPrice, showFeeSeparately, originalTotalPrice, show_markdown)}</div>
                        {this.props.level.subheading && (
                              <div className="price-level-subheading">
                                  {this.props.level.subheading}
                              </div>
                        )}
                        {this.props.level.description && <DetailsToggle onClick={this.toggleDescription}/>}
                    </div>

                    {this.getDisplayToRightOfTicketName(basePrice, totalPrice, showFeeSeparately, originalTotalPrice, show_markdown)}
                    <div className="ticket__action">
                        {
                            this.props.level.timeslot_buyout_settings
                                ? <BookTimeslotBuyout id={this.props.level.price_level_id}/>
                                : <AddTickets
                                    onIncrement={this.props.onIncrement}
                                    onDecrement={this.props.onDecrement}
                                    quantity={this.props.quantity}
                                    quantities={this.props.quantities}
                                    onQuantityInput={this.props.onQuantityInput}
                                    initialQuantities={this.props.initialQuantities}
                                    disabled={!!this.props.disabled || !!this.props.selectedTimeslotBuyout}
                                  />
                        }
                    </div>
                </div>
                {this.props.level.description && <Fragment>
                    {this.state.showDescription === true &&
                        <div className="ticket__description ticket__item">
                            <div
                              className="ticket-description__content"
                              name="description_{index}"
                              dangerouslySetInnerHTML={{
                                __html: description
                              }}
                            />
                        </div>
                    }
                </Fragment>}
            </Fragment>
        )
    }
}

PriceLevel.propTypes = {
    level: PropTypes.object.isRequired,
    slug: PropTypes.string,
    quantity: PropTypes.number,
    quantities: PropTypes.arrayOf(PropTypes.number).isRequired,
    initialQuantities: PropTypes.arrayOf(PropTypes.number),
    onQuantityInput: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired,
    pwywPrice: PropTypes.number,
    onSetPWYWPrice: PropTypes.func,
    showPriceLevelInInfo: PropTypes.bool,
    selectedTimeslotBuyout: PropTypes.object,
    feeVerbiage: PropTypes.bool
}

PriceLevel.defaultProps = {
    feeVerbiage: false
}

export default PriceLevel;
