import { createSelector } from 'reselect';
import { ISOStringToDate, isValidDateObject, getAllDatesInMonth, dateOrUnixToDate } from '../../../../helpers/time';
import { hourMinute } from '@showclix-shared-intl/dateTimeFormatters';
import { getProducts, getBundles } from '../../../selectors/event';

const getCaptureMethod = state => state.payment.method;
const getEventDates = state => Object.values(state?.event?.events ?? {})[0]?.event?.series?.eventDates ?? [];
const getSoldOutDates = state => Object.values(state?.event?.events ?? {})[0]?.event?.series?.soldOutDates ?? [];
const getEventStart = state => Object.values(state?.event?.events ?? {})[0]?.event?.series?.start ?? null;
const getEventEnd = state => Object.values(state?.event?.events ?? {})[0]?.event?.series?.end ?? null;
const seriesConfig = state => state.ui && state.ui.checkoutConfig && state.ui.checkoutConfig.seriesOptions ? state.ui.checkoutConfig.seriesOptions : {};
const getWidthForSeriesCalendar = state => state.eventSeries.widthForSeriesCalendar;
const getCustomCalendarColors = state => getSeriesOption("calendarColors", {})(state);
const getCustomCalendarDescriptions = state => getSeriesOption("calendarDescriptions", {})(state);
const getEventsToday = state => state.eventSeries.eventsToday;
const getTime = state => state.time && state.time.currentTime ? state.time.currentTime : null;
const getEvent = state => state.event;
const getUnlockedPresaleEvents = state => state.coupon.unlockedPresaleEvents;
const getLocale = state => state.intl ? state.intl.locale : null;
const getUiCurrentMonth = state => state.ui.currentMonth;
const getInventoryFilter = state => state.inventoryFilter;
const getLayoutName = state => state.ui.uiConfig.layout;
const getSelectedChildEvent = state => state.eventSeries.selectedChildEvent;
const getTicketsInCart = state => state.tickets.tickets;

export const seriesHasSchedule = createSelector(
    [getEventStart],
    (eventStart) => {
        return eventStart ? true  : false;
    }
);

export const getFormattedEventDates = createSelector(
    [getEventDates],
    (eventDates) => {
        let formattedDates = [];
        let dates = Object.values(eventDates).sort();
        dates.forEach(date => {
            formattedDates.push(formatDate(date));
        });

        return formattedDates;
    }
);

export const getFormattedStartMonth = createSelector(
    [getEventStart, getEventDates],
    (eventStart, dates) => {
        let date = formatDate(eventStart, true);
        if(dates && dates.length > 0) {
            return formatDate(dates[0], true);
        }
        return date;
    }
);

export const getFormattedEndMonth = createSelector(
    [getEventEnd],
    (eventEnd) => {
        return formatDate(eventEnd, true);
    }
);

export const getFormattedSoldOutDates = createSelector(
    [getSoldOutDates, seriesConfig],
    (soldOutDates, theSeriesConfig) => {
        let showSoldOut = false;
        if(theSeriesConfig){
            showSoldOut = theSeriesConfig.showSoldOut;
        }
        let formattedDates = [];
        if(showSoldOut) {
            let dates = Object.values(soldOutDates).sort();
            dates.forEach(date => {
                formattedDates.push(formatDate(date));
            });
        }

        return formattedDates;
    }
);

export const getDisabledDates = createSelector(
    [getEventDates, getSoldOutDates, getEventStart, getEventEnd, seriesConfig],
    (eventDates, soldOutDates, eventStart, eventEnd, theSeriesConfig) => {
        let showSoldOut = false;
        if(theSeriesConfig){
            showSoldOut = theSeriesConfig.showSoldOut;
        }

        let dates = [];
        eventDates = Object.values(eventDates).sort();
        eventDates.forEach(date => {
            dates.push(formatDate(date).getTime());
        });

        let formattedSoldOutDates = [];
        if(showSoldOut) {
            soldOutDates = Object.values(soldOutDates).sort();
            soldOutDates.forEach(date => {
                formattedSoldOutDates.push(formatDate(date).getTime());
            });
        }

        let start = setToDateOfTheMonth(eventStart);
        let end = setToDateOfTheMonth(eventEnd, true);

        let disabled = [
          {
                after: new Date(end),
                before: new Date(start),
          }
        ];

        let datesBetweenRange = [];
        for (let d = start; d <= end; d.setDate(d.getDate() + 1)){
            datesBetweenRange.push(new Date(d).getTime());
        }
        for (let i = 0; i < datesBetweenRange.length; i++) {
            if (dates.indexOf(datesBetweenRange[i]) === -1 && formattedSoldOutDates.indexOf(datesBetweenRange[i]) === -1) {
                disabled.push(new Date(datesBetweenRange[i]));
            }
        }

        return disabled;
    }
);

//Get number of months that the series calendar should show
export const getNumberOfMonths = createSelector(
    [getWidthForSeriesCalendar, getEventStart, getEventEnd, getCaptureMethod, getLayoutName],
    (width, eventStart, eventEnd, method, layout) => {
        if (layout === 'hero') {
          return width <= 960 && width > 768 ? 2 : 1;
        }

        if (width < 590 || method === 13) {
            return 1;
        }

        const startMonth = formatDate(eventStart, true);
        const endMonth = formatDate(eventEnd, true);

        const startingMonth = startMonth.getMonth();
        const startingYear = startMonth.getFullYear();
        const endingMonth = endMonth.getMonth();
        const endingYear = endMonth.getFullYear();
        if(startingMonth == endingMonth && startingYear == endingYear){
            return 1;
        }

        return 2;
    }
);

function formatDate(date, noDay = null) {
    if (!date?.split) {
        return new Date();
    }

    let parts = date.split("-");

    if (noDay === true) {
        return new Date(parts[0], parts[1]-1);
    }

    return new Date(parts[0], parts[1]-1, parts[2]);
}

function setToDateOfTheMonth(date, isEnd = null) {
    let parts = date.split("-");
    let newDate = new Date(parts[0], parts[1]-1, parts[2]);
    if (isEnd === true) {
        // set to last day
        let day = new Date(parts[0], parts[1], 0).getDate();
        newDate.setDate(day);
    } else {
        // set to first day
        newDate.setDate(1);
    }

    return newDate;
}

export const getCalendarColors = createSelector([getCustomCalendarColors], (colors) => {
    let sortedColors = {};

    for (const date in colors) {
        // do not add if there is no color
        if (!colors[date]) {
          continue;
        }
        if(!sortedColors[colors[date]]) {
            sortedColors[colors[date]] = [];
        }

        sortedColors[colors[date]].push(dateOrUnixToDate(date));
    }

    return sortedColors;
});

export const getCalendarDescriptions = createSelector([getCustomCalendarDescriptions], (descriptions) => {
    let sortedDescriptions = {};

    for (const date in descriptions) {
        // do not add if there is no description
        if (!descriptions[date]) {
          continue;
        }
        if(!sortedDescriptions[descriptions[date]]) {
            sortedDescriptions[descriptions[date]] = [];
        }

        sortedDescriptions[descriptions[date]].push(dateOrUnixToDate(date));
    }

    return sortedDescriptions;
});

const getNextAvailableEventTodayFromList = eventsToday => {
    let nextAvailableEvent = null;
    let now = new Date();
    let nowTimeStamp = now.getTime();
    if (Array.isArray(eventsToday)) {
        eventsToday.forEach(eventToday => {
            let eventTimeDateObject = ISOStringToDate(eventToday.time);
            if ((eventToday.eventStatus === "on_sale") &&
                isValidDateObject(eventTimeDateObject) &&
                eventTimeDateObject.toLocaleDateString('en-US') === now.toLocaleDateString('en-US') &&
                eventTimeDateObject.getTime() > nowTimeStamp &&
                (nextAvailableEvent === null || ISOStringToDate(nextAvailableEvent.time).getTime() - nowTimeStamp > eventTimeDateObject.getTime() - nowTimeStamp)) {
                nextAvailableEvent = eventToday;
            }
        });
    }
    return nextAvailableEvent;
};

export const getNextAvailableEventToday = createSelector([getEventsToday, getTime], (eventsToday, currentTime) => {
    return getNextAvailableEventTodayFromList(eventsToday);
});

export const getNextAvailableEventTodayTime = createSelector([getEventsToday, getTime], (eventsToday, currentTime) => {
    let nextAvailableEventTodayTime = null;
    let nextAvailableEvent = getNextAvailableEventTodayFromList(eventsToday);
    if (nextAvailableEvent && nextAvailableEvent.time && isValidDateObject(ISOStringToDate(nextAvailableEvent.time))) {
        nextAvailableEventTodayTime = ISOStringToDate(nextAvailableEvent.time);
    }
    return nextAvailableEventTodayTime;
});

export const getNextAvailableEventTodayId = createSelector([getEventsToday, getTime], (eventsToday, currentTime) => {
    let nextAvailableEventTodayId = null;
    let nextAvailableEvent = getNextAvailableEventTodayFromList(eventsToday);
    if (nextAvailableEvent && nextAvailableEvent.id) {
        nextAvailableEventTodayId = nextAvailableEvent.id;
    }
    return nextAvailableEventTodayId;
});

export const getNextAvailableEventTodayInventory = createSelector([getEventsToday, getTime], (eventsToday, currentTime) => {
    let nextAvailableEventTodayInventory = null;
    let nextAvailableEvent = getNextAvailableEventTodayFromList(eventsToday);
    if (nextAvailableEvent && nextAvailableEvent.inventory) {
        nextAvailableEventTodayInventory = nextAvailableEvent.inventory;
    }
    return nextAvailableEventTodayInventory;
});

//returns bool - whether there are pre_sale events that will go on sale today
export const todayHasPresaleEvents = createSelector([getEventsToday, getTime], (eventsToday, currentTime) => {
    let result = false;
    let now = new Date();
    if (Array.isArray(eventsToday)) {
        eventsToday.forEach(eventToday => {
            let salesOpenDate = ISOStringToDate(eventToday.time);
            if (eventToday.eventStatus === "pre_sale" &&
                isValidDateObject(salesOpenDate) &&
                salesOpenDate.toLocaleDateString('en-US') === now.toLocaleDateString('en-US')) {
                result = true;
            }
        });
    }
    return result;
});

/**
 * Creates a selector to get a value from state.ui.checkoutConfig.seriesOptions
 * if option does not exist in seriesOptions, return the provided default
 *
 * @param  optionName - the option to get from the seriesOptions object
 * @param  defaultValue - the value to return if the optionName does not exist
 */
export function getSeriesOption(optionName, defaultValue) {
    return createSelector([seriesConfig], (seriesOptions) => {
        return getSeriesOptionFromSeriesOptions(seriesOptions, optionName, defaultValue);
    });
}

function getSeriesOptionFromSeriesOptions(seriesOptions, optionName, defaultValue) {
    return typeof seriesOptions[optionName] !== 'undefined' ? seriesOptions[optionName] : defaultValue;
}

//is the selected child event on sale in the future
export const selectedPresaleSeriesChild = createSelector([getEvent], (event) => {
    if (event && event.childEvent && event.childEvent.event && event.childEvent.event.onsaleDate) {
        let childEvent = event.childEvent.event;
        let onsaleDate = ISOStringToDate(childEvent.onsaleDate);
        let now = new Date();
        if (isValidDateObject(onsaleDate) && onsaleDate > now) {
            return true;
        }
    }
    return false;
});

//was the selected child event unlocked by a presale code
export const unlockedSelectedPresaleSeriesChild = createSelector([getEvent, getUnlockedPresaleEvents], (event, unlockedEvents) => {
    //make sure indexOf(int)
    if (selectedPresaleSeriesChild({event: event}) && unlockedEvents.indexOf(parseInt(event.childEvent.event.event_id)) !== -1) {
        return true;
    }
    return false;
});

export const getSeriesChildOnsaleDate = createSelector([getEvent], (event) => {
    if (event && event.childEvent && event.childEvent.event && event.childEvent.event.onsaleDate) {
        let onsaleDate = ISOStringToDate(event.childEvent.event.onsaleDate);
        if (isValidDateObject(onsaleDate)) {
            return onsaleDate
        }
    }
    return null;
});

export const getLowInventoryMessage = createSelector([seriesConfig], (seriesOptions) => {
    return (inventory, translation = null) => {
        let message = null;

        if (inventory && !isNaN(inventory)) {
            const lowInventory = getSeriesOptionFromSeriesOptions(seriesOptions, "lowInventory", null);
            const lowInventoryThreshold = getSeriesOptionFromSeriesOptions(seriesOptions, "lowInventoryThreshold", null);
            const showLowInventoryMessage = getSeriesOptionFromSeriesOptions(seriesOptions, "showLowInventoryMessage", null);
            const lowInventoryMessage = getSeriesOptionFromSeriesOptions(seriesOptions, "lowInventoryMessage", null);

            if (lowInventory && +inventory <= +lowInventoryThreshold) {
                if (translation && translation?.t) {
                    message = translation.t('checkout__tickets_remaining', {inventory});
                }
                if(showLowInventoryMessage) {
                    message = lowInventoryMessage;
                }
            }
        }

        return message;
    }
});

export const getSoldOutMessage = createSelector([seriesConfig], (seriesOptions) => {
    return () => {
        const customMessage = getSeriesOptionFromSeriesOptions(seriesOptions, "soldOutMessage", null);
        if (customMessage) {
            return customMessage;
        }
        return "This time is sold out";
    }
});

export const getEventTimeFormatted = createSelector([getLocale], (locale) => {
    return (time, endTime) => {
        let formattedTimeString = null;
        if (locale && time) {
            const startTime = ISOStringToDate(time);
            formattedTimeString = hourMinute(locale, startTime);

            if(endTime && endTime !== "") {
                endTime = ISOStringToDate(endTime);
                if (isValidDateObject(endTime)) {
                    endTime = hourMinute(locale, endTime);
                    formattedTimeString += " - " + endTime;
                }
            }
        }
        return formattedTimeString;
    }
});

export const hasSelectedChildEvent = createSelector(
    [getEvent],
    (event) => {
        return event && typeof event.childEvent === 'object' && event.childEvent.event ? true : false;
    }
);

export const getCurrentMonth = createSelector(
    [getUiCurrentMonth, getEventStart, getEventDates],
    (currentMonth, eventStart, eventDates) => {
        if (currentMonth) {
            return ISOStringToDate(currentMonth);
        } else {
            let parts = [];

            if(eventDates && eventDates.length > 0) {
                parts = eventDates[0].split('-'); //YYYY-MM-DD
            } else if (eventStart) {
                parts = eventStart.split('-'); //YYYY-MM-DD
            }

            if (parts.length === 3) {
                return new Date(parts[0], +parts[1] - 1, 1);
            }
        }
    }
);

//return array of visible calendar days in YYYY-MM-DD format
export const getVisibleCalendarDays = (currentMonth, numberMonths) => {
    if (!isValidDateObject(currentMonth)) return [];
    let allDates = [];
    allDates = allDates.concat(getAllDatesInMonth(currentMonth.getFullYear(), currentMonth.getMonth()));
    if (numberMonths === 2) {
        let nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
        allDates = allDates.concat(getAllDatesInMonth(nextMonth.getFullYear(), nextMonth.getMonth()));
    }
    return allDates.sort();
}

export const hasActiveInventoryFilter = createSelector(
    [getInventoryFilter],
    (inventoryFilter) => {
        return Object.keys(inventoryFilter.priceLevelQuantities).length > 0 || Object.keys(inventoryFilter.bundleQuantities).length > 0
    }
)

export const canSkipToCheckoutWithInventoryFilter = createSelector(
    [seriesConfig, getEvent],
    (seriesOptions, event) => {
        const skipToCheckout = getSeriesOptionFromSeriesOptions(seriesOptions, "inventoryFilterSkipToCheckout", false);
        if (skipToCheckout) {
            const eventState = {event:event};
            const products = getProducts(eventState);
            const bundles = getBundles(eventState);
            if (!Object.keys(products).length && !Object.keys(bundles).length) {
                return true;
            }
        }
        return false;
    }
)

export const needsDatesLoaded = (props) => {

    let visibleCalendarDates = getVisibleCalendarDays(props.currentMonth, props.numberOfMonths);

    let result = false;
    visibleCalendarDates.forEach(date => {
        if (props.loadedDates.indexOf(date) === -1) {
            result = true;
        };
    });
    return result;
}

export const hasTicketsCartedForOtherSeriesChild = createSelector(
    [getSelectedChildEvent, getTicketsInCart],
    (selectedChildEvent, tickets) => {
        if (!selectedChildEvent) {
            return false;
        }
        return !!tickets.filter(t => t.event_id != selectedChildEvent && !t.listing_upsell_id).length;
    }
);

export const getTicketIdsExceptForSelectedSeriesChild = createSelector(
    [getSelectedChildEvent, getTicketsInCart],
    (selectedChildEvent, tickets) => {
        if (!selectedChildEvent) {
            return [];
        }
        return tickets
            .filter(t => !(t.event_id == selectedChildEvent && !t.listing_upsell_id))
            .map(t => t.id);
    }
);
