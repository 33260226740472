import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Props from './FooterComponent';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import clsx from 'clsx';
import Translation from "../../shared/Translation";
import { getMainEvent } from '../../checkout/selectors/event';
import Icon from '@showclix-shared/Icon';

export class ContactInfo extends Component<Props> {
    getFormattedPhoneNumber = () => {
        return "tel:" + this.props.contactInfo.phone;
    }

    getContactUsUrl = () => {
        return "/event/" + this.props.event_id + "/contact?referral_url=" + window.location.href;
    }

    getWebsiteUrl = () => {
        if(this.props.contactInfo.website.startsWith("http://") || this.props.contactInfo.website.startsWith("https://")){
            return this.props.contactInfo.website;
        } else {
            return "//" + this.props.contactInfo.website;
        }
    }

    areAllContactInfoDisabled() {
        return !this.props.contactInfo.enableContactForm ||
            (!this.props.contactInfo.enableMailingAddress &&
            !this.props.contactInfo.enableTelephone &&
            !this.props.contactInfo.enableWebsite &&
            !this.props.contactInfo.validatedEmail);
    }

    render() {
        const shouldUseThemeDark = this.props.uiConfig.styles["CONTENT__reverse-text"] && this.props.uiConfig.styles["FULLSCREEN"];
        const pageContactClassName = clsx('page__contact', { 'theme--dark': shouldUseThemeDark });

        if (!this.props.showContactOnListing || this.areAllContactInfoDisabled()) {
            <div className={pageContactClassName}/>
        }

        return(
            <div className={pageContactClassName}>
                {this.props.contactInfo.enableContactForm &&
                <div className="page-contact__outer">
                    <div className="page-contact__inner">
                        <div className="page__contact__top">
                            <h2 className="page__contact__org-title">
                                <Translation>
                                    {'checkout__contact_info'}
                                </Translation>
                            </h2>
                        </div>
                        <div className="page__contact__bottom">
                            {this.props.contactInfo.enableMailingAddress && (
                                <div className="page__contact__bottom-section">
                                    <div className="page__contact__address">
                                        {this.props.contactInfo.address}
                                    </div>
                                </div>
                            )}
                            {this.props.contactInfo.enableTelephone && (
                                <div className="page__contact__bottom-section">
                                    <div className="page__contact__phone">
                                        <a href={this.getFormattedPhoneNumber()} rel={"nofollow"}>{this.props.contactInfo.phone}</a>
                                    </div>
                                </div>
                            )}
                            {this.props.contactInfo.enableWebsite && (
                                <div className="page__contact__url">
                                    <a href={this.getWebsiteUrl()}>{this.props.contactInfo.website}</a>
                                </div>
                            )}
                            {(this.props.contactInfo.orglisting || this.props.contactInfo.validatedEmail) && (
                                <div className="page__contact__actions">
                                    {this.props.contactInfo.validatedEmail && (
                                        <div className="page__contact__email">
                                            <div className="page__contact__email-link-container">
                                                {!!this.props.event_id && (
                                                    <a
                                                        target="_blank"
                                                        href={this.getContactUsUrl()}
                                                        className="button button--medium page__contact__email-link"
                                                    >
                                                        <Icon icon='icon-markunread' size='14' color='inherit' />
                                                        <Translation>{"checkout__contact_us"}</Translation>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {!!this.props.contactInfo.orglisting && (
                                        <div className="page__contact__orglisting__url">
                                            <a
                                              target="_blank"
                                              href={this.props.contactInfo.orglisting}
                                              className="button button--medium org-listing-url"
                                            >
                                                <Icon icon='icon-calendar' size='14' color='inherit' />
                                                <Translation>{"checkout__more_events_by"}</Translation> {this.props.contactInfo.name}
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

ContactInfo.propTypes = {
    contactInfo: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        website: PropTypes.string,
        enableContactForm: PropTypes.bool,
        enableContactFormInEmail: PropTypes.bool,
        enableWebsite: PropTypes.bool,
        enableMailingAddress: PropTypes.bool,
        enableTelephone: PropTypes.bool
    }).isRequired,
    event_id: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
}

const mapStateToProps = (state, props) => {
    return {
        contactInfo: state.contactInfo,
        event_id: getMainEvent(state)?.event?.event_id ?? '',
        showContactOnListing: (state.ui.uiConfig && state.ui.uiConfig.listings) ?
            (state.ui.uiConfig.listings.showContactOnListing != null ? state.ui.uiConfig.listings.showContactOnListing : false)
            : false,
        uiConfig: state.ui.uiConfig,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
