import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';

/**
 * Accepts all listed props + any props that HTML tag can accept,
 * such as "onClick", "tabIndex", "onMouseLeave", etc.
 */
const Icon = forwardRef(({
  className: classNameProp,
  size = '20px',
  icon,
  color = 'primary',
  alt = '',
  rotateDeg = 0,
  style: styleProp = {},
  ...rest
}, ref) => {
  const className = useMemo(() =>
    classNames('icon', icon, classNameProp),
    [classNameProp, icon],
  );

  // Spread at the end, so developer can pass custom color, fontSize or transform if needed
  const style = useMemo(() => {
    const hex = color?.startsWith('#');

    const computedColor = hex ? color : `var(--${color})`;

    return {
      color: computedColor,
      fontSize: size,
      transform: `rotateZ(${rotateDeg}deg)`,
      ...styleProp,
    }
  }, [color, size, rotateDeg, styleProp]);

  return (
    <i
      ref={ref}
      className={className}
      aria-hidden={!alt}
      aria-label={alt}
      style={style}
      {...rest}
    />
  )
})

export default Icon;
