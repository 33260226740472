import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Props from './ContinueCheckoutBannerComponent';
import FormattedCurrency from '@s/FormattedCurrency';
import Translation from "@s/Translation";

class ContinueCheckoutBanner extends Component<Props> {
    render() {
        return(
            <Fragment>
                { (this.props.itemCount > 0 || this.props.forceShow) &&
                    <div className={`checkout__status-bar__sticky ${this.props.pinToBottom ? 'status-bar--bottom' : ''}`}>
                        <button className="checkout__status-bar" onClick={this.props.showCart}>
                            <div className="checkout__status-bar__cart">
                                <span className="checkout__status-bar__cart-icon">
                                <svg width="22px" height="22px" viewBox="0 0 24 24" role='img' aria-label='Shopping cart' aria-hidden='false'>
                                    <path fill="#000000" d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z" />
                                </svg>
                                { +this.props.itemCount > 0 &&
                                    <span className="checkout__status-bar__cart-count">
                                    {this.props.itemCount}
                                    </span>
                                }
                                </span>
                                <span className="checkout__status-bar__cart-title">
                                    <Translation>{'checkout__cart'}</Translation>
                                </span>
                            </div>
                            <div className="checkout__status-bar__right">
                                <div className="checkout__status-bar__total">
                                    <FormattedCurrency>{this.props.amount}</FormattedCurrency>
                                </div>
                                <div className="checkout__status-bar__timer">
                                    <i className="checkout__status-bar__timer-icon">
                                    <svg width="19px" height="19px" viewBox="0 0 24 24" role='img' aria-label='Timer' aria-hidden='false'>
                                        <path fill="#000000" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                                    </svg>
                                    </i>
                                    <span className="checkout__status-bar__timer-time" aria-live='polite'>
                                        {this.props.timeRemaining}
                                    </span>
                                    <div className="checkout__status-bar__action">
                                        <span className="checkout__status-bar__action-more" onClick={this.props.showCart} >
                                            <svg width="17px" height="17px" viewBox="0 0 24 24">
                                                <path fill="#000000" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                                            </svg>
                                            <span className="sr-only"><Translation>{"checkout__continue"}</Translation></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </Fragment>
        )
    }
}

ContinueCheckoutBanner.propTypes = {
    showCart: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired
}

export default ContinueCheckoutBanner
