// @flow
import type {ClearAction, CreateAction} from '../../../reducers/reservation';
import { getReducerState, returnReducerState } from '../../../reducers/subReducerHelpers';
import { isValidDateObject, getAllDatesInMonth } from '../../../../helpers/time';
import { stringToDate } from '@showclix-shared-util/dates'
export type SetEventTimes = {type: "SET_EVENT_TIMES", eventTimes: object};
export type SetSelectedEvent = {type: "SET_SELECTED_EVENT", event: object};

export type Action = SetEventTimes | SetSelectedEvent | ClearAction | CreateAction;
type State = { eventTimes: Object, selectedListing: object };
const defaultState = {
    eventTimes: [],
    selectedListing: {},
    eventTimesByDate: {},
    selectedDateObject: null, //javascript date object
    selectedDate: '',         //string representation of selected date
    selectedChildEvent: null,
    selectedEventTimeObject: null, //selected from eventTimes
    widthForSeriesCalendar: 0, //updated by SeriesDayPicker.  Only used to determine how many months should be displayed for the series calendar
    eventsToday: [],
    fetchedEventsToday: false,
    seriesDatesLoading: false,
    datesLazyLoaded: [], //keeps track of which dates we have requested to prevent duplicate requests
    seriesChildAdditionalInfo: null,
    selectedDateDescription: null,
    networkError: false
};
let initialState = defaultState;

export function eventSeries(state: State = initialState, action:Action): State {
    if(action.type === "FETCH_EVENT") {
        let newState = getReducerState(state, defaultState, action);
        if (action.data && action.data.attributes && typeof action.data.attributes.events === 'object') {
            let event = Object.values(action.data.attributes.events)[0];

            //populate lazy loaded dates to prevent unnecessary requests
            if (event.event && event.event.series && Array.isArray(event.event.series.eventDates)) {
                let uniqueYearMonths = [];
                event.event.series.eventDates.forEach(date => {
                    let parts = date.split('-');//YYYY-MM-DD
                    if (parts.length === 3) {
                        let yearmonth = parts[0] + '-' + parts[1];
                        if (uniqueYearMonths.indexOf(yearmonth) === -1) {
                            uniqueYearMonths.push(yearmonth);
                        }
                    }
                });
                if (uniqueYearMonths.length) {
                    let allDates = [];
                    uniqueYearMonths.forEach(yearmonth => {
                        let parts = yearmonth.split('-');
                        allDates = allDates.concat(getAllDatesInMonth(parts[0], +parts[1] - 1));
                    });
                    newState.datesLazyLoaded = [...allDates];
                }
            }
        }
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_EVENT_TIMES") {
        let newState = getReducerState(state, defaultState, action);
        const sortedTimes = sortEventTimesByTime(action.eventTimes);
        newState.eventTimes = sortedTimes;
        newState.selectedDateDescription = action.selectedDateDescription;
        if(action.date) {
            newState.eventTimesByDate = {...newState.eventTimesByDate};
            newState.eventTimesByDate[stringToDate(action.date).toISOString()] = sortedTimes;
        }
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_SELECTED_EVENT") {
        let newState = getReducerState(state, defaultState, action);
        newState.selectedListing = action.data;
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_SELECTED_DATE") {
        let newState = getReducerState(state, defaultState, action);
        newState.selectedDate = action.date;
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_SELECTED_DATE_OBJECT") {
        let newState = getReducerState(state, defaultState, action);
        newState.selectedDateObject = isValidDateObject(action.date) ? action.date : null;
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_EVENT_TIME_FOR_DATE") {
        let newState = getReducerState(state, defaultState, action);
        newState.eventTimesByDate[action.date] = sortEventTimesByTime(action.eventTimes);
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_SELECTED_CHILD_EVENT") {
        let newState = getReducerState(state, defaultState, action);
        newState.selectedChildEvent = action.id;
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_SELECTED_CHILD_EVENT_INFO") {
        let newState = getReducerState(state, defaultState, action);
        newState.seriesChildAdditionalInfo = action.childEventAdditionalInfo;
        return returnReducerState(state, newState, action);
    } else if(action.type === "SET_EVENT_TIME_FOR_DATES") {
        let newState = getReducerState(state, defaultState, action);
        newState.eventTimesByDate = {...newState.eventTimesByDate};
        for (const date in action.eventTimes) {
            newState.eventTimesByDate[stringToDate(date).toISOString()] = sortEventTimesByTime(action.eventTimes[date]);
        }
        return returnReducerState(state, newState, action);
    } else if (action.type === "RESERVATION_CLEARED") {
        return {
            ...initialState,
            widthForSeriesCalendar: window.innerWidth,
            eventsToday: state.eventsToday,
            fetchedEventsToday: state.fetchedEventsToday,
            datesLazyLoaded: state.datesLazyLoaded,
        };
    } else if (action.type === "CLEAR_EVENT_TIMES") {
        let newState = getReducerState(state, defaultState, action);
        newState.eventTimes = [];
        newState.eventTimesByDate = {};
        return returnReducerState(state, newState, action);
    } else if (action.type === "SET_WIDTH_FOR_SERIES_CALENDAR") {
        let newState = getReducerState(state, defaultState, action);
        newState.widthForSeriesCalendar = action.width;
        return returnReducerState(state, newState, action);
    } else if (action.type === "SET_FETCHED_EVENTS_TODAY") {
        let newState = getReducerState(state, defaultState, action);
        newState.fetchedEventsToday = action.value;
        return returnReducerState(state, newState, action);
    } else if (action.type === "SET_EVENTS_TODAY") {
        let newState = getReducerState(state, defaultState, action);
        newState.eventsToday = action.value;
        return returnReducerState(state, newState, action);
    } else if (action.type === "SET_SELECTED_EVENT_TIME_OBJECT") {
        let newState = getReducerState(state, defaultState, action);
        newState.selectedEventTimeObject = action.data;
        return returnReducerState(state, newState, action);
    } else if (action.type === "SET_DATES_LAZY_LOADED") {
        let newState = getReducerState(state, defaultState, action);
        newState.datesLazyLoaded = [...action.dates];
        return returnReducerState(state, newState, action);
    } else if (action.type === "REFRESH_EVENT") {
        let newState = getReducerState(state, defaultState, action);
        newState.datesLazyLoaded = [];
        return returnReducerState(state, newState, action);
    } else if (action.type === "SET_NETWORK_ERROR") {
        let newState = getReducerState(state, defaultState, action);
        newState.networkError = action.networkError;
        return returnReducerState(state, newState, action);
    } else if (action.type === "SAVE_CURRENT_CHILD_EVENT") {
        const newState = getReducerState(state, defaultState, action);
        newState.prevState = createPrevState(newState);
        return returnReducerState(state, newState, action);
    } else if (action.type === "REVERT_CHILD_EVENT_CHANGES") { 
        let newState = getReducerState(state, defaultState, action);

        if (!newState.prevState) return newState;

        newState = {
          ...newState,
          ...newState.prevState,
          prevState: undefined
        };
        return returnReducerState(state, newState, action);
    } else {
        return state;
    }
}

const createPrevState = (state) => ({
  eventTimes: state.eventTimes,
  selectedListing: state.selectedListing,
  eventTimesByDate: state.eventTimesByDate,
  selectedDateObject: state.selectedDateObject,
  selectedDate: state.selectedDate,
  selectedChildEvent: state.selectedChildEvent,
  selectedEventTimeObject: state.selectedEventTimeObject,
  eventsToday: state.eventsToday,
  seriesChildAdditionalInfo: state.seriesChildAdditionalInfo,
  selectedDateDescription: state.selectedDateDescription
});

export const sortEventTimesByTime = toSort => {
    if (!Array.isArray(toSort)) return toSort;

    const times = [...toSort];

    //validate that we have an array of objects each with a valid time property before attempting to sort
    let allValid = true;
    times.forEach(time => {
        if (typeof time !== 'object' || time === null || time.time === undefined || isNaN(stringToDate(time.time).getTime())) {
            allValid = false;
        }
    });
    if (allValid) {
        times.sort((a,b) => {
            return stringToDate(a.time).getTime() - stringToDate(b.time).getTime();
        });
    }
    
    return times;
}
