import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@s/Button';
import { createLoadingSelector } from '../../../selectors/helpers';
import {
  isKioskMode,
  getCheckoutStepsSettings,
  getCurrentCheckoutStep,
  isSubmitStepButtonDisabled,
} from '../../../selectors/ui';
import { submitStep } from '../../../actions/ui';
import { upsellRequestLoading } from '../../../views/upsells/selectors/upsells';
import Translation from '@s/Translation';

class SubmitStepButton extends Component {
  render() {
    let isPaymentStep =
      this.props.currentStep &&
      this.props.currentStep.title &&
      (this.props.currentStep.title === 'checkout__payment' || this.props.currentStep.title === 'Payment');

    if (this.props.isKioskMode && isPaymentStep) {
      return null;
    }

    const buttonClass = this.props.isKioskMode
      ? 'button--primary button--large kiosk-footer__button--primary'
      : 'button--primary button--large checkout-form__button-submit';
    const buttonLastStepText = this.props.isKioskMode ? 'checkout__pay' : 'checkout__continue';

    const disabled =
      !!this.props.upsellRequestLoading ||
      !!this.props.routerTransitioning ||
      this.props.isButtonDisabled ||
      this.props.disabled; // does not rely on the redux state, can be passed from parent component

    const loadingTranslation = !this.props.lastStep || this.props.insuranceOptionLoading
      ? 'checkout__loading'
      : 'checkout__submitting_order';

    return (
      <Button
        onClick={this.submitStep}
        className={buttonClass}
        loading={this.props.loading || this.props.insuranceOptionLoading}
        loadingText={
          <Translation>{loadingTranslation}</Translation>
        }
        disabled={disabled}
        role="link"
        data-href="#next-step"
      >
        <Translation>{this.props.lastStep ? 'checkout__complete_order' : buttonLastStepText}</Translation>
      </Button>
    );
  }

  submitStep = () => {
    this.props.submitStep(this.props.settings, this.props.currentStepIndex);
  };
}

const stepLoading = createLoadingSelector('CHECKOUT_STEP');
const insuranceOptionLoading = createLoadingSelector('INSURANCE_OPTION');
const mapStateToProps = (state) => {
  return {
    upsellRequestLoading: upsellRequestLoading(state),
    loading: stepLoading(state),
    insuranceOptionLoading: insuranceOptionLoading(state),
    currentStepIndex: state.ui.currentStep,
    settings: getCheckoutStepsSettings(state),
    isKioskMode: isKioskMode(state),
    lastStep: state.ui.listingConfig.checkoutSteps.length - 1 === state.ui.currentStep,
    currentStep: getCurrentCheckoutStep(state),
    routerTransitioning: !!state.router.transitioning,
    isButtonDisabled: isSubmitStepButtonDisabled(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitStep,
    },
    dispatch,
  );
};

SubmitStepButton.propTypes = {
  lastStep: PropTypes.bool,
  buttonText: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitStepButton);
