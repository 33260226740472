import {getCheckoutClient} from '../../../config/CheckoutClient';
import { addServerErrors } from '../../../actions/ui';
import { getMemberPayload } from 'checkout/selectors/request';
import { isEmail } from '@showclix-shared-util/validators';

export function setName(name) {
    return {type: "SET_MEMBER_NAME", name};
}

export function setEmail(email) {
    return {type: "SET_MEMBER_EMAIL", email};
}

export function setPhone(phone) {
    return {type: "SET_MEMBER_PHONE", phone};
}

export function setAddress(address) {
    return {type: "SET_MEMBER_ADDRESS", address};
}

export function setSaved(value) {
    return {type: "SET_MEMBER_SAVED", value};
}

export function setEmailOptOut(value) {
    return {type: "SET_MEMBER_EMAIL_OPT_OUT", value};
}

//modify initial state to change the default value
export function setDefaultEmailOptOut(value) {
    return {type: "SET_DEFAULT_MEMBER_EMAIL_OPT_OUT", value};
}

export function setCreatedNewAccount(value) {
    return {type: "SET_MEMBER_CREATED_NEW_ACCOUNT", value};
}

export function setMemberFieldError(field, error) {
    return {
        type: "SET_MEMBER_FIELD_ERROR",
        payload: {
            field,
            error
        }
    }
}

const validateName = (name) => {
    let [, lastName] = name.trim().split(" ").filter(Boolean)

    if (!lastName) {
        return {
            message: 'checkout__invalid_member_name',
        }
    }
}

const validateEmail = (email) => {
    if (!isEmail(email)) {
        return {
            message: 'checkout__invalid_email_pattern'
        }
    }
}

const memberValidators = {
    name: validateName,
    email: validateEmail
}

export function validateMemberField(field) {
    return (dispatch, getState) => {
        const state = getState();

        if (!(field in state.member)) return;

        const value = state.member[field];

        if (!value) {
            dispatch(setMemberFieldError(field, { message: 'checkout__member_required_field' }));
            return;
        }

        const validate = memberValidators[field];

        if (!validate) return;

        const error = validate(value);

        if (error) {
            dispatch(setMemberFieldError(field, error));
            return;
        }

        // Clear error
        dispatch(setMemberFieldError(field, undefined));
    }
}

export function submitMemberInfo() {
    return (dispatch, getState) => {
        let state = getState();
        let payload = getMemberPayload(state);

        return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(state.reservation.token, 'customer', payload, state.reservation.secret).then((result) => {
            dispatch(setSaved(true));
        }).catch((error) => {
            if (error.response.statusCode === 400) {
                dispatch(addServerErrors(error.response.body.errors));
                return Promise.reject("");
            }
        });
    }
}

export function refetchMember() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      getCheckoutClient(state.ui.apiUrl)
        .reservationGetRequest(state.reservation.token, 'customer', state.reservation.secret)
        .then((result) => {
          if (result?.ok && result.body?.data?.attributes
            && Object.keys(result.body.data.attributes).length > 0) {
            const memberAttributes = result.body.data.attributes;
            const fullName = memberAttributes.first_name + ' ' + memberAttributes.last_name;
            dispatch(setName(fullName));
            dispatch(setEmail(memberAttributes.email));
            dispatch(setPhone(memberAttributes.phone));
            dispatch(setAddress({
              name: fullName,
              address: memberAttributes.address,
              address2: memberAttributes.address2,
              state: memberAttributes.state,
              city: memberAttributes.city,
              country: memberAttributes.country,
              zip: memberAttributes.zip
            }));
            resolve();
          }
          reject();
        });
    });
  }
}
