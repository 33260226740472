/* @flow */

export type FetchAction = {type: "FETCH_EVENT", data: Object};
export type SetPriceLevelsAction = {type: "SET_PRICE_LEVELS", data: Object};
export type SetProductsAction = {type: "SET_PRODUCTS", data: Object};
export type UpdatePriceLevelsAction = {type: "UPDATE_PRICE_LEVELS", data: Object};
export type SetBundlesAction = {type: "SET_BUNDLES", data: Object};
export type SetChildEventAction = {type: "SET_CHILD_EVENT", data: Object};

export type Action = FetchAction | SetPriceLevelsAction| SetProductsAction | SetBundlesAction | SetChildEventAction | UpdatePriceLevelsAction;

import { getReducerState, returnReducerState, applyToParentAndAllSubReducers } from './subReducerHelpers.js';

type State = {+loading: boolean};

const defaultState = {
  loading: true,
  refreshLoading: false,
  events: {},
  // the event that is added by "listingInfo" used for multi listing children only
  // if listing is not embed and not multi should always stay "undefined"
  fetchedEventId: undefined
};
let initialState = defaultState;

export function event(state: State = initialState, action:Action): State {

    let newState = {...state};

    /**
     * Cases that only apply to parent reducer
     */
    if (action.type === "FETCH_EVENT" || action.type === "REFRESH_EVENT") {
        let seller_id = Object.values(action.data?.attributes.events)[0].seller_id;
        newState = {...newState, loading: false, events: {...action.data?.attributes.events}, seller_id, listing: action.data.listing};
        return newState;
    } else if(action.type === 'RESERVATION_CLEARED' || action.type === 'RESERVATION_COMPLETED') {
        return applyToParentAndAllSubReducers(newState, s => {
            // clear selected child event for series when clearing or completing reservation
            if (s.childEvent && (action.type === 'RESERVATION_COMPLETED' || !!action.clearChildEvent)) {
                delete s.childEvent;
            }
            return s;
        });
    } else if(action.type === 'SET_FETCHED_EVENT') {
        newState.fetchedEventId = Number(action.id);
        return newState;
    }

    /**
     * Cases that may apply to sub reducers
     */
    newState = getReducerState(state, defaultState, action);
    if (action.type === "SET_DATA_FOR_EVENT_IF_NOT_SET") {
        if (newState.events[action.eventId] === undefined) {
            newState.events = { [action.eventId]: action.eventData };
        }
    } else if(action.type === "SET_PRICE_LEVELS") {
        newState.price_levels = action.data;
    } else if(action.type === "SET_EVENT_PRICE_LEVELES") {
        const event = newState.events[action.id];

        if (!event) return newState;

        newState.events = {
          ...newState.events,
          [action.id]: {
            ...event,
            price_levels: action.data
          }
        }

        return newState;
    } else if(action.type === "SAVE_CURRENT_CHILD_EVENT") {
        if (!newState.childEvent) return newState;

        newState.prevChildEvent = newState.childEvent;
    } else if(action.type === "REVERT_CHILD_EVENT_CHANGES") {
        if (!newState.prevChildEvent) return newState;
      
        newState.childEvent = newState.prevChildEvent;
        newState.prevChildEvent = undefined;
    } else if(action.type === "SET_PRODUCTS") {
        newState.products = action.data;
    } else if(action.type === "UPDATE_PRICE_LEVELS") {
        newState.price_levels = action.data;
    } else if(action.type === "SET_BUNDLES") {
        newState.bundles = action.data;
    } else if(action.type === "SET_CHILD_EVENT") {
        newState.childEvent = action.data;
    } else if(action.type === "SET_EVENT_REFRESH_LOADING") {
        newState.refreshLoading = action.value ? true : false;
    } else if(action.type === "SET_SERIES_EVENT_DATES") {
        newState = applySeriesDates(newState, action.dates, 'eventDates');
    } else if(action.type === "SET_SERIES_SOLD_OUT_DATES") {
        newState = applySeriesDates(newState, action.dates, 'soldOutDates');
    }
    return returnReducerState(state, newState, action);
}


/**
 * Add the dates from the provided array to the array of dates in state
 * @param  object newState
 * @param  array  dates
 * @param  string keyInSeries
 * @return object
 */
const applySeriesDates = (newState, dates, keyInSeries) => {
    if (typeof newState.events === 'object' && Object.values(newState.events).length) {
        let event = Object.values(newState.events)[0];
        let eventId = event.event_id;
        // should be getting event ID from event.event.event_id, but checking event.event_id for backward compatibility
        if (!eventId && !!event?.event?.event_id) {
            eventId = event.event.event_id;
        }
        if (event.event && event.event.series) {
            newState = {
                ...newState,
                events: {
                    ...newState.events,
                    [eventId]: {
                        ...newState.events[eventId],
                        event: {
                            ...newState.events[eventId].event,
                            series: {
                                ...newState.events[eventId].event.series,
                                [keyInSeries]: dates,
                            },
                        },
                    },
                },
            };
        }
    }
    return newState;
}
