import React, { Component } from 'react';
import { connect } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

// wraps react-focus-trap
class WrappedFocusTrap extends Component {

    render() {
        if (!this.props.useFocusTrap) {
            return this.props.children;
        }

        const props = {...this.props};
        delete props.children;
        delete props.useFocusTrap;

        if (props.focusTrapOptions === undefined) {
            props.focusTrapOptions={};
        }
        props.focusTrapOptions.fallbackFocus = props.fallback;
        props.focusTrapOptions.allowOutsideClick = allowsOutsideClicks;

        return (
            <FocusTrap {...props}>
                {this.props.children}
            </FocusTrap>
        )
    }
}

const classNames = ["onetrust", "privacy", "switch", "checkout__status-bar", "add-guarantee", "weather-guarantee", "hydrated"];
const ids = ["onetrust", "accept", "close-pc", "weather-guarantee","tg-placeholder"];

const allowsOutsideClicks = event => {
    const className = classNames.some(term => event.target.className?.includes(term));
    const id = ids.some(term => event.target.id?.includes(term));
    if (className || id){
        return true;
    }
    return false;
}

WrappedFocusTrap.propTypes = {
    fallback: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
    return {
        useFocusTrap: !!state?.ui?.useFocusTrap,
    }
}

export default connect(mapStateToProps)(WrappedFocusTrap)

