/* @flow */

import { toDecimals } from '../../../helpers/currency';
import { SetDataAction,ClearAction,CompletedAction } from '../../../reducers/reservation';

export type FetchAction = {type: "FETCH_EVENT", data: Object};
export type SetPriceAction = {type: "SET_INSURANCE_PRICE", price: number};
export type SetPolicyAction = {type: "SET_INSURANCE_POLICY", selected: string, options: Object};

export type Action = FetchAction | SetPriceAction | SetPolicyAction | SetDataAction | ClearAction | CompletedAction;

type State = { provider: string, key: string, selected: string, price: number, declined: boolean, widget_api_key: string, version: string, sandbox: boolean, templateId: string, insuranceItems: Array, quoteToken: string, quoteHash: string};

let initialState = { provider: "", key: "", selected: "", price: 0, declined: false, widget_api_key: "", version: "", sandbox: false, templateId: "", insuranceItems: [], quoteToken: '', quoteHash:''};

export function insurance(state: State = initialState, action: Action): State {
    if(action.type === "FETCH_EVENT" || action.type === "FETCH_LISTING_INFO") {
        let newState = {...state};

        if(action.data.insurance && !Array.isArray(action.data.insurance)) {
            let insurance = action.data.insurance;
            if (typeof insurance?.provider === 'object' && insurance?.provider !== null && Object.keys(insurance).length) {
                newState.provider = insurance.provider.provider;
                newState.key = insurance.provider.client;
                newState.widget_api_key = insurance.widget_api_key;
                newState.version = insurance.version;
                newState.sandbox = insurance.sandbox;
                newState.templateId = insurance.templateId;

                if (insurance.default === "in") {
                    newState.selected = insurance.provider.provider;
                } else if (insurance.default === "out") {
                    newState.declined = true;
                }
            }
        }

        return newState;
    } else if(action.type === "SET_INSURANCE_PRICE") {
        let newState = {...state};
        newState.price = action.price;
        return newState;
    } else if(action.type === "SET_INSURANCE_POLICY") {
        let newState = {...state};

        if(action.selected === "") {
            newState.selected = "";
            newState.declined = true;
        } else {
            newState.selected = action.selected;
            newState.declined = false;
        }

        if(action.options) {
            newState = {...newState, ...action.options}
        }

        return newState;
    } else if(action.type === "RESERVATION_DATA_SET") {
        let newState = {...state};

        // would be convenient if true encoded to true wouldn't it
        if(action.reservation.attributes.insuranceProvider === "1") {
            newState.selected = "ticketguardian";
            newState.declined = false;
        } else {
            newState.declined = true;
        }

        if (action.reservation.attributes.insuranceItems && Array.isArray(action.reservation.attributes.insuranceItems)) {
            newState.insuranceItems = action.reservation.attributes.insuranceItems.map(item => {
                return toDecimals({amount: item, currency: action.reservation.attributes.total_cost.currency});
            });
        } else {
            newState.insuranceItems = [];
        }

        return newState;
    } else if(action.type === "RESERVATION_CLEARED") {
        let newState = {...state, insuranceItems: []};

        newState.selected = "";

        return newState;
    } else if(action.type === "RESERVATION_COMPLETED") {
        let newState = {...state, insuranceItems: []};

        newState.selected = "";

        return newState;
    } else {
        return state;
    }
}
