import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hourMinute } from '@showclix-shared-intl/dateTimeFormatters';
import { getNextAvailableEventTodayTime } from '../selectors/eventSeries';
import { getMainEvent } from '../../../selectors/event';
import Translation from '@s/Translation';

const MINUTES_THRESHHOLD = 60;

class NextAvailableTime extends Component {

    render() {
        if (!this.props.nextAvailableEventTime || !this.props.isRecurring) {
            return null;
        }

        const diffms = this.props.nextAvailableEventTime.getTime() - this.props.currentTime.getTime();
        if (diffms > 0) {
            const minutes = Math.ceil((diffms / 1000) / 60);
            if (minutes < MINUTES_THRESHHOLD) {
                return <span className="next-avaliable-time">
                    <Translation interpolation={{num_minutes:minutes}}>{minutes === 1 ? 'checkout__next_available_in_a_minute' : 'checkout__next_available_in_minutes'}</Translation>
                    </span>
            }
        }

        return (
            <span className="next-avaliable-time">Next available at {hourMinute(this.props.locale, this.props.nextAvailableEventTime)}</span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.intl.locale,
        nextAvailableEventTime: getNextAvailableEventTodayTime(state),
        isRecurring: !!getMainEvent(state)?.event?.isRecurring,
        currentTime: state.time.currentTime,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};

NextAvailableTime.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NextAvailableTime)
