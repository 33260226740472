export function initAnalyticsScript(measurementId) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;

    return new Promise((resolve, reject) => {
        // Event listener for successful loading
        script.addEventListener('load', () => {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', measurementId);
            resolve();
        });
        // Event listener for error in loading the script
        script.addEventListener('error', () => {
            reject(new Error('Failed to load Google Analytics script'));
        });

        // Append the script to the body
        document.body.appendChild(script);
    });
}

function formatItem(item, type, currency) {
    return {
        item_id: item?.sellableID + '',
        item_name: item?.sellableName + '',
        item_category: type,
        price: {amount: +item?.price, currency: currency},
        quantity: 1,
    };
}

export function formatAllReservationItems(sellableItems, currency) {
    const items = [];
    sellableItems.forEach(item => {
        for (let i = 0; i < item?.quantity; i++) {
            items.push(formatItem(item, item?.sellableType, currency));
        }
    });
    return items;
}
