import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import countries from '../../../../util/countries';
import { useCheckoutProcessContext } from '../contexts/CheckoutProcessContext';
import { InsetWrapper } from './InsetWrapper';
import Name from './fields/Name';
import Phone from './fields/Phone';

const Address = (props) => {
  const { TranslationContext } = useCheckoutProcessContext();
  const translationContext = useContext(TranslationContext);

  const labelTranslationMap = {
    Territory: 'checkout__address_label_territory',
    'Zip Code': 'checkout__address_label_zip_code',
    City: 'checkout__address_label_city',
    Address: 'checkout__address_label_address',
    'Address 2': 'checkout__address_label_address_2',
    State: 'checkout__address_label_state',
    Suburb: 'checkout__address_label_suburb',
    Postcode: 'checkout__address_label_postcode',
    Parish: 'checkout__address_label_parish',
    Province: 'checkout__address_label_province',
    'Postal Code': 'checkout__address_label_postal_code',
    County: 'checkout__address_label_county',
    Locality: 'checkout__address_label_locality',
    Eircode: 'checkout__address_label_eircode',
  };

  const translateLocalizedAddressLabel = (localizedLabel) => {
    let translatedLabel = localizedLabel;
    if (!labelTranslationMap[localizedLabel]) {
      console.error('No translation defined for localized address label: ' + localizedLabel);
    } else {
      translatedLabel = translationContext.t(labelTranslationMap[localizedLabel]);
    }
    return translatedLabel;
  };

  const getAddressValues = () => {
    return {
      name: props.name,
      address: props.address,
      address2: props.address2,
      city: props.city,
      state: props.state,
      country: props.country,
      zip: props.zip,
      phone: props.phone,
      countryCode: props.countryCode,
    };
  };

  const triggerAddressChange = (vals) => {
    props.onAddressChange(vals);
  };

  const onNameChange = (e) => {
    let values = getAddressValues();
    values.name = e.target.value;
    triggerAddressChange(values);
  };

  const onCountryChange = (e) => {
    let values = getAddressValues();
    values.country = e.target.value;
    triggerAddressChange(values);
  };

  const onCityChange = (e) => {
    let values = getAddressValues();
    values.city = e.target.value;
    triggerAddressChange(values);
  };

  const onAddress2Change = (e) => {
    let values = getAddressValues();
    values.address2 = e.target.value;
    triggerAddressChange(values);
  };

  const onAddressChange = (e) => {
    let values = getAddressValues();
    values.address = e.target.value;
    triggerAddressChange(values);
  };

  const onZipChange = (e) => {
    let values = getAddressValues();
    values.zip = e.target.value;
    triggerAddressChange(values);
  };

  const onStateChange = (e) => {
    let values = getAddressValues();
    values.state = e.target.value;
    triggerAddressChange(values);
  };

  const onPhoneChange = (number) => {
    let values = getAddressValues();
    values.phone = number;
    triggerAddressChange(values);
  };

  const onCountryCodeChange = (code) => {
    let values = getAddressValues();
    values.countryCode = code;
    triggerAddressChange(values);
  };

  const getStateField = () => {
    if (!props.local_settings.state.show) {
      return '';
    }

    const translatedLabel = translateLocalizedAddressLabel(props.local_settings.state.label);

    if (props.local_settings.state.options) {
      return (
        <InsetWrapper>
          <div className="input__top">
            <label className="input__label" htmlFor="state">
              {translatedLabel}
            </label>
            {/*STYLE-TODO make this visible*/}
            {!!props.stateRequired && (
              <span className="input__required text__required">{translationContext.t('checkout__required')}</span>
            )}
          </div>
          <div className="input__select">
            <select
              className={props.state ? 'input__control' : 'input__control input__select--unselected'}
              name="state"
              placeholder={translatedLabel}
              onChange={onStateChange}
              value={props.state ? props.state : ''}
            >
              {<option value="">{translatedLabel}</option>}
              {Object.keys(props.local_settings.state.options).map((code, i) => {
                return (
                  <option value={code} autoComplete={props.local_settings.state.autocomplete} key={i}>
                    {props.local_settings.state.options[code]}
                  </option>
                );
              })}
            </select>
            <i className="icon-keyboard_arrow_down" />
          </div>
        </InsetWrapper>
      );
    }

    return (
      <InsetWrapper>
        <div className="input__top">
          <label className="input__label" htmlFor="state">
            {translatedLabel}
          </label>
          {!!props.stateRequired && (
            <span className="input__required text__required">{translationContext.t('checkout__required')}</span>
          )}
        </div>
        <input
          className="input__control"
          type="text"
          name="state"
          placeholder={translatedLabel}
          autoComplete={props.local_settings.state.autocomplete}
          value={props.state}
          onChange={onStateChange}
        />
      </InsetWrapper>
    );
  };

  const getPostalField = () => {
    if (!props.local_settings.zip.show) {
      return '';
    }

    const validTypes = ['text', 'tel'];
    const inputType =
      props.local_settings.zip.inputType && validTypes.indexOf(props.local_settings.zip.inputType) !== -1
        ? props.local_settings.zip.inputType
        : 'text'; // default

    const translatedLabel = translateLocalizedAddressLabel(props.local_settings.zip.label);

    return (
      <InsetWrapper>
        <div className="input__top">
          <label className="input__label" htmlFor="zip">
            {translatedLabel}
          </label>
          {!!props.zipRequired && (
            <span className="input__required text__required">{translationContext.t('checkout__required')}</span>
          )}
        </div>

        <input
          className="input__control"
          type={inputType}
          name="zip"
          placeholder={translatedLabel}
          autoComplete={props.local_settings.zip.autocomplete}
          value={props.zip}
          onChange={onZipChange}
          id="customer-zip"
        />
      </InsetWrapper>
    );
  };

  const getAddress1Field = () => {
    if (!props.local_settings.address.show) {
      return '';
    }

    const translatedLabel = translateLocalizedAddressLabel(props.local_settings.address.label);

    return (
      <InsetWrapper>
        <div className="input__top">
          <label className="input__label" htmlFor="address_1">
            {translatedLabel}
          </label>
          {!!props.addressRequired && (
            <span className="input__required text__required">{translationContext.t('checkout__required')}</span>
          )}
        </div>
        <input
          className="input__control"
          type="text"
          name="address_line_1"
          placeholder={translatedLabel}
          value={props.address}
          autoComplete={props.local_settings.address.autocomplete}
          onChange={onAddressChange}
        />
      </InsetWrapper>
    );
  };

  const getAddress2Field = () => {
    if (!props.local_settings.address2.show) {
      return '';
    }

    const translatedLabel = translateLocalizedAddressLabel(props.local_settings.address2.label);

    return (
      <InsetWrapper>
        <label className="input__label" htmlFor="address_2">
          {translatedLabel}
        </label>
        <input
          className="input__control"
          type="text"
          name="address_line_2"
          placeholder={translatedLabel}
          value={props.address2}
          autoComplete={props.local_settings.address2.autocomplete}
          onChange={onAddress2Change}
        />
      </InsetWrapper>
    );
  };

  const getCityField = () => {
    if (!props.local_settings.city.show) {
      return '';
    }

    const translatedLabel = translateLocalizedAddressLabel(props.local_settings.city.label);

    return (
      <InsetWrapper>
        <div className="input__top">
          <label className="input__label" htmlFor="city">
            {translatedLabel}
          </label>
          {!!props.cityRequired && (
            <span className="input__required text__required">{translationContext.t('checkout__required')}</span>
          )}
        </div>
        <input
          className="input__control"
          type="text"
          name="city"
          placeholder={translatedLabel}
          autoComplete={props.local_settings.city.autocomplete}
          value={props.city}
          onChange={onCityChange}
        />
      </InsetWrapper>
    );
  };

  return (
    <section className="checkout-form__section">
      {!!props.header && <h4 className="checkout-form__sub-heading">{props.header}</h4>}

      {/* COUNTRY WIDGET */}
      <InsetWrapper>
        <div className="input__top">
          <label className="input__label" htmlFor="country">
            {translationContext.t('checkout__country')}
          </label>
          {/*STYLE-TODO make this visible*/}
          {!!props.countryRequired && (
            <span className="input__required text__required">{translationContext.t('checkout__required')}</span>
          )}
        </div>
        <div className="input__select">
          <select
            placeholder={translationContext.t('checkout__country')}
            className={props.country ? 'input__control' : 'input__control input__select--unselected'}
            name="country"
            onChange={onCountryChange}
            value={props.country}
            autoFocus={true}
            autoComplete='country-name'
          >
            {<option value="">{translationContext.t('checkout__country')}</option>}
            {Object.keys(countries).map((code, i) => {
              return (
                <option value={code} key={i}>
                  {countries[code]}
                </option>
              );
            })}
          </select>
          <i className="icon-keyboard_arrow_down" />
        </div>
      </InsetWrapper>

      {/* NAME WIDGET */}
      <Name
        disabled={props.nameDisabled}
        name={props.name}
        onNameChange={onNameChange}
        required={!!props.nameRequired}
      />

      {/* ADDRESS 1 WIDGET */}
      {getAddress1Field()}

      {/* ADDRESS 2 WIDGET */}
      {getAddress2Field()}

      {/* CITY WIDGET */}
      {getCityField()}

      {/* STATE WIDGET */}
      <div className="row">
        <div className="col-md-8">{getStateField()}</div>
        <div className="col-md-4">
          {/* POSTAL CODE WIDGET */}
          {getPostalField()}
        </div>
        <div className="col-md-8">
          {/* ADDRESS PHONE WIDGET */}
          <Phone
            phone={props.phone}
            countryCode={props.countryCode}
            onPhoneChange={onPhoneChange}
            onCountryCodeChange={onCountryCodeChange}
            required={props.phoneRequired}
          />
        </div>
      </div>
    </section>
  );
};

Address.defaultProps = {
  country: '',
  local_settings: {
    state: {
      show: true,
      label: 'Territory',
      autocomplete: 'address-level1',
    },
    zip: {
      show: true,
      label: 'Zip Code',
      autocomplete: 'postal-code',
      inputType: 'text',
    },
    city: {
      show: true,
      label: 'City',
      autocomplete: 'address-level2',
    },
    address: {
      show: true,
      label: 'Address',
      autocomplete: 'address-line1',
    },
    address2: {
      show: true,
      label: 'Address 2',
      autocomplete: 'address-line2',
    },
  },
  name: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  phoneRequired: false,
};

Address.propTypes = {
  header: PropTypes.string,
  country: PropTypes.string,
  countryCode: PropTypes.string,
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string,
  zip: PropTypes.string.isRequired,
  phone: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  local_settings: PropTypes.object.isRequired,
  countryRequired: PropTypes.bool,
  nameRequired: PropTypes.bool,
  addressRequired: PropTypes.bool,
  cityRequired: PropTypes.bool,
  stateRequired: PropTypes.bool,
  zipRequired: PropTypes.bool,
  nameDisabled: PropTypes.bool,
  phoneRequired: PropTypes.bool.isRequired,
};

export default Address;
