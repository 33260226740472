import { createSelector } from 'reselect';
import { createLoadingSelector } from './helpers';
import { getSubState } from '../reducers/subReducerHelpers';

const getEvents = (state) => state.event;
const getLoading = (state) => state.loading;

export const getMainEvent = (state) => {
  if (
    typeof state.event.events === 'object' &&
    state.event.events !== null &&
    !!Object.keys(state.event.events).length
  ) {
    if (state.event.fetchedEventId) {
      return state.event.events[state.event.fetchedEventId];
    }

    return Object.values(state.event.events)[0];
  }
  return null;
};

export const getSelectedEvent = state => {
  if (state.event?.childEvent) {
    return state.event.childEvent;
  }
  return getMainEvent(state);
};

export const getPriceLevels = createSelector([getMainEvent], (event) => {
  let levels = {};
  if (Array.isArray(event?.price_levels)) {
    event.price_levels.map((pl) => {
      levels[pl.price_level_id] = pl;
    });
  }
  return levels;
});

export const getProducts = createSelector([getMainEvent], (event) => {
  let products = {};
  if (Array.isArray(event?.products)) {
    event.products.map((product) => {
      products[product.product_id] = product;
    });
  }
  return products;
});

export const getBundles = createSelector([getMainEvent], (event) => {
  let bundles = {};
  if (Array.isArray(event?.bundles)) {
    event.bundles.map((bn) => {
      bundles[bn.id] = bn;
    });
  }
  return bundles;
});

export const hasCoupons = createSelector([getSelectedEvent, getMainEvent], (selectedEvent, mainEvent) => {
  return !!selectedEvent?.event?.hasCoupons || !!mainEvent?.event?.hasCoupons;
});

export const get_custom_get_seat_message = createSelector([getMainEvent], (event) => {
  return event?.event?.custom_get_seat_message;
});

export const isSeries = createSelector([getMainEvent], (event) => {
  return !!event?.event?.series;
});

const getPriceLevel = (state) => state.priceLevel;

// Get price levels related to main event in multi listing embed listing
export const getFetchedEventPriceLevels = createSelector(
  [getEvents, getPriceLevel],
  ({ fetchedEventId }, { levels }) => {
    if (!fetchedEventId) return levels;

    const priceLevels = {};

    for (const level in levels) {
      if (Number(levels[level].eventId) === fetchedEventId) {
        priceLevels[level] = levels[level];
      }
    }

    return priceLevels;
  },
);

const getBundleState = (state, reducerKey) => getSubState(state.bundles, reducerKey);

export const getFetchedEventBundles = createSelector([getEvents, getBundleState], ({ fetchedEventId }, { bundles }) => {
  if (!fetchedEventId) return bundles;

  const resBundles = {};

  for (const bundle in bundles) {
    if (Number(bundles[bundle].eventId) === fetchedEventId) {
      resBundles[bundle] = bundles[bundle];
    }
  }
  return resBundles;
});

const getProductsState = (state) => state.products;

export const getFetchedEventProducts = createSelector(
  [getEvents, getProductsState],
  ({ fetchedEventId }, { products }) => {
    if (!fetchedEventId) return products;

    const resProducts = {};

    for (const product in products) {
      if (Number(products[product].eventId) === fetchedEventId) {
        resProducts[product] = products[product];
      }
    }
    return resProducts;
  });

export const getMinPriceLevel = createSelector(
  getMainEvent,
  (event) => {
    if (!event) return undefined;

    if (!event.price_levels.length) return undefined;

    if (event.price_levels.length === 1) return event.price_levels[0].min_price;

    let min = event.price_levels[0];

    for (let i = 1, length = event.price_levels.length; i < length; i++) {
      const priceLevel = event.price_levels[i];

      if (priceLevel.min_price.price < min.min_price.price) {
        min = priceLevel;
      }
    }

    return min.min_price;
  }
);
