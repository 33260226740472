import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hourMinute } from '@showclix-shared-intl/dateTimeFormatters';
import { getNextAvailableEventTodayTime } from '../selectors/eventSeries';
import { getMainEvent } from '../../../selectors/event';
import Translation from '@s/Translation';

class NextAvailableTimeSlot extends Component {

    render() {
        if (!this.props.nextAvailableEventTime || !this.props.isRecurring) {
            return null;
        }

        return (
            <span>
                <Translation interpolation={{hourMinute: hourMinute(this.props.locale, this.props.nextAvailableEventTime)}}>
                {'checkout__next_available_time_slot'}
                </Translation>
                </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.intl.locale,
        nextAvailableEventTime: getNextAvailableEventTodayTime(state),
        isRecurring: !!getMainEvent(state)?.event?.isRecurring,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};

NextAvailableTimeSlot.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NextAvailableTimeSlot)
