import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translation from '@s/Translation';

import Button from '@s/Button';
import FormattedCurrency from "../../shared/FormattedCurrency";

class TicketsButton extends Component {
    getButtonText = () => {
        let buttonText = TicketsButton.defaultProps.title;

        if (!this.props.invitationCouponApplied && !this.props.isRecurring && this.props.hasPresale && !this.props.isOnsale && Object.keys(this.props.priceLevels.levels).length === 0) {
            buttonText =  <Translation>{"checkout__enter_presale_code"}</Translation>;
        } else if (this.props.uiConfig.listings && this.props.uiConfig.listings.buttonText) {
            buttonText = <>{this.props.uiConfig.listings.buttonText}</>;
        }

        if(this.props.title) {
            buttonText = <>{this.props.title}</>;
        }

        return buttonText;
    };

    getButtonType = () => {
        let buttonType = "";

        if(this.props.type) {
            buttonType = this.props.type;
        }

        return buttonType;
    }

    render() {
        if (!this.props.maintenanceModeLoaded) {
            return(<button className="button--primary banner__cta" disabled>Loading...</button>)
        }

        if (!this.props.isCanceled && !this.props.isPaused && (this.props.invitationCouponApplied || this.props.isOnsale || (!this.props.isOnsale && this.props.hasPresale) || this.props.isRecurring)) {
            return(
                <div className="event-details__cta">
                  <Button
                      onClick={ this.props.onClick }
                      className={`button--primary banner__cta ${this.getButtonType()} ${this.props.loading ? 'loading' : 'not-loading' }`}
                      loading={this.props.loading}
                      loadingText={this.getButtonText()}
                      disabled={!!this.props.disabled}
                      role="link"
                      data-href="#get-tickets"
                  >
                    {this.getButtonText()}
                    {this.props.children}
                  </Button>
                </div>
            )
        } else {
            return(<></>)
        }
    }
}

TicketsButton.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    uiConfig: PropTypes.object,
    priceLevels: PropTypes.object,
    coupon: PropTypes.object,
    hasPresale: PropTypes.bool,
    isOnsale: PropTypes.bool,
    isCanceled: PropTypes.bool,
    invitationCouponApplied: PropTypes.bool,
    isPaused: PropTypes.bool,
};

TicketsButton.defaultProps = {
  title: <Translation>{"checkout__get_tickets"}</Translation>,
};

export default TicketsButton;
