import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import Spinner from 'shared/Spinner';
import Translation from 'shared/Translation';

class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.helpOpen === true && this.props.helpOpen === false) {
            this.setState({showSpinner: false});
        }
    }

    closeModal = () => {
        this.props.onHelpChange(false);
    };

    otherHelp = () => {
        this.setState({showSpinner: true});
        this.props.onOtherPress();
    };

    ticketHelp = () => {
        this.setState({showSpinner: true});
        this.props.onTicketPress();
    };

    render() {
        if (!this.props.helpOpen) {
            return null;
        }

        return (
            <div className="kiosk__modal">
                <div className="kiosk__modal__content">
                    {!this.props.printReceiptsAndTickets || this.state.showSpinner ?
                        <Fragment>
                            <div className="kiosk__modal__title modal-title__with-spinner">
                              <Translation>{"checkout__help_is_on_the_way"}</Translation>
                                <div className="kiosk__spinner">
                                    <Spinner />
                                </div>
                            </div>
                          <button className="kiosk-modal__button kiosk-modal__button--secondary" onClick={this.closeModal}><Translation>{"checkout__help_cancel"}</Translation></button>
                        </Fragment>
                        :
                        <Fragment>
                            <div className="kiosk__modal__title">
                              <Translation>{"checkout__need_help"}</Translation>
                                <div className="kiosk__modal__close" onClick={this.closeModal}>
                                    <i className="icon-close" aria-hidden="true"></i>
                                    <span className="sr-only"><Translation>{"checkout__close"}</Translation></span>
                                </div>
                            </div>
                            <div>
                              <button className="kiosk-modal__button kiosk-modal__button--secondary" onClick={this.ticketHelp}><Translation>{'checkout__ticket_did_not_print'}</Translation></button>
                              <button className="kiosk-modal__button kiosk-modal__button--secondary" onClick={this.otherHelp}><Translation>{'checkout__help_something_else'}</Translation></button>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        );
    }
}

Help.propTypes = {
    helpOpen: PropTypes.bool,
    onHelpChange: PropTypes.func.isRequired,
    onTicketPress: PropTypes.func.isRequired,
    onOtherPress: PropTypes.func.isRequired,
    printReceiptsAndTickets: PropTypes.bool.isRequired
};

Help.defaultProps = {
    helpOpen: false,
};

export default Help;

