// @flow
export type IncrementAction = {type: "INCREMENT_BUNDLE", id: number};
export type DecrementAction = {type: "DECREMENT_BUNDLE", id: number};
export type SetAction = {type: "SET_BUNDLE", id: number, value: number};
export type SetBundleProductConfigAction = {type: "SET_BUNDLE_PRODUCT_CONFIG", id: number, configuration: object};
export type SetBundlesQuantitiesAction = {type: "SET_NEW_BUNDLE_QUANTITIES", quantities: Object};

export type FetchAction = {type: "FETCH_EVENT", data: Object};

export type Action = IncrementAction | DecrementAction | SetAction | FetchAction | SetBundleProductConfigAction | SetBundlesQuantitiesAction;

type State = { +bundleQuantityArrays: Object, +bundleQuantities: Object, +selectedConfigurations: Object, +availableQuantities: Object, +initialQuantities: Object };

import { getReducerState, returnReducerState, applyToParentAndAllSubReducers } from '../../../reducers/subReducerHelpers';

const defaultState = { 
    bundleQuantityArrays: {},
    bundleQuantities: {},
    selectedConfigurations: {},
    availableQuantities: {},
    initialQuantities: {} 
};
let initialState = defaultState;
/** Reducer for bundle picker actions */
export function bundlePicker(state: State = initialState, action:Action): State {

    let newState = getReducerState(state, defaultState, action);

    if(action.type === "INCREMENT_BUNDLE") {
        newState.bundleQuantities = {...newState.bundleQuantities};

        if(!newState.bundleQuantities[action.id]) {
            newState.bundleQuantities[action.id] = newState.bundleQuantityArrays[action.id][0];
        } else {
            let newIndex = newState.bundleQuantityArrays[action.id].indexOf(newState.bundleQuantities[action.id]) + 1;
            newState.bundleQuantities[action.id] = newIndex >= newState.bundleQuantityArrays[action.id].length ? newState.bundleQuantities[action.id] : newState.bundleQuantityArrays[action.id][newIndex];
        }

    } else if(action.type === "DECREMENT_BUNDLE") {
        let id = action.id;
        newState.bundleQuantities = {...newState.bundleQuantities};

        let newIndex = newState.bundleQuantityArrays[id].indexOf(newState.bundleQuantities[id]) - 1;

        if(newIndex < 0) {
            delete newState.bundleQuantities[id];
            if (newState.selectedConfigurations[id]) {
                delete newState.selectedConfigurations[id];
            }
        } else {
            newState.bundleQuantities[id] = newState.bundleQuantityArrays[id][newIndex];
            if (newState.selectedConfigurations[id]) {
                Object.keys(newState.selectedConfigurations[id]).map((key, i) => {
                    if (Object.keys(newState.selectedConfigurations[id][key]).length > 0) {
                        delete newState.selectedConfigurations[id][key][Object.keys(newState.selectedConfigurations[id][key]).length - 1];
                    } else {
                        delete newState.selectedConfigurations[id][key];
                    }
                })
            }
        }

    } else if(action.type === "SAVE_CURRENT_CHILD_EVENT") {
      newState.prevState = {
          bundleQuantityArrays: newState.bundleQuantityArrays,
          bundleQuantities: newState.bundleQuantities,
          selectedConfigurations: newState.selectedConfigurations,
          availableQuantities: newState.availableQuantities,
          initialQuantities: newState.initialQuantities,
      };
    } else if(action.type === "REVERT_CHILD_EVENT_CHANGES") {
      if (!newState.prevState) return state;

      newState = {
        ...newState,
        ...newState.prevState,
        prevState: undefined
      };
    } else if(action.type === "SET_BUNDLE") {

        newState.bundleQuantities = {...newState.bundleQuantities};
        let newIndex = newState.bundleQuantityArrays[action.id].indexOf(action.value);
        newState.bundleQuantities[action.id] = newState.bundleQuantityArrays[action.id][newIndex];

    }  else if(action.type === "SET_BUNDLES") {

        newState.bundleQuantities = {...newState.bundleQuantities};
        newState.initialQuantities = {...newState.initialQuantities};

        for(let i = 0; i < action.data.length; i++) {
            newState.bundleQuantityArrays[action.data[i].id] = buildBundleQuantityArray(action.data[i]);
            newState.initialQuantities[action.data[i].id] = buildBundleQuantityArray(action.data[i]);
        }

    }  else if(action.type === "SET_BUNDLE_PRODUCT_CONFIG") {
        let bundleId = action.configuration["bundleId"];
        let configId = action.configuration["configurationId"];
        let productId = action.configuration["productId"];
        let index = action.configuration["index"];

        newState.selectedConfigurations = {...newState.selectedConfigurations};
        newState.selectedConfigurations[bundleId] = {...newState.selectedConfigurations[bundleId]};
        newState.selectedConfigurations[bundleId][productId] = {...newState.selectedConfigurations[bundleId][productId]};
        newState.selectedConfigurations[bundleId][productId][index] = {};
        if (configId !== "") {
            if (newState.selectedConfigurations[bundleId][productId][index][configId] === undefined) {
                newState.selectedConfigurations[bundleId][productId][index][configId] = 1;
            } else {
                newState.selectedConfigurations[bundleId][productId][index][configId] += 1;
            }
        } else {
            if (newState.selectedConfigurations[bundleId][productId][index]) {
                delete newState.selectedConfigurations[bundleId][productId][index];
            }
        }

    }  else if(action.type === "SET_BUNDLE_SALES") {
        newState = applyToParentAndAllSubReducers(newState, s => {
            s.bundleQuantities = {};
            s.selectedConfigurations = {};
            return s;
        });
    }  else if(action.type === "SET_NEW_BUNDLE_QUANTITIES") {
        newState.availableQuantities = action.quantities;
    } else if(action.type === "FETCH_EVENT" || action.type === "REFRESH_EVENT") {

        for (const eventId in action.data.attributes.events) {
            let eventBundles = action.data.attributes.events[eventId].bundles;
            for(let i = 0; i < eventBundles.length; i++) {
                newState.bundleQuantityArrays[eventBundles[i].id] = buildBundleQuantityArray(eventBundles[i]);
                newState.initialQuantities[eventBundles[i].id] = buildBundleQuantityArray(eventBundles[i]);
            }
        }
    }  else if(action.type === "RESERVATION_CLEARED" || action.type === "CLEAR_SELECTED_BUNDLES") {
        if(!action.reset && action.type !== "CLEAR_SELECTED_BUNDLES") {
            return state;
        }

        newState = applyToParentAndAllSubReducers(newState, s => {
            s.bundleQuantities = {};
            s.selectedConfigurations = {};
            s.availableQuantities = {};
            return s;
        });
    } else {
        return state;
    }

    return returnReducerState(state, newState, action);
}

export function buildBundleQuantityArray(bundle) {
    let min = (bundle.min_purchase_limit === 0 || bundle.min_purchase_limit === null) ? 1 : bundle.min_purchase_limit;
    let max = bundle.max_purchase_limit;
    let increment_by = bundle.increment_by;
    let quantities = [];
    for(var i = parseInt(min); i <= parseInt(max); i += increment_by) {
        quantities.push(i);
    }

    return quantities;
}
