import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TicketGuardianWrapper from '../components/TicketGuardianWrapper';
import { getInsuranceQuote, setInsurancePolicy, setInsuranceOption } from 'checkout/views/insurance/actions/insurance';
import { createLoadingSelector } from 'checkout/selectors/helpers';
import {feesSlice} from '../../../slices/fees';
import { reservationQualifiesForInsurance } from 'checkout/selectors/reservation';
import { submitInsuranceOption } from 'checkout/actions/insurance';
import { toMinorUnit } from '@h/currency';
import { updateStripePaymentIntent } from '../../../actions/payment';

class InsuranceContainer extends Component {
    componentDidMount() {
        this.props.getInsuranceQuote();
    }

    async componentDidUpdate(prevProps) {
      if (!prevProps.price && prevProps.price !== this.props.price) {
        await this.props.submitInsuranceOption();
      }
    }

    render() {
        if (!this.props.reservationQualifiesForInsurance || this.props.noReservation) {
            return null;
        }

        return(
            <div>
                {this.loadInsurance()}
            </div>
        );
    }

    loadInsurance = () => {
        if(this.props.provider === "ticketguardian") {
            return <TicketGuardianWrapper
                selected={this.props.selected === "ticketguardian"}
                price={this.props.price}
                loading={this.props.tgLoading}
                onInsuranceChange={this.onInsuranceChange}
                declined={this.props.declined}
                widget_api_key={this.props.widget_api_key}
                version={this.props.version}
                sandbox={this.props.sandbox}
                templateId={this.props.templateId}
                insuranceItems={this.props.insuranceItems}
                currency={this.props.currency}
                locale={this.props.locale}
                setInsuranceOption={this.props.setInsuranceOption}
            />
        }
    };

    onInsuranceChange = async (val, updatedPrice, quoteToken = "", quoteHash ="") => {
        if(this.props.loading) {
            return;
        }

        this.props.setInsurancePolicy(val, {quoteToken, quoteHash});
        this.updateInsuranceCost(val, updatedPrice);

        if (this.props.price) {
          await this.props.submitInsuranceOption();
        }
    };

    updateInsuranceCost = (val, updatedPrice) => {
        if (val !== "") {
            if(updatedPrice != null){
                this.props.setFee({type: 'insurance', id: null, price: {amount: updatedPrice}})
            } else {
                this.props.setFee({type: 'insurance', id: null, price: {amount: this.props.price}})
            }
        } else {
            this.props.setFee({type: 'insurance', id: null, price: {amount: 0}})
        }
    }
}

const tgLoading = createLoadingSelector("TICKETGUARDIAN_QUOTE");
const mapStateToProps = (state) => {
    if (!state.reservation.total_cost) {
        return {
            noReservation: true,
        };
    }

    const minimumOrderValue = state.settings.refundAsAServiceMinimumOrderValue;

    if (minimumOrderValue && state.reservation.ticket_total.amount < toMinorUnit(minimumOrderValue, state.reservation.ticket_total.currency)) {
      return {
        reservationQualifiesForInsurance: false
      }
    }

    return {
        provider: state.insurance.provider,
        selected: state.insurance.selected,
        total: state.reservation.total,
        price: state.insurance.price,
        tgLoading: tgLoading(state),
        declined: state.insurance.declined,
        widget_api_key: state.insurance.widget_api_key,
        version: state.insurance.version,
        sandbox: state.insurance.sandbox,
        templateId: state.insurance.templateId,
        insuranceItems: state.insurance.insuranceItems,
        currency: state.reservation && state.reservation.total_cost ? state.reservation.total_cost.currency : null,
        reservationQualifiesForInsurance: reservationQualifiesForInsurance(state),
        locale: state.intl.locale,
        amount: state.reservation.total_cost ? state.reservation.total_cost.amount : 0,
        paymentIntentSecret: state.payment.stripePaymentIntentSecret,
        paymentIntentId: state.payment.stripePaymentIntentId,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getInsuranceQuote,
        setInsurancePolicy,
        setInsuranceOption,
        setFee: feesSlice.actions.setFee,
        submitInsuranceOption,
        updateStripePaymentIntent
    }, dispatch);
}

InsuranceContainer.propTypes = {
    provider: PropTypes.string,
    selected: PropTypes.string,
    price: PropTypes.number,
    total: PropTypes.number,
    version: PropTypes.string
};

InsuranceContainer.defaultProps = {
    provider: "",
    selected: "",
    price: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceContainer);
