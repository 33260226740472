import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { countryPhoneCodes } from '../../../../../util/countryPhoneCodes';
import { useCheckoutProcessContext } from '../../contexts/CheckoutProcessContext';
import { InsetWrapper } from '../InsetWrapper';

export const Phone = (props) => {
  const { TranslationContext } = useCheckoutProcessContext();
  const translate = useContext(TranslationContext).t;

  const onPhoneChange = (e) => {
    props.onPhoneChange(e.target.value);
  };

  const onCountryCodeChange = (e) => {
    props.onCountryCodeChange(e.target.value);
  };

  const onFocus = (e) => {
    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  return (
    <div className="input__phone">
      <div className="input__select">
        <select
          className="input__control input__phone__country-code"
          onChange={onCountryCodeChange}
          value={props.countryCode}
        >
          {countryPhoneCodes.map((code) => (
            <option key={code} value={code}>{`+${code}`}</option>
          ))}
        </select>
        <i className="icon-keyboard_arrow_down" />
      </div>
      <InsetWrapper hasError={props.hasError}>
        <div className="input__top">
          <label className="input__label" htmlFor="customerPhone">
            {translate('checkout__phone')}
          </label>
          {!props.hideRequiredLabel && (
            <span className="input__required">
              {translate(props.required ? 'checkout__required' : 'checkout__optional')}
            </span>
          )}
        </div>
        <input
          className="input__control"
          type="tel"
          placeholder={translate('checkout__phone')}
          name="Phone"
          value={props.phone}
          onChange={onPhoneChange}
          onFocus={onFocus}
          onBlur={props.onBlur}
          autoComplete={props.autocomplete ? 'on' : 'off'}
        />
      </InsetWrapper>
    </div>
  );
};

Phone.propTypes = {
  phone: PropTypes.string,
  countryCode: PropTypes.string,
  required: PropTypes.bool,
  onPhoneChange: PropTypes.func.isRequired,
  onCountryCodeChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  hasError: PropTypes.bool,
  autocomplete: PropTypes.bool,
  hideRequiredLabel: PropTypes.bool,
};

Phone.defaultProps = {
  focus: false,
  phone: '',
  autocomplete: true,
  hideRequiredLabel: false,
};

export default Phone;
