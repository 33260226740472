import { setIsReaderConnected, setConnectedTerminal } from '@/actions/payment';
import { wrappedFetch } from '../../../helpers/fetch';

export function fetchConnectionToken() {
  return (dispatch, getState) => {
    let state = getState();
    let req = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify({ sellerId: state.event.seller_id }),
    };

    return wrappedFetch(state.ui.apiUrl + '/emv/stripe/terminal_connection_token', req)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        return data.secret;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

let reconnectNumTimes = 0;
const ONE_MINUTE = 60000;

export function discoverAndConnectToReader(fetchConnectionCallback, callback = null, force = false) {
  return (dispatch, getState) => {
    let state = getState();

    let stripeTerminal = state.payment.stripeTerminal;
    let connectedTerminal = state.payment.connectedTerminal;
    let isReaderConnected = state.payment.isReaderConnected;

    // if we're already connected then no need to connect again
    if (stripeTerminal && connectedTerminal?.paymentStatus === 'ready' && isReaderConnected === true && !force) {
      if (callback) {
        callback();
      }

      return Promise.resolve();
    }

    let selectedTerminal = state.payment.terminals[state.payment.terminalId];
    connectedTerminal = stripeTerminal.create({
      onFetchConnectionToken: fetchConnectionCallback,
      onUnexpectedReaderDisconnect: unexpectedDisconnect(dispatch, fetchConnectionCallback, callback, force),
    });
    dispatch(setConnectedTerminal(connectedTerminal));

    let config = {};
    if (state.payment.terminalLocation) {
      config.location = state.payment.terminalLocation;
    }

    return connectedTerminal.discoverReaders(config).then((discoverResult) => {
      if (discoverResult.error) {
        unexpectedDisconnect(dispatch, fetchConnectionCallback, callback, force)();
        console.log('Failed to discover: ', discoverResult.error);
      } else if (discoverResult.discoveredReaders.length === 0) {
        unexpectedDisconnect(dispatch, fetchConnectionCallback, callback, force)();
        console.log('No available readers.');
      } else {
        discoverResult.discoveredReaders.forEach((reader) => {
          if (reader.serial_number === selectedTerminal) {
            dispatch(connectToReader(reader)).then((success) => {
              if (success) {
                reconnectNumTimes = 0;
              } else {
                unexpectedDisconnect(dispatch, fetchConnectionCallback, callback, force)();
              }

              if (success && callback) {
                callback();
              }
            });
          }
        });
      }
    });
  };
}

function unexpectedDisconnect(dispatch, fetchConnectionCallback, callback, force) {
  return () => {
    reconnectNumTimes = reconnectNumTimes + 1;
    dispatch(setIsReaderConnected(false));
    setTimeout(
      () => {
        dispatch(discoverAndConnectToReader(fetchConnectionCallback, callback, force));
      },
      Math.min(reconnectNumTimes * reconnectNumTimes * 1000, ONE_MINUTE),
    );
  };
}

export function connectToReader(selectedReader) {
  return (dispatch, getState) => {
    let state = getState();
    return state.payment.connectedTerminal.connectReader(selectedReader).then((connectResult) => {
      if (connectResult.error) {
        dispatch(setIsReaderConnected(false));
        return Promise.resolve(false);
      } else {
        dispatch(setIsReaderConnected(true));
        return Promise.resolve(true);
      }
    });
  };
}
