import { getInsurancePayload } from "checkout/selectors/request";
import { saveInsuranceOption } from "./reservation";
import { processInsuranceResponse } from "../views/insurance/actions/insurance";
import { setLoading } from "./loading";
import { addServerErrors } from "./ui";

export function submitInsuranceOption() {
  return async (dispatch, getState) => {
    const state = getState();
    const insurancePayload = getInsurancePayload(state);

    if (!insurancePayload.quote) {
      return false;
    }
    
    dispatch(setLoading("INSURANCE_OPTION_REQUEST"));

    try {
      const res = await dispatch(saveInsuranceOption({
        'data': {
          'attributes': insurancePayload
        }
      }));
  
      await dispatch(processInsuranceResponse(res.body));
      dispatch(setLoading("INSURANCE_OPTION_SUCCESS"));
    } catch (e) {
      dispatch(setLoading("INSURANCE_OPTION_FAILURE"));
      dispatch(addServerErrors(e.response.body.errors));
    }
  }
}