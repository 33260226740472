import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { weekDayMonthDayYear, hourMinute } from '@showclix-shared-intl/dateTimeFormatters';


class PresentTime extends Component {
    render() {
        return(
            <span>{hourMinute(this.props.locale, this.props.currentTime) + ' - ' + weekDayMonthDayYear(this.props.locale, this.props.currentTime)}</span>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.intl.locale,
        currentTime: state.time.currentTime,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(PresentTime)
