import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Props from './StepActionComponent';
import SubmitStepButton from './SubmitStepButton';
import Translation from "../../../../shared/Translation";
import WithCurrencyConfig from '../../../config/WithCurrencyConfig';
import { getJSFormattedCurrency } from '@h/currency';

class StepAction extends Component<Props> {

    constructor(props){
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event){
        if(event.key === "Escape") {
            this.props.onBack();
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }
    getFormattedTotal = amount => {
      return this.props.currencyConfig ? getJSFormattedCurrency(this.props.orderTotal, this.props.currencyConfig) : '';
    }
    render() {
        return(
            <div>
                <footer className="checkout-form__footer">
                    {this.props.lastStep && <section className="checkout-form__section" >
                        <div className="complete-order__grand-total">
                          <Translation interpolation={{orderTotal:this.getFormattedTotal()}}>{'checkout__order_total_with_total'}</Translation>
                        </div>
                    </section>}
                    <div className="checkout-form__buttons">
                      {!this.props.terminalId && <SubmitStepButton disabled={this.props.disabled} />}
                      <button
                        onClick={this.props.onBack}
                        className="button--clear checkout-form__button-back"
                        role="link"
                        data-href="#back"
                      >
                        <Translation>{'checkout__back'}</Translation>
                      </button>
                    </div>
                </footer>
            </div>
        )
    }
}
StepAction.propTypes = {
    firstStep: PropTypes.bool.isRequired,
    lastStep: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    orderTotal: PropTypes.number.isRequired,
    onBack: PropTypes.func.isRequired,
    terminalId: PropTypes.any,
};

export default (WithCurrencyConfig(StepAction))
