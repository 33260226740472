import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import Keyboard from 'react-simple-keyboard';
import { clearKeyboard, updateValue } from 'checkout/actions/keyboard';

class VirtualKeyboard extends Component {
    keyboard = null;
    state = {
        shift: false,
        caps: false,
        cursor: false,
        caretPosition: null,
    };

    componentDidUpdate(prevProps) {
        if(prevProps.label !== this.props.label && this.keyboard) {
            this.keyboard.setInput(this.props.value);
        }

        if(prevProps.type !== this.props.type) {
            if(this.props.type === "name") {
                this.setState({caps: true, shift: true});
            } else {
                this.setState({caps: false, shift: false});
            }
        }
    }

    renderPreview = () => {
        let chars = [];

        for(let i = 0; i < this.props.value.length; i++) {
            if(this.keyboard && this.keyboard.caretPosition === i) {
                chars.push(<span className="keyboard-preview__cursor" key="caret"></span>);
            }

            let charClass = !this.props.value[i].replace(/\s/g, '').length && this.props.type !== 'password' ? "keyboard-preview__space" : "keyboard-preview__letter";
            let previewChar = this.props.type === 'password' ? "*" : this.props.value[i];
            chars.push(<span className={charClass} key={i} onClick={this.onCharClick(i)}>{previewChar}</span>);
        }

        if(!this.keyboard || (!this.keyboard.caretPosition && this.keyboard.caretPosition !== 0)) {
            chars.push(<span className="keyboard-preview__cursor" key="caret"></span>);
        }

        return chars;
    }

    onCharClick = (index) => {
        return (e) => {
            this.keyboard.caretPosition = index;
            this.keyboard.caretPositionEnd = index;
            this.setState({caretPosition: index});
        }
    }

    cursorToEnd = () => {
        this.keyboard.caretPosition = null;
        this.setState({caretPosition: null});
    }

    render() {
        if (!this.props.input) {
            return null;
        }

        return (
            <div className="kiosk__keyboard">
                <div className="kiosk__keyboard__header">
                    <div className="kiosk__keyboard__preview">
                        <span className="kiosk__keyboard__preview__label">{this.props.label}</span>
                        <div className="kiosk__keyboard__preview__input">
                            <span className="kiosk__keyboard__preview__value">{this.renderPreview()}</span>
                            <div style={{height: "60px", flex: 1}} onClick={this.cursorToEnd}></div>
                        </div>
                    </div>
                    <div className="kiosk__keyboard__done">
                        <button className="button--primary kiosk__keyboard__done__button" onClick={this.onDismiss}>Done</button>
                    </div>
                </div>
                <Keyboard
                    keyboardRef={this.onRef}
                    onChange={this.onChange}
                    preventMouseDownDefault={true}
                    layoutName={this.getLayoutName()}
                    onKeyPress={this.onKeyPress}
                    layout={this.getLayouts()}
                    display={{
                        '{bksp}': 'Backspace',
                        '{enter}': 'Enter',
                        '{shift}': 'Shift',
                        '{space}': 'Space',
                        '{tab}': 'Tab',
                        '{lock}': 'Caps',
                        '{blank}': ' ',
                    }}
                    buttonTheme={[
                        {
                            class: "email-button",
                            buttons: "@gmail.com @yahoo.com"
                        },
                        {
                            class: "punctuation-button--lower",
                            buttons: ", ."
                        },
                        {
                            class: "punctuation-button--upper",
                            buttons: "` \' \""
                        },
                    ]}
                />
            </div>
        )
    }

    getLayoutName = () => {
        let prefix = this.state.caps ? 'shift' : '';
        let layoutName = this.props.type;

        if(!this.getLayouts()[layoutName]) {
            layoutName = "text";
        }

        layoutName = prefix + layoutName;

        return layoutName;
    }

    getLayouts = () => {
        return {
            'text': [
                '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                '{tab} q w e r t y u i o p [ ] \\',
                '{lock} a s d f g h j k l ; \' {enter}',
                '{shift} z x c v b n m , . / {shift}',
                '{blank} {space} {blank}'
            ],
            'shifttext': [
                '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                '{tab} Q W E R T Y U I O P { } |',
                '{lock} A S D F G H J K L : " {enter}',
                '{shift} Z X C V B N M < > ? {shift}',
                '{blank} {space} {blank}'
            ],
            'name': [
                '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                '{tab} q w e r t y u i o p [ ] \\',
                '{lock} a s d f g h j k l ; \' {enter}',
                '{shift} z x c v b n m , . / {shift}',
                '{blank} {space} {blank}'
            ],
            'shiftname': [
                '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                '{tab} Q W E R T Y U I O P { } |',
                '{lock} A S D F G H J K L : " {enter}',
                '{shift} Z X C V B N M < > ? {shift}',
                '{blank} {space} {blank}'
            ],
            'number': [
                '1 2 3',
                '4 5 6',
                '7 8 9',
                '{blank} 0 {bksp}',
            ],
            'email': [
                '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                '{tab} q w e r t y u i o p [ ] \\',
                '{lock} a s d f g h j k l ; \' {enter}',
                '{shift} z x c v b n m , . / {shift}',
                '.com @ {space} @gmail.com @yahoo.com'
            ],
            'shiftemail': [
                '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                '{tab} Q W E R T Y U I O P { } |',
                '{lock} A S D F G H J K L : " {enter}',
                '{shift} Z X C V B N M < > ? {shift}',
                '.com @ {space} @GMAIL.COM @YAHOO.COM'
            ],
        }
    }


    onKeyPress = (button) => {
        if ((button === "{shift}" || button === "{lock}" || this.state.shift === true) && button !== "{bksp}") {
            this.handleShift();
        }

        if(button === "{shift}") {
            this.setState({shift: true});
        }

        if(this.props.type === "name") {
            if(button === "{space}") {
                this.setState({caps: true, shift: true});
            } else if(button === "{bksp}" && this.props.value.charAt(this.props.value.length - 2) !== " ") {
                this.setState({caps: false, shift: false});
            }
        }
    };

    handleShift = () => {
        this.setState({caps: !this.state.caps, shift: false});
    };

    onRef = (ref) => {
        this.keyboard = ref;
        this.keyboard.setInput(this.props.value);
    }

    onChange = (value) => {
        this.props.updateValue(value);
    }

    onDismiss = () => {
        this.props.clearKeyboard();
    }
}

const mapStateToProps = state => {
    return {
        input: state.keyboard.input,
        label: state.keyboard.label,
        value: state.keyboard.value,
        type: state.keyboard.type,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        clearKeyboard,
        updateValue,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualKeyboard)
