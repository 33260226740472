import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormattedCurrency from 'shared/FormattedCurrency';
import AddTickets from 'checkout/views/ticket-picker/components/AddTickets';
import DetailsToggle from '@s/DetailsToggle'
import Translation from '@s/Translation';
import WithCurrencyConfig from '../../../config/WithCurrencyConfig';
import { getJSFormattedCurrency } from '@h/currency';

class InventoryFilterItem extends Component {

    state = {
        showDescription: false,
    }

    toggleDescription = () => {
        this.setState({showDescription: !this.state.showDescription});
    };

    getPrice = () => {
        if (this.hasPriceRange()) {
            return (
                <Fragment>
                    <Translation interpolation = {{minAmount:getJSFormattedCurrency(this.props.minPrice, this.props.currencyConfig), maxAmount:getJSFormattedCurrency(this.props.maxPrice, this.props.currencyConfig)}}>{'checkout__fee_with_range'}</Translation>
                </Fragment>
            );
        }
        return <Fragment>
                    <Translation interpolation = {{amount:getJSFormattedCurrency(this.props.price, this.props.currencyConfig)}}>{'checkout__plus_fees'}</Translation>
                </Fragment>;
    }

    hasPriceRange = () => {
        return this.props.minPrice !== undefined && this.props.maxPrice !== undefined && this.props.minPrice != this.props.maxPrice;
    }

    render() {
        return (
            <>
                <div className="ticket ticket--inventory-filter-item">
                    <div className="ticket__information">
                        <div className="ticket__name" role='heading' aria-level='3'>{ this.props.name }</div>
                        {!!this.props.description && <DetailsToggle onClick={this.toggleDescription}/>}
                        <div className="ticket__pricing-group hide-on-medium-screen">
                            <div className="ticket__price">{this.getPrice()}</div>
                        </div>
                    </div>
                    <div className="ticket__pricing-group hide-on-small-screen">
                        <div className="ticket__price">{this.getPrice()}</div>
                    </div>
                    <div className="ticket__action">
                        <AddTickets
                            onIncrement={this.props.onIncrement}
                            onDecrement={this.props.onDecrement}
                            quantity={this.props.quantity}
                            quantities={this.props.quantities}
                            onQuantityInput={this.props.onQuantityInput}
                        />
                    </div>
                </div>
                {!!this.props.description && <>
                    {this.state.showDescription === true &&
                        <div className="ticket__description">
                            <div className="ticket-description__content" name="inventory_filter_description">
                                {this.props.description}
                            </div>
                        </div>
                    }
                </>}
            </>
        );
    }
}

InventoryFilterItem.propTypes = {
    name: PropTypes.string.isRequired,
    price: PropTypes.number,
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired,
    onQuantityInput: PropTypes.func.isRequired,
    quantity: PropTypes.number,
    quantities: PropTypes.arrayOf(PropTypes.number),
};

export default WithCurrencyConfig(InventoryFilterItem);
