import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import PriceLevelContainer from '../containers/PriceLevelContainer';
import PriceLevelFilterContainer from '../../series/containers/PriceLevelFilterContainer';

class PriceLevelDefaultOrder extends PureComponent {

    map = {
        PriceLevelContainer: PriceLevelContainer,
        PriceLevelFilterContainer: PriceLevelFilterContainer,
    };

    renderPriceLevels = () => {
        let levels = Object.values(this.props.priceLevels).sort((a, b) => {
            // account for null
            if (a.position == b.position) {
                return 0;
            }
            if (!a.position) {
                return 1;
            } else if (!b.position) {
                return -1;
            }
            return a.position - b.position;
        });

        return levels.map((level, i) => {

            if (level.box_office_hide && this.props.isKioskMode && !this.props.alwaysShowHiddenLevels) {
                return null;
            }

            if(level.settings && level.settings.hide_online === true) {
                return null;
            }

            if(Array.isArray(this.props.levelFilter) && this.props.levelFilter.indexOf(+level.price_level_id) === -1) {
                return null;
            }

            return React.createElement(this.map[this.props.componentName], {key: i, levelId: +level.price_level_id});
        });
    }

    render() {
        if (Object.keys(this.map).indexOf(this.props.componentName) === -1) {
            console.warn('Invalid component name prop provided for PriceLevelDefaultOrder: ' + this.props.componentName);
            return null;
        }
        return(
            <Fragment>
                {this.renderPriceLevels()}
            </Fragment>
        )
    }
}

PriceLevelDefaultOrder.propTypes = {
    priceLevels: PropTypes.object.isRequired,
    componentName: PropTypes.string.isRequired,
    alwaysShowHiddenLevels: PropTypes.bool,
    isKioskMode: PropTypes.bool,
    levelFilter: PropTypes.array,
};

export default PriceLevelDefaultOrder;
