import React, { Component } from 'react';
import { asyncComponent } from 'react-async-component';
import PropTypes from 'prop-types';

const TicketGuardian = asyncComponent({
    resolve: () => import('./TicketGuardian'),
});

export default class TicketGuardianWrapper extends Component {
    render() {
        return(
            <TicketGuardian
                selected={this.props.selected}
                price={this.props.price}
                loading={this.props.loading}
                declined={this.props.declined}
                onInsuranceChange={this.props.onInsuranceChange}
                widget_api_key={this.props.widget_api_key}
                version={this.props.version}
                sandbox={this.props.sandbox}
                templateId={this.props.templateId}
                insuranceItems={this.props.insuranceItems}
                currency={this.props.currency}
                locale={this.props.locale}
                setInsuranceOption={this.props.setInsuranceOption}
            />
        );
    }
}

TicketGuardianWrapper.propTypes = {
    version: PropTypes.string
};
