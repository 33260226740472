import {setPriceLevels, setProducts, setBundles, setChildEvent} from '@/actions/event';
import { getSeriesConfig } from 'checkout/selectors/ui';
import { setListingItem, removeListingItem, getListingItem } from 'listing/helpers/storage';
import { validatePresaleCoupon, validateCoupon, setPresaleCode, setIsAccessCodeApplied, submitPresaleCoupon, setTaggableCouponLoading, applyTaggableCoupon, setTaggableCodeError, setTaggableReserveCodeApplied } from '../../coupon/actions/coupon';
import { isValidDateObject, ISOStringToDate, dateToYMD, dateOrUnixToDate } from '../../../../helpers/time';
import { setShowCalendarInModal, setCheckoutOverlay, setCurrentMonth } from 'checkout/actions/ui';
import { triggerTransition } from '@uirouter/redux';
import { getNumberOfMonths, hasActiveInventoryFilter, canSkipToCheckoutWithInventoryFilter, getSeriesOption } from '../selectors/eventSeries';
import { serializeToUrl } from '../../../helpers/helpers';
import { setLoading } from 'checkout/actions/loading';
import { createLoadingSelector } from 'checkout/selectors/helpers';
import { throttleQueryInventory, applyFiltersToTicketPicker } from './inventory-filter';
import { createReservation, deleteReservation, createReservationIfNotExists } from '../../../actions/reservation';
import { reservationActive } from '../../../selectors/reservation';
import { getMainEvent } from '../../../selectors/event';
import { eventInFilterResults } from '../../../helpers/series';
import { getSubState } from '../../../reducers/subReducerHelpers';
import { wrappedFetch } from '../../../helpers/fetch';
import { submitReserveCoupon } from '../../../actions/reservedSeating';
import { isKioskMode, selectIsHeroLayout } from '@b/checkout/selectors/ui';

export const SET_EVENT_TIMES = "SET_EVENT_TIMES";
export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";
export const SET_SELECTED_EVENT_TIME_OBJECT = "SET_SELECTED_EVENT_TIME_OBJECT";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_SELECTED_DATE_OBJECT = "SET_SELECTED_DATE_OBJECT";
export const SET_SELECTED_TIME = "SET_SELECTED_TIME";
export const SET_EVENTS_TODAY = "SET_EVENTS_TODAY";
export const SET_FETCHED_EVENTS_TODAY = "SET_FETCHED_EVENTS_TODAY";
export const SET_SERIES_EVENT_DATES = "SET_SERIES_EVENT_DATES";
export const SET_SERIES_SOLD_OUT_DATES = "SET_SERIES_SOLD_OUT_DATES";
export const SET_DATES_LAZY_LOADED = "SET_DATES_LAZY_LOADED";

export function setEventTimes(eventTimes, date = null, subReducerKey = null, selectedDateDescription = null) {
    return {type: SET_EVENT_TIMES, eventTimes, date, subReducerKey, selectedDateDescription};
}

export function setSelectedEvent(data, subReducerKey = null) {
    return {type: SET_SELECTED_EVENT, data, subReducerKey};
}

/**
 * Set the selected event time from state.eventSeries.eventTimes
 */
export function setSelectedEventTimeObject(data, subReducerKey = null) {
    return {type: SET_SELECTED_EVENT_TIME_OBJECT, data, subReducerKey};
}

export function setSelectedDate(date, subReducerKey = null) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (isValidDateObject(date)) {
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                const dateString = date.toLocaleDateString("en-US", options);
                dispatch({type: SET_SELECTED_DATE_OBJECT, date, subReducerKey});
                dispatch({type: SET_SELECTED_DATE, date:dateString, subReducerKey});
                resolve(dateString);
            } else {
                dispatch({type: SET_SELECTED_DATE_OBJECT, date:null, subReducerKey});
                dispatch({type: SET_SELECTED_DATE, date:'', subReducerKey});
                resolve('');
            }
        });
    }
}

export function setSelectedTime(time) {
    return {type: SET_SELECTED_TIME, time};
}

export function clearEventTimes() {
    return {type: "CLEAR_EVENT_TIMES"};
}

export function setWidth(width) {
    return {type: "SET_WIDTH_FOR_SERIES_CALENDAR", width};
}

export function setNetworkError(networkError) {
    return {type: "SET_NETWORK_ERROR", networkError};
}

export function setSelectedChildEvent(id, subReducerKey = null) {
    return (dispatch, getState) => {
        if (!subReducerKey) {
            if (id === undefined) {
              removeListingItem(getState().ui.listingSlug, 'selectedEventId');
            } else {
                setListingItem(getState().ui.listingSlug, 'selectedEventId', id);
            }
        }
        dispatch({type: "SET_SELECTED_CHILD_EVENT", id, subReducerKey});
    }
}

export function setChildEventAdditionalInfo(childEventAdditionalInfo = "") {
  return {type: "SET_SELECTED_CHILD_EVENT_INFO", childEventAdditionalInfo};
}

export function setEventTimesForDate(date, eventTimes) {
    return {type: "SET_EVENT_TIME_FOR_DATE", date, eventTimes};
}

export function setEventTimesForDates(eventTimes) {
    return {type: "SET_EVENT_TIME_FOR_DATES", eventTimes};
}

export function getEventTimes(parentId, date, listingUpsellId = null, subReducerKey = null, skipSelectFirstDate = false) {
  return (dispatch, getState) => {
    const state = getState();
    const eventTimesLoading = createLoadingSelector("EVENT_TIMES");
    if (eventTimesLoading(state)) {
      return Promise.resolve();
    }

    dispatch(setLoading("EVENT_TIMES_REQUEST"));
    let selectedDescription = '';
    return fetchEventTimes(state.ui.apiUrl, state.ui.listingSlug, parentId, date, null, null, listingUpsellId).then((data) => {
      if (state.ui.listingConfig.checkout.seriesOptions.calendarDescriptions) {
        const selectedDate = new Date(date).toLocaleDateString();
        const datePastabilities = Object.keys(state.ui.listingConfig.checkout.seriesOptions.calendarDescriptions);

        const descriptions = state.ui.listingConfig.checkout.seriesOptions.calendarDescriptions;

        datePastabilities.forEach(dateKey => {
          if (selectedDate === dateOrUnixToDate(dateKey).toLocaleDateString()) {
            selectedDescription = descriptions[dateKey];
          }
        })
      } else {
        selectedDescription = data.selectedDateDescription;
      }
      if (data) {
        dispatch(setLoading("EVENT_TIMES_SUCCESS"));
        dispatch(setNetworkError(false));
        dispatch(setEventTimes(data.eventTimes, date, subReducerKey, selectedDescription));
        if (!skipSelectFirstDate) {
          if (data.eventTimes.length === 1) {
            const first = data.eventTimes[0];
            dispatch(setSelectedChildEvent(Number(first.id)));
            dispatch(setChildEventAdditionalInfo(first.childEventAdditionalInfo));
            dispatch(selectChildEventAndContinue(Number(first.id), false));
          }

          const { selectedChildEvent, childEventAdditionalInfo } = state.eventSeries;

          if (selectedChildEvent) {
            dispatch(setSelectedChildEvent(selectedChildEvent));
            dispatch(setChildEventAdditionalInfo(childEventAdditionalInfo));
            dispatch(selectChildEventAndContinue(selectedChildEvent, false));
          }
        }
      } else {
        dispatch(setLoading("EVENT_TIMES_FAILURE"));
        dispatch(setNetworkError(true));
        Rollbar.critical("Empty response when fetching event times", JSON.stringify({
          params: {url: state.ui.apiUrl, slug: state.ui.listingSlug, parentId, date},
        }));
      }
    }).catch(() => {
        dispatch(setLoading("EVENT_TIMES_FAILURE"));
        dispatch(setNetworkError(true));
    });
  }
}

/**
 * @param dates array of date objects
 */
export function getEventTimesForDates(dates) {
    return (dispatch, getState) => {
        const state = getState();
        const eventTimesLoading = createLoadingSelector("EVENT_TIMES");
        if(eventTimesLoading(state)) {
            return Promise.resolve();
        }
        dispatch(setLoading("EVENT_TIMES_REQUEST"));
        const parentId = getMainEvent(state)?.event?.event_id;
        if (!dates.length) return Promise.resolve();
        const sorted = dates.sort((a, b) => {
            if (isValidDateObject(a) && isValidDateObject(b)) {
                return a.getTime() - b.getTime();
            } else {
                return 1;
            }
        });
        let startDate = sorted[0];
        let endDate = sorted[sorted.length - 1];
        if (isValidDateObject(startDate)) startDate = dateToYMD(startDate);
        if (isValidDateObject(endDate)) endDate = dateToYMD(endDate);
        return fetchEventTimes(state.ui.apiUrl, state.ui.listingSlug, parentId, null, startDate, endDate).then((data) => {
            if (data) {
                dispatch(setLoading("EVENT_TIMES_SUCCESS"));
                dispatch(setEventTimesForDates(data.eventTimes));
            } else {
                dispatch(setLoading("EVENT_TIMES_FAILURE"));
                Rollbar.critical("Empty response when fetching event times", JSON.stringify({
                    params: {url: state.ui.apiUrl, slug: state.ui.listingSlug, parentId, startDate, endDate},
                }));
            }
        }).catch(() => {
            dispatch(setLoading("EVENT_TIMES_FAILURE"));
        });
    }
}

export function fetchListingConfigVersion(apiUrl,listing) {
    const payload = serializeToUrl({listing});
    return wrappedFetch(`${apiUrl}/listing-config-version?${payload}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
    }).then((response) => {
        return response.json();
    }).catch((err) => {
    });
}

export function fetchEventTimes(apiUrl, listing, parentId, eventDate, startDate = null, endDate = null, listingUpsellId = null, ver = '') {
    if(typeof eventDate === "object") {
        eventDate = dateToYMD(eventDate);
    }
    return fetchListingConfigVersion(apiUrl,listing).then((data) => {
        //why ver? App\API\Handler\EventSeries#httpCache, Cache-Control 60s
        const ver = data.ver;
        const payload = serializeToUrl({parentId, listing, eventDate, startDate, endDate, listingUpsellId, ver});
        return wrappedFetch(`${apiUrl}/event-series?${payload}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
        }).then((response) => {
            return response.json();
        }).catch((err) => {
        });
    });
}

export function getEventSeriesChildListing(eventId, reducerKey = null, upsellId = null) {
    return (dispatch, getState) => {
        let state = getState();
        const toSerialize = {eventId, listingId: state.ui.listingId};
        if (upsellId) {
            toSerialize.listingUpsellId = upsellId;
        }
        const payload = serializeToUrl(toSerialize);
        return wrappedFetch(state.ui.apiUrl + '/series-child?' + payload, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.childListingData) {
                const eventData = Object.values(data.childListingData.attributes.events)[0];
                dispatch(setChildEvent(eventData, reducerKey));
                dispatch(setSelectedEvent(eventData, reducerKey));
                dispatch(setChildEventAdditionalInfo(eventData.event.series_child_event_additional_info));
                dispatch(setPriceLevels(eventData.price_levels, reducerKey));
                if (eventData.products) dispatch(setProducts(eventData.products, reducerKey));
                if (eventData.bundles) {
                    dispatch(setBundles(eventData.bundles, reducerKey));
                }
                if (state.ui.uiConfig.taggableCode) {
                    dispatch(setTaggableCouponLoading(true));
                    const taggableCode = state.ui.uiConfig.taggableCode;
                    if (state.coupon.isAccessCodeApplied) {
                        // recreate res, can't use access code across events
                        dispatch(setIsAccessCodeApplied(false));
                        dispatch(deleteReservation(false, false));
                        dispatch(createReservationIfNotExists());
                    }
                    if (!eventData.event.isOnsale && eventData.event.hasPresale) {
                       dispatch(setPresaleCode(taggableCode));
                       dispatch(validatePresaleCoupon(true, true)).then((data) => {
                           state = getState();
                           if (state.coupon.isPresaleValid) {
                               dispatch(submitPresaleCoupon(true));
                           }
                       });
                    } else if (eventData.event.hasReserveCoupon) {
                        dispatch(setTaggableCouponLoading(true));
                        dispatch(submitReserveCoupon(taggableCode)).then((data) => {
                            state = getState();
                            if (state.coupon.reserveCouponUsages.length > 0) {
                                dispatch(setTaggableReserveCodeApplied(true));
                            } else {
                                dispatch(setTaggableCodeError(true));
                            }
                            dispatch(setTaggableCouponLoading(false))
                        });

                    } else {
                        dispatch(validateCoupon({ code: taggableCode, force: null, forTaggableCoupon: true }));
                    }
                }

                //handle the case where the user reloads after having entered a valid presale code for a series child.
                //In this scenario, we need to re-submit the code because reloading the page will have
                //caused all of the price levels, products, etc to be hidden again.
                const couponData = state.coupon;
                if (couponData.presaleCode && couponData.presaleCode !== "" && couponData.isPresaleValid && couponData.isPresaleCodeApplied && !reducerKey) {
                    dispatch(validatePresaleCoupon(true));
                }
            }
        }).catch(function(err) {
            console.log(err);
        });
    }
}

//wrap the functionality for selecting a child event and
//continuing in the checkout process to eliminate duplication
export function selectChildEventAndContinue(eventId, redirect = true) {
    return (dispatch, getState) => {
        if (eventId) {
            let state = getState();

            setListingItem(state.ui.listingSlug, 'selectedEventId', eventId);
            dispatch(setLoading("SELECT_CHILD_EVENT_REQUEST"));
            dispatch(getEventSeriesChildListing(eventId)).then(() => {
                //get state again updated by getEventSeriesChildListing
                state = getState();

                // if skipping to checkout but you've selected invalid then you go to the ticketpicker like normal
                if (getSeriesOption('inventoryFilterSkipToCheckout', false)(state) && selectingFilteredEvent(eventId, state)) {
                    dispatch(applyFiltersToTicketPicker());
                    dispatch(setLoading("SELECT_CHILD_EVENT_SUCCESS"));
                    redirect && dispatch(triggerTransition("ticketPicker"));
                    return;
                }

                const childEventObject = (state.event.childEvent && state.event.childEvent.event) ? state.event.childEvent.event : null;
                if (childEventObject) {
                    dispatch(setCheckoutOverlay(true));

                    if (!childEventObject.isOnsale && childEventObject.hasPresale
                        && Object.keys(state.priceLevel.levels).length === 0
                        && state.coupon.isPresaleCodeApplied === false) {
                        // presale
                        dispatch(triggerTransition('coupon'));
                        dispatch(setLoading("SELECT_CHILD_EVENT_SUCCESS"));
                    } else {
                        if (hasActiveInventoryFilter(state)) {
                            const result = dispatch(applyFiltersToTicketPicker());
                            if (result && canSkipToCheckoutWithInventoryFilter(state)) {
                                dispatch(createReservation()).then(() => {
                                    if (!reservationActive(getState())) {
                                        redirect && dispatch(triggerTransition("ticketPicker"));
                                    }
                                }).finally(() => {
                                    dispatch(setLoading("SELECT_CHILD_EVENT_SUCCESS"));
                                });
                            } else {
                                dispatch(setLoading("SELECT_CHILD_EVENT_SUCCESS"));
                                redirect && dispatch(triggerTransition("ticketPicker"));
                            }
                        } else {
                            dispatch(setLoading("SELECT_CHILD_EVENT_SUCCESS"));
                            redirect && dispatch(triggerTransition("ticketPicker"));
                        }
                    }
                    dispatch(setShowCalendarInModal(true));
                } else {
                    dispatch(setLoading("SELECT_CHILD_EVENT_SUCCESS"));
                }
            });
        }
    }
}

function selectingFilteredEvent(eventId, state) {
    const filterResults = state.inventoryFilter.filterResults;
    if (hasActiveInventoryFilter(state) && filterResults !== null && !eventInFilterResults(+eventId, filterResults)) {
        return true;
    }
    return false;
}

export function setEventsToday(eventsToday) {
    return {type:SET_EVENTS_TODAY, value: eventsToday};
}

export function setFetchedEventsToday(value) {
    return {type:SET_FETCHED_EVENTS_TODAY, value};
}

export function setSeriesEventDates(dates, subReducerKey = null) {
    return {type:SET_SERIES_EVENT_DATES, dates, subReducerKey};
}

export function setSeriesSoldOutDates(dates, subReducerKey = null) {
    return {type:SET_SERIES_SOLD_OUT_DATES, dates, subReducerKey};
}

export function setDatesLazyLoaded(dates, subReducerKey = null) {
    return {type:SET_DATES_LAZY_LOADED, dates, subReducerKey};
}

/**
 * Lazy load series dates for the visible months based on the date provided
 *
 * @param month          - date object passed to onMonthChange handlers by react-day-picker
 *                         note: if we are displaying two months the date passed will reflect the first
 *                         visible month (the one on the left).
 * @param numberOfMonths - if provided, uses this instead of calculating number of months
 * @param reducerKey     - if provided, loads series dates for sub reducers with the provided key
 */
export function lazyLoadSeriesDates(month, numberOfMonths = null, reducerKey = null) {
    return (dispatch, getState) => {
        if (!isValidDateObject(month)) {
            console.log('Invalid date provided to load lazy load series children: ' + month);
            return Promise.resolve();
        }

        const state = getState();
        const eventState = getSubState(state.event, reducerKey);
        const eventSeriesState = getSubState(state.eventSeries, reducerKey);
        const numberMonths = !!numberOfMonths && typeof numberOfMonths === 'number' ? numberOfMonths : getNumberOfMonths(state);
        const startDate = new Date(month.getFullYear(), month.getMonth(), 1, 0, 0, 0);
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + numberMonths, 0, 23, 59, 59);
        const eventDates = [...Object.values(eventState.events)[0].event.series.eventDates];
        const soldOutDates = [...Object.values(eventState.events)[0].event.series.soldOutDates];
        const parentId = Object.values(eventState.events)[0].event.event_id;
        const datesLazyLoaded = Array.isArray(eventSeriesState.datesLazyLoaded) ? [...eventSeriesState.datesLazyLoaded] : [];

        if (createLoadingSelector('SERIES_CALENDAR')(state)) {
            return Promise.resolve();
        }

        if (isValidDateObject(startDate) && isValidDateObject(endDate) && startDate.getTime() <= endDate.getTime()) {
            const datesToFetch = [];
            let dateCursor = startDate;
            while (dateCursor.getTime() <= endDate.getTime()) {
                const ymd = dateToYMD(dateCursor);
                if (ymd && eventDates.indexOf(ymd) === -1 && soldOutDates.indexOf(ymd) === -1 && datesLazyLoaded.indexOf(ymd) === -1) {
                    datesToFetch.push(new Date(dateCursor.getTime()));
                }
                dateCursor = new Date(dateCursor.setDate(dateCursor.getDate() + 1));
            }
            if (datesToFetch.length) {
                const sorted = datesToFetch.sort((a, b) => {
                    return a.getTime() - b.getTime();
                });
                const startDateFilter = dateToYMD(sorted[0]);
                const endDateFilter = dateToYMD(sorted[sorted.length - 1]);
                if (startDateFilter && endDateFilter) {
                    dispatch(setLoading("SERIES_CALENDAR_REQUEST"));
                    return wrappedFetch(state.ui.apiUrl + '/series-dates/' + parentId + '?startDate=' + startDateFilter + '&endDate=' + endDateFilter, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin',
                    }).then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        }
                    }).then((response) => {
                        dispatch(setLoading("SERIES_CALENDAR_SUCCESS"));
                        if (response) {
                            if (Array.isArray(response.eventDates) && response.eventDates.length) {
                                let newEventDates = eventDates;
                                response.eventDates.forEach(eventDate => {
                                    if (newEventDates.indexOf(eventDate) === -1) {
                                        newEventDates.push(eventDate);
                                    }
                                });
                                newEventDates = newEventDates.sort();
                                dispatch(setSeriesEventDates(newEventDates, reducerKey));
                            }
                            if (Array.isArray(response.soldOutDates) && response.soldOutDates.length) {
                                let newSoldOutDates = soldOutDates;
                                response.soldOutDates.forEach(soldOutDate => {
                                    if (newSoldOutDates.indexOf(soldOutDate) === -1) {
                                        newSoldOutDates.push(soldOutDate);
                                    }
                                });
                                newSoldOutDates = newSoldOutDates.sort();
                                dispatch(setSeriesSoldOutDates(newSoldOutDates, reducerKey));
                            }
                            //update dates lazy loaded so we don't make this same request again
                            const newDatesLazyLoaded = datesLazyLoaded;
                            datesToFetch.forEach(date => {
                                const ymd = dateToYMD(date);
                                if (newDatesLazyLoaded.indexOf(ymd) === -1) {
                                    newDatesLazyLoaded.push(ymd);
                                }
                            });
                            dispatch(setDatesLazyLoaded(newDatesLazyLoaded, reducerKey));
                            if(hasActiveInventoryFilter(state)) {
                                throttleQueryInventory(dispatch);
                            }
                        }
                    }).catch((err) => {
                        dispatch(setLoading("SERIES_CALENDAR_FAILURE"));
                    });
                }
            } else {
                if(hasActiveInventoryFilter(state)) {
                    throttleQueryInventory(dispatch);
                }
            }
        }
    }
}

/**
 * Track the current month and lazy load dates if necessary
 */
export function handleMonthChange(month, numberOfMonths = null, reducerKey = null) {
    return async (dispatch, getState) => {
        dispatch(setCurrentMonth(isValidDateObject(month) && month.toISOString ? month.toISOString() : null, reducerKey));
        await dispatch(lazyLoadSeriesDates(month, numberOfMonths, reducerKey));
        if (hasActiveInventoryFilter(getState())) {
            dispatch(throttleQueryInventory);
        }
    }
}
