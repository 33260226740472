import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDayPicker from 'react-day-picker';
import { DateTimeFormatters } from '../../../../sc-intl';

export default class DayPicker extends Component {
  render() {
    const dayComponent = this.props.renderDay ? this.props.renderDay : this.renderDay;

    return (
      <Fragment>
        {this.props.calendarStyle && <style>{this.props.calendarStyle}</style>}
        <ReactDayPicker renderDay={dayComponent} {...this.props} captionElement={this.renderCaption} />
      </Fragment>
    );
  }

  renderCaption = ({ date, months }) => {
    months = months || DateTimeFormatters.monthNames(this.props.locale);

    const title = `${months[date.getMonth()]} ${date.getFullYear()}`;

    return <h3 className="DayPicker-Caption">{title}</h3>;
  };

  renderDay = (day) => {
    const date = day.getDate();
    return (
      <div className="daypicker__circle__color">
        <span className="daypicker__circle__number">{date}</span>
        <span className="daypicker__circle__overlay" />
      </div>
    );
  };
}

DayPicker.propTypes = {
  calendarStyle: PropTypes.string,
};
