import { Store } from 'redux';
import { getIsDevMode } from '../checkout/selectors/ui';
import { setIsDevMode } from '../checkout/actions/ui';
import { getTopWindow } from '../checkout/helpers/window';

export const EVENT_SUBMIT_CARD = 'kiosk-console-submit-card';

class KioskConsole {
    store: Store<any>;
    constructor(store) {
        this.store = store;
    }
    isDevMode() {
        return getIsDevMode(this.store.getState());
    }
    setIsDevMode(val) {
        this.store.dispatch(setIsDevMode(val));
    }
    // submitting dummy payments only work if dummy gateway enabled on the server
    submitCard(card: string) {
        dispatchEvent(new CustomEvent(EVENT_SUBMIT_CARD, {detail: card}));
    }
}

/**
 * Expose some methods for working with kiosk without a terminal in dev and test environments
 */
export default (store) => {
    const topWindow = getTopWindow();
    (<any>topWindow).KioskConsole = new KioskConsole(store);
}
