import Translation from '@s/Translation';
import Icon from '@showclix-shared/Icon';
import ListingDatePicker from '@v/series/containers/ListingDatePicker';
import clsx from 'clsx';

export const StickyEvent = ({ show, calendarOnListing, name, date, location, ages, image, imageAlt, TicketsButton }) => {
  return (
    <div className={clsx('sticky-event', calendarOnListing ? 'sticky-event--series' : 'sticky-event--single', show && 'sticky-event--visible')}>
      {image && (
        <header className="sticky-event__header">
          <img className="sticky-event__header-image" src={image} alt={imageAlt} />
        </header>
      )}
      <main className="sticky-event__info">
        <h5 className="sticky-event__info-name">{name}</h5>
        {date && (
          <p className="sticky-event__info-date">
            <Icon icon="icon-room" size="20" color="inherit" />
            {date}
          </p>
        )}
        {location && (
          <p className="sticky-event__info-location">
            <Icon icon="icon-calendar" size="20" color="inherit" />
            {location}
          </p>
        )}
        {ages && (
          <p className="sticky-event__info-ages">
            <Translation>{ages}</Translation>
          </p>
        )}
      </main>
      <footer className="sticky-event__actions">{TicketsButton}</footer>
      <main className="sticky-event__day-picker">
        <ListingDatePicker />
      </main>
    </div>
  );
};
